import gql from "graphql-tag";
import { useQuery, QueryHookOptions } from "@apollo/client";

export function useSummonerRankSnapshots(
  regionId: string,
  riotUserName: string,
  riotTagLine: string,
  queryOptions: QueryHookOptions = {},
) {
  return useQuery<RankSnapshotsData>(GET_SUMMONER_RANK_SNAPSHOTS, {
    ...queryOptions,
    variables: {
      regionId,
      riotUserName,
      riotTagLine,
      queueType: [420, 440],
    },
  });
}

export interface RankSnapshot {
  insertedAt: number;
  losses: number;
  lp: number;
  promoProgress: string;
  queueId: number;
  rank: string;
  tier: string;
  wins: number;
}
interface RankSnapshotsData {
  getSummonerRankSnapshots: RankSnapshot[];
}

export const GET_SUMMONER_RANK_SNAPSHOTS = gql`
  query getSummonerRankSnapshots($queueType: [Int], $regionId: String!, $riotUserName: String!, $riotTagLine: String!) {
    getSummonerRankSnapshots(queueType: $queueType, regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      insertedAt
      losses
      lp
      promoProgress
      queueId
      rank
      tier
      wins
    }
  }
`;
