import React, { useCallback } from "react";
import classnames from "classnames";
import { MediaQuery } from "@outplayed/responsive";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { QUEUE_TYPE_OPTIONS, defaultMatchFilters } from "./match-history-filters";
import DefaultSelect from "@ugg/shared/components/FilterManager/DefaultSelect";
import Summoner from "@ugg/shared/components/FilterManager/filters/Summoner";
import Champion from "@ugg/shared/components/FilterManager/filters/Champion";
import MultiSearch from "@ugg/shared/components/FilterManager/filters/MultiSearch";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

interface MatchFiltersProps {
  className?: string;
  filters: typeof defaultMatchFilters;
  setFilters: (filters: typeof defaultMatchFilters) => void;
  onFiltersChanged: () => void;
}

export default function MatchFilters(props: MatchFiltersProps) {
  const { getChampionName } = getRiotAssetsContext();
  const { className, filters, setFilters, onFiltersChanged } = props;

  const onFilterChange = useCallback(
    (type: string, option: { value: string } | null) => {
      onFiltersChanged();
      setFilters({ ...filters, [type]: option?.value });
    },
    [filters],
  );

  const getLabel = (options: Array<{ label: string; value: string }>, value: string) => {
    const foundOption = options.find((option) => option.value === value);
    return (foundOption && foundOption.label) || "";
  };

  const Filters = ({ isMobile }: { isMobile?: boolean }) => (
    <React.Fragment>
      <DefaultSelect
        title={"Change Queue Type"}
        group={"queuetype"}
        customButton={
          <div className="filter-select_mobile">
            <span>{getLabel(QUEUE_TYPE_OPTIONS, filters.queueType)}</span>
            <TriangleArrowDown className="dropdown-indicator" />
          </div>
        }
        className={`transparent-button`}
        isMobile={isMobile}
        options={QUEUE_TYPE_OPTIONS}
        onChange={(selected) => {
          onFilterChange("queueType", selected);
        }}
        value={filters.queueType}
        width={110}
        fontSize={14}
        isSearchable={false}
      />
      {isMobile ? (
        <>
          <Summoner
            value={filters.duoRiotId}
            filterKey="duoRiotId"
            className={"transparent-button-summoner"}
            onFilterChange={onFilterChange}
            customButton={
              <div className="filter-select_mobile">
                <span>{filters.duoRiotId ? filters.duoRiotId : "All Summoners"}</span>
                <TriangleArrowDown className="dropdown-indicator" />
              </div>
            }
            isMobile={isMobile}
            fontSize={12}
          />
          <Champion
            value={filters.championId}
            filterKey="championId"
            onFilterChange={onFilterChange}
            customButton={
              <div className="filter-select_mobile">
                <span>{filters.championId ? getChampionName(filters.championId) : "All Champions"}</span>
                <TriangleArrowDown className="dropdown-indicator" />
              </div>
            }
            isMobile={isMobile}
            fontSize={14}
            defaultSearch={true}
          />
        </>
      ) : (
        <MultiSearch filters={{ championId: filters.championId, duoRiotId: filters.duoRiotId }} onFilterChange={onFilterChange} />
      )}
    </React.Fragment>
  );

  return (
    <div className={classnames("match-block_filters", className)}>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        {(mediaQueryClassNames) => (
          <div className={classnames(mediaQueryClassNames, "flex-center")}>
            <Filters />
          </div>
        )}
      </MediaQuery>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        {(mediaQueryClassNames) => (
          <div className={classnames(mediaQueryClassNames, "flex-center")}>
            <Filters isMobile />
          </div>
        )}
      </MediaQuery>
    </div>
  );
}
