import { CHAMPION_STATS_OPTIONS, CHAMPION_STATS_DEFAULT } from "./filter-options-profile/champion-stats";
import { SUMMONER_PROFILE_PAGES } from "@ugg/shared/pages/summoner-profile-pages";

export const validParamsProfile = {
  [SUMMONER_PROFILE_PAGES.OVERVIEW]: {},
  [SUMMONER_PROFILE_PAGES.LIVE_GAME]: {},
  [SUMMONER_PROFILE_PAGES.MATCH]: {},
  [SUMMONER_PROFILE_PAGES.CHAMPION_STATS]: {
    queueType: CHAMPION_STATS_OPTIONS.queueType,
    role: CHAMPION_STATS_OPTIONS.role,
    season: CHAMPION_STATS_OPTIONS.season,
    default: CHAMPION_STATS_DEFAULT,
  },
};

export const global_exemptions = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "url",
  "gclid",
  "gtm",
  "iframe",
];
