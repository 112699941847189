import { calculateKDA } from "@ugg/shared/utils/kda";
import { round } from "@ugg/shared/utils/math";
import { BasicChampionPerformance } from "@ugg/shared/api/requests/summoner-profiles/player-statistics";

export interface ChampionStatsInfo extends BasicChampionPerformance {
  played: {
    value: number;
    totalMatches: number;
    wins: number;
    losses: number;
    winRate: number;
  };
  winRate: number;
  kda: {
    ratio: string;
    avgKills: number;
    avgDeaths: number;
    avgAssists: number;
  };
  avgGold: number;
  avgCs: number;
  maxKills: number;
  maxDeaths: number;
  avgDamage: number;
  avgDamageTaken: number;
  doubleKills: number;
  tripleKills: number;
  quadraKills: number;
  pentaKills: number;
  firstRate: number;
  top4: number;
  avgPlacement: number;
}

export function formatSummonerChampionStats(data: BasicChampionPerformance[]) {
  if (!Array.isArray(data)) {
    return [];
  }

  let noDupes: Record<number, BasicChampionPerformance> = {};
  const notAddable = ["maxKills", "maxDeaths"];
  for (const champion of data) {
    if (noDupes[champion.championId]) {
      const keys = Object.keys(champion) as Array<keyof BasicChampionPerformance>;
      for (const key of keys) {
        const value = champion[key];
        if (key !== "championId") {
          if (notAddable.includes(key)) {
            if (noDupes[champion.championId][key]) {
              noDupes[champion.championId][key] = Math.max(noDupes[champion.championId][key], value);
            } else {
              noDupes[champion.championId][key] = value;
            }
          } else {
            noDupes[champion.championId][key] = noDupes[champion.championId][key] + value;
          }
        }
      }
    } else {
      noDupes[champion.championId] = { ...champion };
    }
  }
  const noDupesValues = Object.values(noDupes);

  return noDupesValues.map<ChampionStatsInfo>((champion) => {
    const {
      assists,
      championId,
      cs,
      damage,
      damageTaken,
      deaths,
      gold,
      kills,
      maxDeaths,
      maxKills,
      totalMatches,
      wins,
      doubleKills,
      tripleKills,
      quadraKills,
      pentaKills,
      firstPlace,
      totalPlacement,
    } = champion;

    const winRate = round(((wins || 0) / totalMatches) * 100);
    const losses = totalMatches - (wins || 0);
    const avgKills = round((kills || 0) / totalMatches, 1);
    const avgDeaths = round((deaths || 0) / totalMatches, 1);
    const avgAssists = round((assists || 0) / totalMatches, 1);
    const ratio = calculateKDA(kills || 0, deaths || 0, assists || 0);
    const avgCs = round((cs || 0) / totalMatches, 1);
    const avgGold = round((gold || 0) / totalMatches);
    const avgDamage = round((damage || 0) / totalMatches);
    const avgDamageTaken = round((damageTaken || 0) / totalMatches);
    // Arena
    const firstRate = round((firstPlace / totalMatches) * 100, 1);
    const top4 = round(((wins || 0) / totalMatches) * 100, 1);
    const avgPlacement = round(totalPlacement / totalMatches, 2);

    return {
      ...champion,
      played: {
        value: totalMatches,
        totalMatches,
        wins: wins || 0,
        losses,
        winRate,
      },
      winRate,
      kda: {
        ratio,
        avgKills,
        avgDeaths,
        avgAssists,
      },
      avgGold,
      avgCs,
      maxKills: maxKills || 0,
      maxDeaths: maxDeaths || 0,
      avgDamage,
      avgDamageTaken,
      doubleKills: doubleKills || 0,
      tripleKills: tripleKills || 0,
      quadraKills: quadraKills || 0,
      pentaKills: pentaKills || 0,
      firstRate: firstRate || 0,
      top4: top4 || 0,
      avgPlacement: avgPlacement || 0,
    };
  });
}
