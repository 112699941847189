import classNames from "classnames";
import { getNotFoundImg } from "@outplayed/riot-assets";

export function NoTableData({ className }: { className?: string }) {
  return (
    <div className={classNames("flex flex-col items-center justify-center", className)}>
      <img className="w-[250px]" src={getNotFoundImg()} alt="No Rows Rengar" />
      <div className="mt-[15px] text-[16px] text-center">Sorry, no data available.</div>
      <div className="mt-[15px] text-[16px] text-center">If you believe this is an error, try checking again later.</div>
    </div>
  );
}
