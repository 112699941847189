import { window } from "global";
import { useState, useEffect } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import {
  useUpdateDispatch,
  ActionTypes,
  ProfileUpdateState,
} from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { RankBlock } from "@ugg/shared/components/SummonerProfiles/overview/rank/RankBlock";
import { PlayedChampionsContainer } from "@ugg/shared/components/SummonerProfiles/overview/played-champions/PlayedChampionsContainer";
import { MatchBlock } from "@ugg/shared/components/SummonerProfiles/overview/match-history/MatchBlock";
import { RecentlyPlayedWith } from "@ugg/shared/components/SummonerProfiles/overview/recently-played-with/RecentlyPlayedWith";
import { MatchSummary } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";

export function SummonerProfileOverviewPage() {
  const location = useLocation<{ scrollTop: boolean }>();
  const dispatch = useUpdateDispatch();
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const [refetchedMatches, setRefetchedMatches] = useState<MatchSummary[] | null>(null);
  const [matchSummariesRPW, setMatchSummariesRPW] = useState<MatchSummary[] | null>(null);

  const onUpdateCompleted: ProfileUpdateState["onUpdateCompleted"] = (data) => {
    const { matchSummary } = data || {};
    const { fetchPlayerMatchSummaries } = (matchSummary && matchSummary.data) || {};
    const { matchSummaries } = fetchPlayerMatchSummaries || {};
    setRefetchedMatches(matchSummaries);
  };
  const onUpdateError: ProfileUpdateState["onUpdateError"] = (error) => {
    // console.log("on update error", error)
  };

  useEffect(() => {
    if (location.state && location.state.scrollTop) {
      window.scrollTo(0, 0);
    }
    dispatch({ type: ActionTypes.ON_COMPLETED, payload: onUpdateCompleted });
    dispatch({ type: ActionTypes.ON_ERROR, payload: onUpdateError });
  }, []);

  // Reset update and matches on profile change
  useEffect(() => {
    setRefetchedMatches(null);
  }, [riotUserName, riotTagLine, region]);

  const summonerProfileOverviewClassNames = classnames("summoner-profile_overview w-[1016px] mt-[24px]");

  return (
    <div className={summonerProfileOverviewClassNames}>
      <div className="summoner-profile_overview__side">
        <RankBlock />
        <PlayedChampionsContainer maxCount={7} />
        {matchSummariesRPW && <RecentlyPlayedWith matchSummariesRPW={matchSummariesRPW} />}
      </div>
      <div className="summoner-profile_overview__main">
        <MatchBlock refetchedMatches={refetchedMatches} setMatchSummariesRPW={setMatchSummariesRPW} />
      </div>
    </div>
  );
}
