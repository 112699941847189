import classNames from "classnames";
import { ArenaLiveGameTable } from "./ArenaLiveGameTable";
import { ArenaLiveGameBanList } from "./ArenaLiveGameBanList";
import { LiveGameData } from "@ugg/shared/api/requests/summoner-profiles/live-game";
import { LiveGameHeader } from "@ugg/shared/components/SummonerProfiles/live-game/LiveGameHeader";

interface ArenaLiveGameContainerProps {
  className?: string;
  data: LiveGameData["getLiveGame"];
  riotUserName: string;
  riotTagLine: string;
  regionId: string;
  refresh?: () => void;
}

export function ArenaLiveGameContainer(props: ArenaLiveGameContainerProps) {
  const { className, data, riotUserName, riotTagLine, regionId, refresh } = props;

  return (
    <div className={classNames("bg-purple-500", className)}>
      <LiveGameHeader className="border-b-[1px] border-purple-500" data={data} refresh={refresh} />
      <ArenaLiveGameBanList data={data} />
      <ArenaLiveGameTable data={data} riotUserName={riotUserName} riotTagLine={riotTagLine} regionId={regionId} />
    </div>
  );
}
