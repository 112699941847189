import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";

export const GET_PLAYER_OVERALL_RANKING = gql`
  query getPlayerOverallRanking($queueType: Int, $riotUserName: String, $riotTagLine: String, $regionId: String) {
    overallRanking(queueType: $queueType, riotUserName: $riotUserName, riotTagLine: $riotTagLine, regionId: $regionId) {
      overallRanking
      totalPlayerCount
    }
  }
`;

interface LadderRankingData {
  overallRanking: {
    overallRanking: number;
    totalPlayerCount: number;
  };
}

export function usePlayerOverallRanking(
  riotUserName: string,
  riotTagLine: string,
  regionId: string,
  queueType: number,
  queryOptions: QueryHookOptions = {},
) {
  return useQuery<LadderRankingData>(GET_PLAYER_OVERALL_RANKING, {
    ...queryOptions,
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
      queueType,
    },
  });
}
