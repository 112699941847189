import React, { useContext, useReducer, createContext } from "react";

export interface ProfileUpdateState {
  onUpdateCompleted: (data: Record<string, any>) => void;
  onUpdateError: (error: Error) => void;
  isUpdatingProfile: boolean;
  profileRefetches: Record<string, () => Promise<any>>;
}

export const profileUpdateInitialState: ProfileUpdateState = {
  onUpdateCompleted: () => {},
  onUpdateError: () => {},
  isUpdatingProfile: false,
  profileRefetches: {
    // obj {
    //  [refetchKey]: refetchPromiseFn,
    //   Example properties
    //  "matchHistory": refetch,
    //  "rankBlock": () => Promise.resolve({some data});
    // }
    // Why functions that return promises?
    // - We will execute all functions at once when necessary (after update is complete)
    //   Promises will allows us to know when all refetching is done using Promise.all
  },
};

export enum ActionTypes {
  IS_UPDATING_PROFILE = "isUpdatingProfile",
  ON_COMPLETED = "onCompleted",
  ON_ERROR = "onError",
  ADD_UPDATES = "addUpdates",
  RESET_UPDATES = "resetUpdates",
}

export type UpdateActions =
  | { type: ActionTypes.IS_UPDATING_PROFILE; payload: boolean }
  | { type: ActionTypes.ON_COMPLETED; payload: ProfileUpdateState["onUpdateCompleted"] }
  | { type: ActionTypes.ON_ERROR; payload: ProfileUpdateState["onUpdateError"] }
  | { type: ActionTypes.ADD_UPDATES; payload: ProfileUpdateState["profileRefetches"] }
  | { type: ActionTypes.RESET_UPDATES };

export function updateReducer(state: ProfileUpdateState, action: UpdateActions) {
  switch (action.type) {
    case ActionTypes.IS_UPDATING_PROFILE:
      return { ...state, isUpdatingProfile: action.payload };
    case ActionTypes.ON_COMPLETED:
      return { ...state, onUpdateCompleted: action.payload };
    case ActionTypes.ON_ERROR:
      return { ...state, onUpdateError: action.payload };
    case ActionTypes.ADD_UPDATES:
      if (!!action.payload && action.payload.constructor === Object) {
        return {
          ...state,
          profileRefetches: {
            ...state.profileRefetches,
            ...action.payload,
          },
        };
      } else {
        return state;
      }
    case ActionTypes.RESET_UPDATES:
      return { ...state, ...profileUpdateInitialState };
    default:
      return state;
  }
}

export const UpdateDispatchContext = createContext<React.Dispatch<UpdateActions>>((value) => {});
export function useUpdateDispatch() {
  return useContext(UpdateDispatchContext);
}

export const UpdateStateContext = createContext(profileUpdateInitialState);
export function useUpdateState() {
  return useContext(UpdateStateContext);
}

export function SummonerProfileUpdateProvider(props: { children: React.ReactNode | React.ReactNode[] }) {
  const [state, dispatch] = useReducer(updateReducer, profileUpdateInitialState);

  return (
    <UpdateDispatchContext.Provider value={dispatch}>
      <UpdateStateContext.Provider value={state}>{props.children}</UpdateStateContext.Provider>
    </UpdateDispatchContext.Provider>
  );
}
