import classNames from "classnames";
import { getRiotAssetsContext, getIcon } from "@outplayed/riot-assets";
import { ReactComponent as CircleCross } from "@ugg/shared/assets/svg/circle-cross.svg";

export function ChampionBan({ className, championId }: { className?: string; championId: number }) {
  const { getChampionImg } = getRiotAssetsContext();

  return (
    <div
      className={classNames(
        "relative flex-none w-[30px] h-[30px] rounded-[3px] border-[1px] border-accent-purple-700",
        className,
      )}
    >
      <div className="flex items-center justify-center w-full h-full rounded-[3px] overflow-hidden">
        {championId && championId >= 1 ? (
          <img className="w-full h-full flex-none scale-[1.1]" src={getChampionImg(championId)} />
        ) : (
          <img className="w-full h-full flex-none " src={getIcon("lol/icons/blankChampIcon.svg")} />
        )}
      </div>
      <CircleCross className="absolute left-[50%] bottom-0 w-[12px] translate-x-[-50%] translate-y-[50%] rounded-[50%] bg-purple-200" />
    </div>
  );
}
