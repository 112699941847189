import gql from "graphql-tag";
import { useMutation, MutationHookOptions } from "@apollo/client";
import { GET_SUMMONER_PROFILE_SIMPLE } from "./profile-init";
import { useUserState } from "../accounts/user-state";
import { useSummonerProfileInitSimple } from "./profile-init";

export const SET_PROFILE_CUSTOMIZATIONS = gql`
  mutation SetProfileCustomizations($regionId: String, $riotUserName: String, $riotTagLine: String, $headerBg: String) {
    setProfileCustomizations(
      regionId: $regionId
      riotUserName: $riotUserName
      riotTagLine: $riotTagLine
      headerBg: $headerBg
      twitchLink: ""
      twitchName: ""
      twitterLink: ""
      twitterName: ""
      youtubeLink: ""
      youtubeName: ""
      pinnedMatchId: "0"
    ) {
      headerBg
    }
  }
`;

interface SetProfileCustomizationsData {
  headerBg: string;
}

interface NewCustomizationData {
  headerBg?: string;
}

export function updateProfileCustomizations(mutationOptions: MutationHookOptions = {}) {
  const { data: userState } = useUserState();
  const { lolSettings, premium } = (userState && userState.getState) || {};
  const { summoners } = lolSettings || {};
  const { riotUserName = "", riotTagLine = "", regionId = "" } = (summoners && summoners[0]) || {};
  const { loading, data, error, variables } = useSummonerProfileInitSimple(
    regionId || "",
    riotUserName,
    riotTagLine,
    {},
    {
      skip: !regionId || !riotUserName || !riotTagLine,
    },
  );
  const { playerInfo, customizationData } = (data && data.profileInitSimple) || {};

  const [setProfileCustomizations, mutationResults] = useMutation<SetProfileCustomizationsData>(SET_PROFILE_CUSTOMIZATIONS, {
    ...mutationOptions,
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SUMMONER_PROFILE_SIMPLE,
        variables,
        fetchPolicy: "network-only",
      },
    ],
  });

  return [
    (newCustomizations: NewCustomizationData) => {
      const combinedCustomizations = {
        riotUserName: playerInfo?.riotUserName,
        riotTagLine: playerInfo?.riotTagLine,
        regionId: playerInfo?.regionId,
        ...customizationData,
        ...(newCustomizations || {}),
      };
      if (!loading && data) {
        setProfileCustomizations({ variables: combinedCustomizations });
      }
    },
    mutationResults,
  ] as const;
}
