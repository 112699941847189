import classNames from "classnames";
import { useFilterManager, FilterManagerWrapper } from "@ugg/shared/components/FilterManager/FilterManager";
import { validParamsProfile } from "@ugg/shared/query-params/valid-params-profile";
import { getValidParamKeys } from "@ugg/shared/query-params/params-helpers";
import { MediaQuery } from "@outplayed/responsive";
import { ReactComponent as FilterIcon } from "@ugg/shared/assets/svg/filters-icon.svg";
import Role from "@ugg/shared/components/FilterManager/filters/Role";
import RoleSplit from "@ugg/shared/components/FilterManager/filters/RoleSplit";
import QueueType from "@ugg/shared/components/FilterManager/filters/QueueType";
import Season from "@ugg/shared/components/FilterManager/filters/Season";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { SUMMONER_PROFILE_PAGES } from "@ugg/shared/pages/summoner-profile-pages";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";

export function ChampionStatsFilterManager(props: { className?: string; hasRole?: boolean }) {
  const { className, hasRole } = props;
  const page = SUMMONER_PROFILE_PAGES.CHAMPION_STATS;
  const filterManagerRes = useFilterManager(page, validParamsProfile);
  const { filters } = filterManagerRes;

  const validParamKeys = getValidParamKeys(validParamsProfile, page);
  const options = validParamsProfile[page];

  const isLatestSeason = parseInt(filters.season) === INTERNAL_CURRENT_SEASON;

  // Temp classname. Fix later
  return (
    <FilterManagerWrapper className={classNames(`${process.env.PLATFORM !== "app" ? "_filter-manager" : ""}`, props.className)}>
      <div className="filter-manager_label">
        <FilterIcon />
        <MediaQuery min="TABLET" max="DESKTOP_LARGE">
          <span>Filters</span>
        </MediaQuery>
      </div>
      {isLatestSeason && hasRole && validParamKeys.role && (
        <>
          <MediaQuery min="MOBILE_SMALL" max="TABLET">
            <Role {...filterManagerRes} options={"role" in options ? options.role : []} />
          </MediaQuery>
          <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
            <RoleSplit {...filterManagerRes} options={"role" in options ? options.role : []} />
          </MediaQuery>
        </>
      )}
      {validParamKeys.queueType && <QueueType {...filterManagerRes} options={"queueType" in options ? options.queueType : []} />}
      {validParamKeys.season && <Season {...filterManagerRes} options={"season" in options ? options.season : []} />}
    </FilterManagerWrapper>
  );
}
