import classNames from "classnames";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { useFetchSummonerMatchSummaries } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

export function SummonerProfileBackground({ className }: { className?: string }) {
  const { getChampionNameId, getChampionIdByName, getChampionSplashImg } = getRiotAssetsContext();
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const { loading, data, error } = useSummonerProfileInitSimple(region, riotUserName, riotTagLine);
  const {
    loading: matchSummariesLoading,
    data: matchSummariesData,
    error: matchSummariesError,
  } = useFetchSummonerMatchSummaries(region, riotUserName, riotTagLine, {}, { ssr: false });

  const { profileInitSimple } = data || {};
  const { customizationData, memberStatus } = profileInitSimple || {};
  const { fetchPlayerMatchSummaries } = matchSummariesData || {};
  const { matchSummaries } = fetchPlayerMatchSummaries || {};

  // const [headerBg, setHeaderBg] = useState(customizationData?.headerBg);

  const champUsageMap: { [key: number]: number } = {};
  let mostCommonChamp = 0;
  let mostCommonCount = 0;

  (matchSummaries || []).forEach((match) => {
    if (!champUsageMap[match.championId]) champUsageMap[match.championId] = 1;
    else champUsageMap[match.championId] += 1;

    if (champUsageMap[match.championId] > mostCommonCount) {
      mostCommonCount = champUsageMap[match.championId];
      mostCommonChamp = match.championId;
    }
  });

  let headerBg: string | null = null;
  if (customizationData?.headerBg) {
    headerBg = customizationData?.headerBg;
  } else if (mostCommonChamp > 0) {
    headerBg = `${getChampionNameId(mostCommonChamp)}_${1}`;
  }

  const headerChampionSplit = (headerBg && headerBg.split("_")) || [];
  const championId = getChampionIdByName(headerChampionSplit[0]);
  const headerImg = getChampionSplashImg(championId, { splashId: parseInt(headerChampionSplit[1]) });

  if (!headerBg) {
    return null;
  }

  return (
    <div className={classNames("absolute top-0 bottom-0 left-0 right-0 z-[-1] h-full", className)}>
      <div className="sticky">
        <div className="flex items-start justify-center w-full h-[700px] overflow-hidden">
          <img className="w-full min-w-[500px]" src={headerImg} />
        </div>
        <div className="absolute top-0 w-full h-[800px]">
          <div className="w-full h-full bg-[50%_-50px] bg-[linear-gradient(180deg,rgba(7,7,32,0.28)_0%,#070720_100%),linear-gradient(270deg,#070720_2.27%,rgba(7,7,32,0.00)_37.69%),radial-gradient(60.09%_84.73%_at_100%_20.89%,rgba(7,7,32,0.00)_0%,#070720_100%)]" />
        </div>
      </div>
    </div>
  );
}

// Old gradient
// radial-gradient(50%_40%_at_48%_23%,rgba(7,7,32,0)_20%,#070720_100%),linear-gradient(90deg,#070720_0%,rgba(7,7,32,0)_100%),linear-gradient(0deg,#070720_10%,rgba(7,7,32,0)_100%)
// New gradient
// linear-gradient(180deg,rgba(7,7,32,0.28)_0%,#070720_100%),linear-gradient(270deg,#070720_2.27%,rgba(7,7,32,0.00)_37.69%),radial-gradient(60.09%_84.73%_at_100%_20.89%,rgba(7,7,32,0.00)_0%,#070720_100%);
