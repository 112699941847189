import { useContext } from "react";
import { LightModeContext, LightModeDispatchContext } from "@ugg/shared/components/common/LightModeContext";

export function useLightMode() {
  const lightMode = useContext(LightModeContext);
  const setLightMode = useContext(LightModeDispatchContext);

  const setLightModeEnabled = (lightModeEnabled: boolean) => {
    if (lightModeEnabled) {
      localStorage.setItem("uggLightModeEnabled", "true");
    } else {
      localStorage.removeItem("uggLightModeEnabled");
    }

    setLightMode?.((prevState) => ({
      ...prevState,
      enabled: lightModeEnabled,
    }));
  };

  return {
    lightMode,
    lightModeOn: lightMode.enabled && lightMode.allowed,
    setLightModeEnabled,
  };
}
