import { window } from "global";
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { NetworkStatus } from "@apollo/client";
import { MediaQuery } from "@outplayed/responsive";
import {
  UpdateStateContext,
  UpdateDispatchContext,
  ActionTypes,
} from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import MatchFilters from "./MatchFilters";
import { MatchStats } from "./MatchStats";
import MatchCard from "@ugg/shared/components/SummonerProfiles/match-card/MatchCard";
import {
  getParamsForFetchMatchSummaryQuery,
  useFetchSummonerMatchSummaries,
  MatchSummary,
} from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { useSummonerRankSnapshots } from "@ugg/shared/api/requests/summoner-profiles/rank-snapshots";
import { useMatchLPs } from "./match-history-helpers";
import { QUEUE_TYPE_MAP, defaultMatchFilters } from "./match-history-filters";
import { splitRiotId } from "@ugg/shared/utils/riot-id-helpers";
import { Transition } from "react-transition-group";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";

interface MatchBlockProps {
  refetchedMatches: MatchSummary[] | null;
  setMatchSummariesRPW: (matches: MatchSummary[] | null) => void;
}

export function MatchBlock(props: MatchBlockProps) {
  const dispatch = useContext(UpdateDispatchContext);
  const { isUpdatingProfile } = useContext(UpdateStateContext);
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const { refetchedMatches, setMatchSummariesRPW } = props;

  const [matchSummaryPage, setMatchSummaryPage] = useState(1);
  const [filters, setFilters] = useState(defaultMatchFilters);
  const [duoRiotUserName, duoRiotTagLine] = splitRiotId(filters.duoRiotId);
  const apiParams = {
    queueType: QUEUE_TYPE_MAP[filters.queueType],
    championId: parseInt(filters.championId),
    duoRiotId: filters.duoRiotId,
    duoRiotUserName,
    duoRiotTagLine,
  };

  const {
    data: rankSnapshots,
    loading: loadingRankSnapshots,
    refetch: refetchRankSnapshots,
    variables: variablesRankSnapshots,
  } = useSummonerRankSnapshots(region, riotUserName, riotTagLine, {
    ssr: false,
  });
  const { getSummonerRankSnapshots } = rankSnapshots || {};

  const {
    loading: matchSummariesLoading,
    data: matchSummariesData,
    error: matchSummariesError,
    refetch: matchSummariesRefetch,
    fetchMore,
    variables: matchSummariesVariables,
    networkStatus: networkStatusMatchSummaries,
  } = useFetchSummonerMatchSummaries(region, riotUserName, riotTagLine, apiParams, {
    ssr: false,
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreMatches = (page: number) => {
    fetchMore({
      variables: getParamsForFetchMatchSummaryQuery(region, riotUserName, riotTagLine, { ...apiParams, page }),
      updateQuery: (previousQueryResult, { fetchMoreResult, variables }) => {
        const previousMatches = previousQueryResult.fetchPlayerMatchSummaries.matchSummaries;
        const fetchMoreMatches = fetchMoreResult.fetchPlayerMatchSummaries.matchSummaries;
        fetchMoreResult.fetchPlayerMatchSummaries = {
          ...fetchMoreResult.fetchPlayerMatchSummaries,
          matchSummaries: [...previousMatches, ...fetchMoreMatches].reduce((acc, cur) => {
            // only push if the match is not already in the complete list
            return acc.some((match) => match.matchId === cur.matchId) ? acc : [...acc, cur];
          }, [] as MatchSummary[]),
          totalNumMatches:
            previousQueryResult.fetchPlayerMatchSummaries.totalNumMatches +
            fetchMoreResult.fetchPlayerMatchSummaries.totalNumMatches,
        };

        return { ...fetchMoreResult };
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: ActionTypes.ADD_UPDATES,
      payload: {
        matchSummary: matchSummariesRefetch,
        rankSnapshots: refetchRankSnapshots,
      },
    });
  }, [JSON.stringify(matchSummariesVariables), JSON.stringify(variablesRankSnapshots)]);

  useEffect(() => {
    if (matchSummaryPage !== 1) {
      fetchMoreMatches(matchSummaryPage);
    }
  }, [matchSummaryPage]);

  useEffect(() => {
    setFilters(defaultMatchFilters);
  }, [riotUserName, riotTagLine, region]);

  const { fetchPlayerMatchSummaries } = matchSummariesData || {};
  const { finishedMatchSummaries, matchSummaries, totalNumMatches } = fetchPlayerMatchSummaries || {};
  const matchSummaryPageRef = useRef<HTMLDivElement>(null);
  // Might seem redundant to store matches in another state but
  // it's used to help sync with new matches from the update button
  const [renderedMatchSummaries, setRenderedMatchSummaries] = useState({
    loading: false,
    matches: matchSummaries || [],
  });
  const [updatingMatches, setUpdatingMatches] = useState(false);
  const [prevMatchIds, setPrevMatchIds] = useState<number[]>([]);
  const [newMatchIds, setNewMatchIds] = useState<number[]>([]);

  const allowPaginationScroll = useRef(matchSummariesLoading);
  useEffect(() => {
    const onLoadScroll = (e: React.UIEvent<Window>) => {
      if (allowPaginationScroll.current && matchSummaryPageRef && matchSummaryPageRef.current) {
        const { bottom } = matchSummaryPageRef.current.getBoundingClientRect();

        if (!matchSummariesLoading && !finishedMatchSummaries && window.innerHeight - bottom >= -100) {
          allowPaginationScroll.current = false;
          setMatchSummaryPage((prevPage) => ++prevPage);
        }
      }
    };

    window.addEventListener("scroll", onLoadScroll);

    return () => {
      window.removeEventListener("scroll", onLoadScroll);
    };
  }, [matchSummariesLoading, finishedMatchSummaries]);

  useEffect(() => {
    if (isUpdatingProfile && !updatingMatches) {
      setUpdatingMatches(true);
      const prevMatchIds = (matchSummaries || []).map((match) => match.matchId);
      setPrevMatchIds(prevMatchIds);
    }
    if (refetchedMatches && !isUpdatingProfile && updatingMatches) {
      const curMatchIds = (refetchedMatches || []).map((match) => match.matchId);
      const newMatchesDiff = curMatchIds.filter((matchId) => {
        return !prevMatchIds.includes(matchId);
      });
      setUpdatingMatches(false);
      setNewMatchIds(newMatchesDiff);
    }
  }, [refetchedMatches, isUpdatingProfile, updatingMatches]);

  useEffect(() => {
    setMatchSummaryPage(1);
    setMatchSummariesRPW(null);
    setPrevMatchIds([]);
    setNewMatchIds([]);
    setUpdatingMatches(false);
  }, [riotUserName, riotTagLine, region, JSON.stringify(matchSummariesVariables)]);

  useEffect(() => {
    if (networkStatusMatchSummaries === NetworkStatus.loading || networkStatusMatchSummaries === NetworkStatus.setVariables) {
      setRenderedMatchSummaries((prevState) => ({ ...prevState, loading: true }));
    }
  }, [networkStatusMatchSummaries]);

  useEffect(() => {
    if (matchSummaries && !matchSummariesLoading && !updatingMatches && !isUpdatingProfile) {
      setRenderedMatchSummaries({ loading: false, matches: matchSummaries });
      setMatchSummariesRPW(matchSummaries);
    }

    allowPaginationScroll.current = !matchSummariesLoading;
  }, [matchSummaries, matchSummariesLoading, updatingMatches, isUpdatingProfile]);

  const onFiltersChanged = useCallback(() => {
    setMatchSummaryPage(1);
  }, []);

  const matchLPs = useMatchLPs(renderedMatchSummaries.matches, getSummonerRankSnapshots);
  let content = null;
  if (loadingRankSnapshots || renderedMatchSummaries.loading) {
    content = (
      <div className="match-history_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else if (renderedMatchSummaries.matches.length !== 0) {
    content = (
      <>
        <MatchStats className="mb-[12px] border-[1px] border-purple-500" matches={renderedMatchSummaries.matches} />
        {renderedMatchSummaries.matches.map((match, index) => {
          const isNew = newMatchIds.includes(match.matchId);
          const matchProps = {
            data: match,
            isNew,
            matchLP: matchLPs[match.matchId],
            // matchLP: index === 0 ? {lp: 14, promoProgress: undefined} : {lp: undefined, promoProgress: "WLLWN"},
            observable: index > 9,
          };

          return !isNew ? (
            <div key={index} className={"match-history_match-card"}>
              <MatchCard {...matchProps} />
            </div>
          ) : (
            <Transition key={index} timeout={0} in>
              {(state) => (
                <div className={`match-history_match-card match-history_match-card__${state}`}>
                  <MatchCard {...matchProps} />
                </div>
              )}
            </Transition>
          );
        })}
        {networkStatusMatchSummaries === NetworkStatus.fetchMore && (
          <div className="spinthatshit-loader load-more">
            <div className="spinner"></div>
          </div>
        )}
      </>
    );
  } else {
    content = (
      <div className="match-history_empty">
        No games for current filters. Try changing the filters or playing some more matches.
      </div>
    );
  }

  return (
    <div className="content-section content-section_no-padding match-block" ref={matchSummaryPageRef}>
      <div className="match-block_header">
        <HeaderBullet>
          <span className="leading-none">Match History</span>
        </HeaderBullet>
        <MediaQuery min="TABLET" max="DESKTOP_LARGE">
          <MatchFilters filters={filters} setFilters={setFilters} onFiltersChanged={onFiltersChanged} />
        </MediaQuery>
      </div>
      {content}
    </div>
  );
}
