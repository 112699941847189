import { useLazyQuery, LazyQueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";

interface UpdatePlayerProfileData {
  updatePlayerProfile: {
    success: boolean;
    errorReason: string;
  };
}

const UPDATE_PLAYER_PROFILE = gql`
  query UpdatePlayerProfile($regionId: String!, $riotUserName: String!, $riotTagLine: String!) {
    updatePlayerProfile(regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      success
      errorReason
    }
  }
`;

export function useUpdateProfile(queryOptions: LazyQueryHookOptions = {}) {
  const [exec, results] = useLazyQuery<UpdatePlayerProfileData>(UPDATE_PLAYER_PROFILE, {
    ...queryOptions,
  });

  const newExec = (regionId: string, riotUserName: string, riotTagLine: string, queryOptions: LazyQueryHookOptions = {}) => {
    exec({
      ...queryOptions,
      variables: {
        regionId,
        riotUserName,
        riotTagLine,
      },
    });
  };

  return [newExec, results] as const;
}
