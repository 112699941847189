import { useState, useEffect, useRef } from "react";
import SummonerTag from "@ugg/shared/components/common/SummonerTag";
import { SummonerTag as ISummonerTag } from "@ugg/shared/api/requests/summoner-profiles/summoner-tags";

interface LiveGameTagsProps {
  championId: number;
  tags: ISummonerTag[];
}

export function LiveGameTags(props: LiveGameTagsProps) {
  const { championId, tags } = props;
  const tagsListRef = useRef<HTMLDivElement>(null);
  const tagPreviewRef = useRef<HTMLDivElement>(null);
  const tagTruncateRef = useRef<HTMLDivElement>(null);
  const [previewTagLimit, setPreviewTagLimit] = useState<number>(0);
  const numHiddenTags = tags.length - previewTagLimit;

  useEffect(() => {
    const resizeHandler = () => {
      const TAG_GAP = 8;
      if (tagPreviewRef.current && tagTruncateRef.current) {
        let numPreviewTags = 0;
        const children = Array.from(tagPreviewRef.current.children);
        const lastTagIndex = children.length - 1;
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (child instanceof HTMLElement && !child.className.includes("truncate-ref")) {
            if (i === lastTagIndex) {
              if (
                child.offsetLeft + child.clientWidth + TAG_GAP <=
                tagPreviewRef.current.offsetLeft + tagPreviewRef.current.clientWidth
              ) {
                numPreviewTags += 1;
              }
            } else if (child.offsetLeft + child.clientWidth + TAG_GAP <= tagTruncateRef.current.offsetLeft) {
              numPreviewTags += 1;
            }
          }
        }
        setPreviewTagLimit(numPreviewTags);
      }
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [tags]);

  const displayTags = (championId: number, count?: number) => {
    return tags.map((tagData, index) => {
      return (
        (count === undefined || count > index) && (
          <SummonerTag
            key={index}
            className="h-[15px]"
            size="small"
            type={tagData.type}
            tag={tagData.tag}
            meta={tagData.meta}
            championId={championId}
          />
        )
      );
    });
  };

  return (
    <div className="relative h-[15px]">
      <div className="absolute invisible flex gap-[8px] w-full overflow-hidden" ref={tagPreviewRef}>
        {displayTags(championId)}
        <div className="truncate-ref absolute flex top-0 right-0" ref={tagTruncateRef}>
          <SummonerTag tag="..." type="neutral" size="small" />
        </div>
      </div>
      <div className="flex gap-[8px]" ref={tagsListRef}>
        {displayTags(championId, previewTagLimit)}
        {numHiddenTags > 0 && <SummonerTag tag="..." type="neutral" size="small" />}
      </div>
    </div>
  );
}
