import ironMarkerURL, { ReactComponent as IronPromotionMarker } from "@ugg/shared/assets/svg/lp-graph/iron-promotion-marker.svg";
import bronzeMarkerURL, {
  ReactComponent as BronzePromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/bronze-promotion-marker.svg";
import silverMarkerURL, {
  ReactComponent as SilverPromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/silver-promotion-marker.svg";
import goldMarkerURL, { ReactComponent as GoldPromotionMarker } from "@ugg/shared/assets/svg/lp-graph/gold-promotion-marker.svg";
import platinumMarkerURL, {
  ReactComponent as PlatinumPromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/platinum-promotion-marker.svg";
import diamondMarkerURL, {
  ReactComponent as DiamondPromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/diamond-promotion-marker.svg";
import masterMarkerURL, {
  ReactComponent as MasterPromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/master-promotion-marker.svg";
import grandmasterMarkerURL, {
  ReactComponent as GrandmasterPromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/grandmaster-promotion-marker.svg";
import challengerMarkerURL, {
  ReactComponent as ChallengerPromotionMarker,
} from "@ugg/shared/assets/svg/lp-graph/challenger-promotion-marker.svg";

import { RankS } from "@ugg/shared/utils/rank-helpers";

// If any of these colors change,
// please change the fill color of the promotion markers
// located ---> /svg/lp-graph/*-promotion-marker.svg
export function getLPGraphTierColor(tier: string, lightModeOn?: boolean) {
  switch (tier && tier.toLowerCase()) {
    case RankS.IRON:
      return lightModeOn ? "#504547" : "#7D6E71";
    case RankS.BRONZE:
      return lightModeOn ? "#894F37" : "#A76C54";
    case RankS.SILVER:
      return lightModeOn ? "#517177" : "#80989D";
    case RankS.GOLD:
      return lightModeOn ? "#815624" : "#CD8837";
    case RankS.PLATINUM:
      return lightModeOn ? "#366967" : "#25ACD6";
    case RankS.EMERALD:
      return "#149C3A";
    case RankS.DIAMOND:
      return "#8141EB";
    case RankS.MASTER:
      return lightModeOn ? "#6E3F94" : "#A4584E";
    case RankS.GRANDMASTER:
      return lightModeOn ? "#973636" : "#CD4545";
    case RankS.CHALLENGER:
      return lightModeOn ? "#EBAB32" : "#F4C874";
    default:
      return "";
  }
}

export function getPromotionMarkerURL(tier: string) {
  switch (tier.toLowerCase()) {
    case RankS.IRON:
      return ironMarkerURL;
    case RankS.BRONZE:
      return bronzeMarkerURL;
    case RankS.SILVER:
      return silverMarkerURL;
    case RankS.GOLD:
      return goldMarkerURL;
    case RankS.PLATINUM:
      return platinumMarkerURL;
    case RankS.DIAMOND:
      return diamondMarkerURL;
    case RankS.MASTER:
      return masterMarkerURL;
    case RankS.GRANDMASTER:
      return grandmasterMarkerURL;
    case RankS.CHALLENGER:
      return challengerMarkerURL;
    default:
      return null;
  }
}

export function getPromotionMarkerSVG(tier: string) {
  switch (tier.toLowerCase()) {
    case RankS.IRON:
      return <IronPromotionMarker />;
    case RankS.BRONZE:
      return <BronzePromotionMarker />;
    case RankS.SILVER:
      return <SilverPromotionMarker />;
    case RankS.GOLD:
      return <GoldPromotionMarker />;
    case RankS.PLATINUM:
      return <PlatinumPromotionMarker />;
    case RankS.DIAMOND:
      return <DiamondPromotionMarker />;
    case RankS.MASTER:
      return <MasterPromotionMarker />;
    case RankS.GRANDMASTER:
      return <GrandmasterPromotionMarker />;
    case RankS.CHALLENGER:
      return <ChallengerPromotionMarker />;
    default:
      return null;
  }
}
