import classnames from "classnames";
import { Item } from "@outplayed/tooltips";

interface ItemsListProps {
  items: number[];
  patch?: string;
  dim?: number;
  winConditionClass?: string;
  disableTooltip?: boolean;
}

export default function ItemsList(props: ItemsListProps) {
  const { items, patch, dim, disableTooltip, winConditionClass } = props;

  const list = items.map((itemId, index) => {
    const validItem = itemId !== 0;
    return (
      <div
        key={index}
        className={classnames("item-wrapper", { "no-item": validItem }, winConditionClass)}
        style={{ width: `${dim}px`, height: `${dim}px` }}
      >
        {validItem && (
          <Item className="sprite" itemId={itemId} spriteSize={dim} isSprite patch={patch} disableTooltip={disableTooltip} />
        )}
      </div>
    );
  });

  return <div className="items-container">{list}</div>;
}
