import classnames from "classnames";
import { useGlobal } from "reactn";
import ChampionSkillWithLabel from "./ChampionSkillWithLabel";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

interface SkillOrderProps {
  championId: number;
  skillOrder: number[] | string[];
  skillNames?: string[];
  skillSlot?: number;
  skillKey: string;
  isPassive?: boolean;
  passiveName: string;
  rec?: boolean;
  disableTooltip?: boolean;
  ssr?: boolean;
}

const SkillOrder = (props: SkillOrderProps) => {
  const [language] = useGlobal("language");
  const { rec, championId, skillSlot = 0, skillKey, skillNames, skillOrder, passiveName, isPassive, disableTooltip, ssr } = props;

  const generateSkillOrder = (skillSlot: number, skillKey: string, skillOrder: number[] | string[]) => {
    let list = skillOrder.map((skill, index) => {
      const op = rec && index >= 11 ? "rec" : "";

      return skillSlot === skill || skillKey === skill ? (
        <div key={index} className={`skill-up ${op}`}>
          <div>{index + 1}</div>
        </div>
      ) : (
        <div key={index} className="no-skill-up">
          <div></div>
        </div>
      );
    });

    for (let i = skillOrder.length + 1; i <= 18; i++) {
      list.push(
        <div key={i} className="no-skill-up">
          <div></div>
        </div>,
      );
    }

    return list;
  };

  return (
    <div className="skill-order-row">
      <div className={classnames("skill-row-label", skillNames && "skill-row-label__with-name")}>
        <ChampionSkillWithLabel
          label={skillKey}
          labelPosition="bottomRight"
          className="skill-img"
          championId={championId}
          skillSlot={skillSlot - 1}
          disableTooltip={disableTooltip}
          isPassive={isPassive}
          ssr={ssr}
        />
        {skillNames && (
          <div className="skill-name">
            <span>{isPassive ? passiveName : skillNames[skillSlot - 1]}</span>
          </div>
        )}
      </div>
      <div className="skill-order">
        {!isPassive ? generateSkillOrder(skillSlot, skillKey, skillOrder) : <div className="blank-row"></div>}
      </div>
    </div>
  );
};

interface SkillPathProps {
  championId: number;
  rec?: boolean;
  skillOrder: number[] | string[];
  // skillMax: ,
  skillNames?: boolean;
  includePassive?: boolean;
  disableTooltip?: boolean;
  ssr?: boolean;
}

export default function SkillPath(props: SkillPathProps) {
  const { useChampionIndiv, getChampionSkillNames, getChampionPassiveJSON } = getRiotAssetsContext();
  const {
    championId,
    rec,
    skillOrder,
    // skillMax,
    skillNames,
    includePassive,
    disableTooltip,
    ssr,
  } = props;

  const [language] = useGlobal("language");
  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { ssr, language });

  if (!championIndiv) {
    return null;
  }

  const skillNamesArr = skillNames && getChampionSkillNames(championId, { optionalData: championIndiv });
  const championPassiveJSON = getChampionPassiveJSON(String(championId), { optionalData: championIndiv });
  const passiveName = (championPassiveJSON || {}).name;

  const commonProps = {
    championId,
    skillOrder,
    skillNames: skillNamesArr,
    passiveName,
    rec,
    disableTooltip,
    ssr,
  };

  if (!skillOrder || skillOrder.length === 0) {
    return null;
  }

  const skillOrderClassNames = classnames("skill-path-container");

  return (
    <div className={skillOrderClassNames}>
      <SkillOrder {...commonProps} skillSlot={1} skillKey={"Q"} />
      <SkillOrder {...commonProps} skillSlot={2} skillKey={"W"} />
      <SkillOrder {...commonProps} skillSlot={3} skillKey={"E"} />
      <SkillOrder {...commonProps} skillSlot={4} skillKey={"R"} />
      {includePassive && <SkillOrder {...commonProps} isPassive skillKey={"P"} />}
    </div>
  );
}
