import { useMemo } from "react";
import { TooltipContainer } from "@outplayed/tooltips";
import { LiveGameTable } from "./LiveGameTable";
import HeadToHead from "./HeadToHead";
import { LiveGameHeader } from "@ugg/shared/components/SummonerProfiles/live-game/LiveGameHeader";
import { ArenaLiveGameContainer } from "@ugg/shared/components/SummonerProfiles/live-game/arena/ArenaLiveGameContainer";
import LiveGameRecommendedBuild from "./LiveGameRecommendedBuild";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { useLiveGameTags, SummonerTag } from "@ugg/shared/api/requests/summoner-profiles/summoner-tags";
import { LiveGameData } from "@ugg/shared/api/requests/summoner-profiles/live-game";
import { getTeamDataPlus } from "@ugg/shared/utils/live-game-helpers";
import { QueueTypeS, hasRoles } from "@ugg/shared/utils/queue-type-helpers";
import { ReactComponent as CircleQuestion } from "svg/circle-question.svg";

interface LiveGameMainProps {
  data: LiveGameData["getLiveGame"];
  refetch: () => void;
}

export function LiveGameMain(props: LiveGameMainProps) {
  const context = useSummonerProfileContext();
  const { realRiotUserName, realRiotTagLine, region } = context;
  const { data, refetch } = props;

  const { gameType, queueId, teamA, teamB } = data;

  // Live Game Tags
  const players = [...(teamA || []), ...(teamB || [])].map((player) => ({
    riotUserName: player.riotUserName,
    riotTagLine: player.riotTagLine,
    championId: player.championId,
  }));
  const {
    data: tagData,
    loading: tagLoading,
    error: tagError,
  } = useLiveGameTags(players, region, queueId, {
    fetchPolicy: "network-only",
    skip: !data,
  });
  const { liveGameTags } = tagData || {};

  const tags = useMemo(() => {
    if (!liveGameTags) return null;

    return liveGameTags.reduce<Record<string, SummonerTag[]>>((acc, curr) => {
      acc[`${curr.riotUserName}_${curr.riotTagLine}`] = curr.tagList;
      return acc;
    }, {});
  }, [liveGameTags]);

  const tagInfo = {
    tags,
    tagLoading,
    tagError: !!tagError,
    errorTagTimeout: false,
  };

  const teamAPlus = getTeamDataPlus(teamA, 100, tagInfo);
  const teamBPlus = getTeamDataPlus(teamB, 200, tagInfo);
  const noRoles = !hasRoles(gameType);
  const hasHeadToHead = !noRoles;

  if (gameType === QueueTypeS.ARENA) {
    return (
      <div className="w-full">
        <ArenaLiveGameContainer
          data={data}
          riotUserName={realRiotUserName}
          riotTagLine={realRiotTagLine}
          regionId={region}
          refresh={() => refetch()}
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="border-b-[1px] border-purple-500">
        <LiveGameHeader
          data={data}
          refresh={refetch}
          title={
            !hasHeadToHead ? (
              "Live Game"
            ) : (
              <div className="flex items-center gap-[6px] tooltip-container">
                <span>Head to Head</span>
                <TooltipContainer
                  tooltipInfo={
                    <div className="head-to-head-tooltip">
                      <div style={{ marginBottom: 6 }}>
                        <strong>Which player wins the head to head?</strong>
                      </div>
                      <span>
                        This feature predicts the winner of each matchup by using weighted values for each player's Ranked Stats
                        (win rate and games played for current season, champion, and role) alongside the current patch's matchup
                        data for those two specific champions.
                      </span>
                    </div>
                  }
                >
                  <div>
                    <CircleQuestion className="w-[12px]" />
                  </div>
                </TooltipContainer>
              </div>
            )
          }
        />
        {hasHeadToHead && <HeadToHead className="pt-[8px] pb-[18px]" teamA={teamAPlus} teamB={teamBPlus} />}
      </div>
      <div className="w-full">
        <LiveGameTable queueType={gameType} teamA={teamAPlus} teamB={teamBPlus} />
      </div>
      <LiveGameRecommendedBuild data={data} />
    </div>
  );
}
