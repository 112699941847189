import { useState, useMemo, useRef } from "react";
import { renderToString } from "react-dom/server";
import classNames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import Highcharts from "highcharts";
import HighChartsReact, { HighchartsReactRefObject } from "highcharts-react-official";
import { SummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";
import { TeamData } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";

interface GraphProps {
  series: {
    x: number;
    y: number;
    color: string;
    championId: number;
  }[][];
}
const Graph = (props: GraphProps) => {
  const { series } = props;
  const { getChampionImg } = getRiotAssetsContext();
  const chartRef = useRef<HighchartsReactRefObject>(null);

  const yMax = Math.max(...series.flat().map((el) => el.y));
  const timeMax = Math.max(...series.flat().map((el) => el.x)) + 120000;

  const options: Highcharts.Options = {
    chart: {
      marginTop: 32,
      marginBottom: 45, //40
      marginLeft: 45, //51
      marginRight: 20,
      animation: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      min: 0,
      max: timeMax,
      gridLineWidth: 0,
      labels: {
        y: 30,
        formatter: function () {
          const minutes = Math.floor(this.value / 1000 / 60);
          return minutes + " min";
        },
        rotation: 0,
        style: {
          color: "#CDDCFE",
          fontFamily: "Inter",
          fontSize: "11px",
          textAlign: "center",
        },
      },
      // @ts-ignore
      left: 70,
      lineColor: "#070720",
      type: "datetime",
      tickLength: 0,
      tickPositioner: function () {
        const positions = [];
        // @ts-ignore
        const { dataMax, len } = this;
        if (dataMax != null) {
          const labelWidth = 34; // Width of each label, 33.68, 34
          const spacing = 7; // Minimum space between labels
          const numTicks = Math.floor(len / (labelWidth + spacing)); // Calculate the number of points that can be shown
          const tickInterval = Math.floor(dataMax / numTicks); //time between ticks
          for (let tick = 0; tick < dataMax + 40000; tick += tickInterval) {
            positions.push(tick);
          }
        }
        return positions;
      },
    },
    yAxis: {
      min: 0,
      max: yMax,
      gridLineColor: "#070720",
      labels: {
        style: {
          color: "#CDDCFE",
          fontFamily: "Inter",
          fontSize: "11px",
          textAlign: "center",
        },
      },
      tickLength: 0,
      title: { text: null },
    },

    tooltip: {
      backgroundColor: "#383864",
      borderRadius: 2,
      borderWidth: 0,
      distance: 18,
      formatter: function () {
        // @ts-ignore
        const { championId, color } = this.point;
        const src = getChampionImg(championId);
        const yValue = Highcharts.numberFormat(this.y, 0, ".", ",");
        const xValue = Math.floor(this.x / 1000 / 60) + " min";

        return renderToString(
          <div className="flex">
            <div className="flex">
              <div
                className="flex items-center justify-center w-[36px] h-[36px] rounded-[4px]"
                style={{ border: `1px solid ${color}` }}
              >
                <div className="relative flex items-center justify-center w-[30px] h-[30px] rounded-[2px] overflow-hidden">
                  <img className="flex-none scale-[1.1]" src={src} />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center ml-[8px] font-[Inter] text-[12px]">
              <div className="mb-[4px] text-white font-bold">{yValue}</div>
              <div className="text-lavender-50 font-medium">{xValue}</div>
            </div>
          </div>,
        );
      },
      headerFormat: "",
      style: {
        textAlign: "center",
      },
      useHTML: true,
    },

    series: series.map((data, index) => ({
      type: "spline",
      color: data[0].color, // line color
      data,
      lineWidth: 1,
      marker: {
        enabled: false,
        symbol: "circle",
      },
      states: {
        hover: {
          lineWidthPlus: 2,
        },
      },
    })),

    legend: {
      enabled: false,
    },
  };

  return (
    <div className="h-[478px] w-full">
      <HighChartsReact
        highcharts={Highcharts}
        ref={chartRef}
        options={options}
        containerProps={{
          style: { width: "100%", height: "100%" },
        }}
      />
    </div>
  );
};

function getMetricId(riotUserName: string | null, riotTagLine: string | null) {
  return `${riotUserName}_${riotTagLine}`;
}

interface MetricsProps {
  data: SummonerMatch;
}

export function Metrics(props: MetricsProps) {
  const { data } = props;
  const { getChampionImgFromSprite } = getRiotAssetsContext();
  const { matchSummary, historicalData, playerInfo } = data;
  const { teamA, teamB } = matchSummary;
  const borderColors = [
    "#B8E986",
    "#D155FF",
    "#BECCFA",
    "#DBA54B",
    "#00FF93",
    "#8C3344",
    "#FF8335",
    "#7878B5",
    "#0ECD2C",
    "#32B2FA",
  ];

  const allPlayers = [...teamA, ...teamB];
  const me = allPlayers.find(
    (player) => player.riotUserName === playerInfo.riotUserName && player.riotTagLine === playerInfo.riotTagLine,
  );
  const metricSets = useMemo(() => {
    const list = historicalData.metricsData;
    const data: { [key: string]: { playerInfo: TeamData; metrics: Array<(typeof list)[number]> } } = Object.fromEntries(
      allPlayers.map((player) => [getMetricId(player.riotUserName, player.riotTagLine), { playerInfo: player, metrics: [] }]),
    );
    for (let metric of list) {
      data[getMetricId(metric.riotUserName, metric.riotTagLine)].metrics.push(metric);
    }
    return data;
  }, [data]);

  const [selectedPlayers, setSelectedPlayers] = useState<Set<string>>(
    me ? new Set([getMetricId(me.riotUserName, me.riotTagLine)]) : new Set(),
  );

  type Category = "totalGold" | "totalDamageDoneToChampions" | "xp" | "cs";
  const [toggledCategory, setToggledCategory] = useState<Category>("totalGold");

  const togglePlayer = (player: TeamData) => {
    setSelectedPlayers((prevSelectedPlayers) => {
      const newSet = new Set(prevSelectedPlayers);
      const id = getMetricId(player.riotUserName, player.riotTagLine);
      newSet.has(id) ? newSet.delete(id) : newSet.add(id);
      return newSet;
    });
  };

  const series = useMemo(() => {
    return [...selectedPlayers].map((player) => {
      const lineColor = borderColors[metricSets[player].metrics[0].pid - 1];
      const championId = metricSets[player].playerInfo.championId;

      return metricSets[player].metrics.map((metric) => {
        const y = toggledCategory === "cs" ? metric["cs"] + metric["jungleCs"] : metric[toggledCategory];
        return {
          x: metric.timestamp,
          y,
          color: lineColor,
          championId,
        };
      });
    });
  }, [data, selectedPlayers, toggledCategory]);

  const tabs: Array<[Category, string]> = [
    ["totalGold", "Gold"],
    ["totalDamageDoneToChampions", "Damage"],
    ["xp", "Exp"],
    ["cs", "CS"],
  ];

  return (
    <div className="w-full h-full bg-purple-100">
      <div className="flex items-center justify-center gap-[12px] h-[52px] border-purple-200 border-b-[1px]">
        {tabs.map(([category, label]) => {
          const isActive = toggledCategory === category;
          return (
            <div
              key={category}
              className={classNames(
                "flex items-center justify-center w-[100px] h-[34px] text-[12px] text-lavender-50 hover:text-white border-b-[2px] cursor-pointer",
                {
                  "border-transparent font-medium": !isActive,
                  "border-accent-blue-400 !text-white font-bold": isActive,
                },
              )}
              onClick={() => setToggledCategory(category)}
            >
              {label}
            </div>
          );
        })}
      </div>
      <div className="bg-purple-400">
        <div className="flex justify-between px-[12px] pt-[12px]">
          {[teamA, teamB].map((team, teamIndex) => {
            return (
              <div key={teamIndex} className="flex gap-[6px]">
                {team.map((summoner, index) => {
                  const playedId = getMetricId(summoner.riotUserName, summoner.riotTagLine);
                  const isActive = selectedPlayers.has(getMetricId(summoner.riotUserName, summoner.riotTagLine));
                  const lineColor = borderColors[metricSets[playedId].metrics[0].pid - 1];

                  return (
                    <div
                      key={index}
                      className={classNames(
                        "flex-none flex items-center justify-center w-[36px] h-[36px] rounded-[4px] overflow-hidden cursor-pointer",
                        {
                          "opacity-40": !isActive,
                        },
                      )}
                      style={{ border: isActive ? `1px solid ${lineColor}` : "" }}
                      onClick={() => togglePlayer(summoner)}
                    >
                      <div
                        className={classNames(
                          "flex-none flex items-center justify-center w-[30px] h-[30px] rounded-[2px] overflow-hidden",
                        )}
                      >
                        {getChampionImgFromSprite(summoner.championId, { size: 34 })}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <Graph series={series} />
      </div>
    </div>
  );
}
