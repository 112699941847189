import { RegionS } from "@ugg/shared/utils/region-helpers";

export function getAllstarURI() {
  const devURI = "https://d.u.gg/allstar-api";
  const prodURI = "https://u.gg/allstar-api";

  // App
  if (process.env.PLATFORM === "app") {
    return process.env.APP_BUILD_TYPE !== "build_prod_win" ? devURI : prodURI;
  }

  // Site
  return process.env.BUILD_TYPE !== "prod" ? devURI : prodURI;
}

export function skipAllstar(region: string) {
  // return false; // Allow all regions;

  if (!region) {
    return true;
  }

  const whitelist = [RegionS.NA, RegionS.EUW, RegionS.EUN];

  return !whitelist.includes(region as RegionS);
}

export function getAllstarClipOpenLog(
  location: string,
  platform: "app" | "site",
  user: { riotUserName: string; riotTagLine: string; region: string },
) {
  return {
    message: "Allstar clip open",
    context: {
      location,
      platform,
      meta: { ...user },
    },
  };
}
