import { ALL_ROLE_OPTIONS } from "./common";
import { QueueTypeS, QueueTypeN } from "@ugg/shared/utils/queue-type-helpers";

export const CHAMPION_STATS_QUEUE_TYPE_ID_OPTIONS = [
  { label: "All Ranked", value: "all_ranked" },
  { label: "Ranked Solo", value: QueueTypeS.RANKED_SOLO },
  { label: "Ranked Flex", value: QueueTypeS.RANKED_FLEX },
  { label: "ARAM", value: QueueTypeS.ARAM, reset: ["role"] },
  { label: "Arena", value: QueueTypeS.ARENA, reset: ["role"] },
  { label: "Normal Blind", value: QueueTypeS.NORMAL_BLIND },
  { label: "Normal Draft", value: QueueTypeS.NORMAL_DRAFT },
  { label: "Quickplay", value: QueueTypeS.QUICKPLAY },
];

export const QUEUE_TYPE_RANKED = ["all_ranked", QueueTypeS.RANKED_SOLO, QueueTypeS.RANKED_FLEX];

export const QUEUE_TYPE_ID_MAP = {
  all_ranked: [QueueTypeN.RANKED_SOLO, QueueTypeN.RANKED_FLEX],
  [QueueTypeS.RANKED_SOLO]: [QueueTypeN.RANKED_SOLO],
  [QueueTypeS.RANKED_FLEX]: [QueueTypeN.RANKED_FLEX],
  [QueueTypeS.ARAM]: [QueueTypeN.ARAM],
  [QueueTypeS.ARENA]: [QueueTypeN.ARENA],
  [QueueTypeS.NORMAL_BLIND]: [QueueTypeN.NORMAL_BLIND],
  [QueueTypeS.NORMAL_DRAFT]: [QueueTypeN.NORMAL_DRAFT],
  [QueueTypeS.CLASH]: [QueueTypeN.CLASH],
  [QueueTypeS.QUICKPLAY]: [QueueTypeN.QUICKPLAY],
};

export const CHAMPION_STATS_SEASON_OPTIONS = [
  { value: "24", label: "Season 14-3" },
  { value: "23", label: "Season 14-2", reset: ["role"] },
  { value: "22", label: "Season 14-1", reset: ["role"] },
  { value: "21", label: "Season 13-2", reset: ["role"] },
  { value: "20", label: "Season 13-1", reset: ["role"] },
  { value: "18", label: "Season 12", reset: ["role"] },
  { value: "16", label: "Season 11", reset: ["role"] },
  { value: "14", label: "Season 10", reset: ["role"] },
  { value: "9", label: "Season 9", reset: ["role"] },
  { value: "8", label: "Season 8", reset: ["role"] },
  { value: "7", label: "Season 7", reset: ["role"] },
  { value: "6", label: "Season 6", reset: ["role"] },
  { value: "5", label: "Season 5", reset: ["role"] },
  { value: "4", label: "Season 4", reset: ["role"] },
  { value: "3", label: "Season 3", reset: ["role"] },
  { value: "2", label: "Season 2", reset: ["role"] },
  { value: "1", label: "Season 1", reset: ["role"] },
];

export const CHAMPION_STATS_OPTIONS = {
  queueType: CHAMPION_STATS_QUEUE_TYPE_ID_OPTIONS,
  role: ALL_ROLE_OPTIONS,
  season: CHAMPION_STATS_SEASON_OPTIONS,
};

export const CHAMPION_STATS_DEFAULT = {
  queueType: CHAMPION_STATS_QUEUE_TYPE_ID_OPTIONS[0].value,
  role: ALL_ROLE_OPTIONS[0].value,
  season: CHAMPION_STATS_SEASON_OPTIONS[0].value,
};
