import classNames from "classnames";
import { Link } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { SummonerSpell } from "@outplayed/tooltips";
import { numberWithCommas, round } from "@ugg/shared/utils/math";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { LiveGameData, LiveGamePlayer } from "@ugg/shared/api/requests/summoner-profiles/live-game";
import { ReactComponent as CircleCross } from "@ugg/shared/assets/svg/circle-cross.svg";

interface ArenaLiveGameTableProps {
  data: LiveGameData["getLiveGame"];
  riotUserName: string;
  riotTagLine: string;
  regionId: string;
}

const arenaGridClassName = `
  grid
  grid-cols-[5fr_minmax(60px,1fr)_minmax(60px,1fr)_minmax(60px,1fr)_minmax(60px,1fr)]
  max-xs:grid-cols-[6fr_minmax(60px,1fr)_minmax(60px,1fr)_minmax(60px,1fr)]
  max-xxs:grid-cols-[8fr_minmax(60px,1fr)_minmax(60px,1fr)]
`;

export function ArenaLiveGameTable(props: ArenaLiveGameTableProps) {
  const { data, riotUserName, riotTagLine, regionId } = props;
  const { teamA = [], teamB = [] } = data || {};
  const allPlayers = [...teamA, ...teamB];

  return (
    <div className="flex flex-col">
      <ArenaTeamHeader />
      <div className="[&>*:nth-child(odd)]:bg-[#1D1D3E] [&>*:nth-child(even)]:bg-purple-400">
        {allPlayers.map((player, index2) => {
          const isCurrentSummoner = player.riotUserName === riotUserName && player.riotTagLine === riotTagLine;
          return <ArenaPlayerRow key={index2} data={player} regionId={regionId} isCurrentSummoner={isCurrentSummoner} />;
        })}
      </div>
    </div>
  );
}

function ArenaTeamHeader() {
  return (
    <div
      className={classNames(
        arenaGridClassName,
        "pl-[18px] pr-[12px] py-[18px] max-xs:pl-[12px] max-xs:py-[12px] font-medium text-lavender-50 text-[12px] leading-[normal] bg-purple-400",
      )}
    >
      <div className="font-bold text-white">Summoner</div>
      <div className="m-auto">1st Place</div>
      <div className="m-auto max-xs:hidden">Top 2</div>
      <div className="m-auto font-bold text-white">Top 4</div>
      <div className="m-auto max-xxs:hidden">Matches</div>
    </div>
  );
}

interface ArenaPlayerRowProps {
  data: LiveGamePlayer;
  isCurrentSummoner: boolean;
  regionId: string;
}

function ArenaPlayerRow(props: ArenaPlayerRowProps) {
  const { getChampionImg } = getRiotAssetsContext();
  const { data, isCurrentSummoner, regionId } = props;
  const { championId, banId, summonerSpellA, summonerSpellB, riotUserName, riotTagLine, arenaPlacements } = data;
  const totalMatches = Object.values(arenaPlacements).reduce((acc, curr) => (acc += typeof curr === "number" ? curr : 0), 0);
  const top1Rate = round((arenaPlacements["_1"] / totalMatches) * 100, 1) || 0;
  const top2Rate = round(((arenaPlacements["_1"] + arenaPlacements["_2"]) / totalMatches) * 100, 1) || 0;
  const top4Rate =
    round(
      ((arenaPlacements["_1"] + arenaPlacements["_2"] + arenaPlacements["_3"] + arenaPlacements["_4"]) / totalMatches) * 100,
      1,
    ) || 0;

  return (
    <div
      className={classNames(arenaGridClassName, "pl-[18px] pr-[12px] py-[13px] max-xs:pl-[12px] max-xs:py-[8px]", {
        "!bg-[linear-gradient(0deg,_rgba(255,_155,_0,_0.20)_0%,_rgba(255,_155,_0,_0.20)_100%,_#191937)]": isCurrentSummoner,
      })}
    >
      <div className="flex items-center overflow-hidden">
        <div className="relative">
          <div className="relative flex items-center justify-center w-[30px] h-[30px] rounded-[3px] bg-purple-400 overflow-hidden">
            <img className="absolute w-full scale-[1.1]" src={getChampionImg(championId)} />
          </div>
        </div>
        <div className="flex flex-col gap-[2px] flex-none ml-[6px]">
          <SummonerSpell className="w-[14px] h-[14px] rounded-[1px]" spellId={summonerSpellA} />
          <SummonerSpell className="w-[14px] h-[14px] rounded-[1px]" spellId={summonerSpellB} />
        </div>
        <Link
          to={getProfileOverviewUrl(regionId, riotUserName, riotTagLine)}
          className={classNames("ml-[12px] truncate font-semibold text-[14px] max-xs:text-[12px] text-white hover:underline", {
            "!text-accent-orange-700": isCurrentSummoner,
          })}
        >
          {riotUserName} #{riotTagLine}
        </Link>
      </div>
      <div className="m-auto text-[12px] font-medium text-lavender-50">{top1Rate}%</div>
      <div className="m-auto text-[12px] font-medium text-lavender-50 max-xs:hidden">{top2Rate}%</div>
      <div className="m-auto text-[12px] font-bold text-white">{top4Rate}%</div>
      <div className="m-auto text-[12px] font-medium text-lavender-50 max-xxs:hidden">{numberWithCommas(totalMatches)}</div>
    </div>
  );
}
