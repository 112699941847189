import { useMemo } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { getRiotAssetsContext, getS13RankUrl } from "@outplayed/riot-assets";
import { MediaQuery } from "@outplayed/responsive";
import { Rune, SummonerSpell } from "@outplayed/tooltips";
import { selectRank, getProBuildRankName, getTierColor } from "@ugg/shared/utils/rank-helpers";
import ItemsList from "@ugg/shared/components/common/ItemsList";
import { thousandCondense } from "@ugg/shared/utils/math";
import { normalizePatch } from "@ugg/shared/utils/patch-helpers";
import { calculateKDA, getKdaColor } from "@ugg/shared/utils/kda";
import { hasPerfScore } from "@ugg/shared/utils/performance-score-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { RankType } from "@ugg/shared/utils/rank-helpers";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { ReactComponent as MedalIcon } from "@ugg/shared/assets/svg/medal-icon.svg";

import { SummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";

export type PostGamePlayerData = SummonerMatch["historicalData"]["postGameData"][number] & {
  carryScore?: number | null;
  hasHighestCarry?: boolean;
};

interface PostGameTeamSectionProps {
  data: SummonerMatch;
  team: PostGamePlayerData[];
  seasonId: number;
  maxDamage: number;
}

export default function PostGameTeamSection(props: PostGameTeamSectionProps) {
  const { seasonId, maxDamage, data, team } = props;
  const { winningTeam, allPlayerRanks, matchSummary, historicalData } = data;
  const { matchDuration, matchId, regionId, queueType, version } = matchSummary;

  const patch = normalizePatch(version);
  const isWin = team[0].teamId === winningTeam;
  const teamName = team[0].teamId === 100 ? "Blue" : "Red";
  const showPerfScore = hasPerfScore(queueType);

  return (
    <div className="expanded-match-card-team-combined">
      <SummonerRowHeader teamName={teamName} win={isWin} showPerfScore={showPerfScore} />
      <div className={`expanded-match-card-team ${teamName.toLowerCase()}`}>
        {team.map((data, index) => {
          const defaultQueueType = QueueTypeS.RANKED_SOLO;
          const possibleQueueTypes: string[] = [QueueTypeS.RANKED_SOLO, QueueTypeS.RANKED_FLEX];
          const defaultRank = { rank: "i", tier: "unranked" } as const;
          let rankIndex = -1;
          allPlayerRanks.forEach((rank, ind) => {
            if (rank.riotUserName === data.riotUserName && rank.riotTagLine === data.riotTagLine) {
              rankIndex = ind;
            }
          });
          let currentRank: RankType | { rank: "i"; tier: "unranked" } | null = defaultRank;
          if (rankIndex !== -1) {
            if (!possibleQueueTypes.includes(queueType)) {
              currentRank = selectRank(defaultQueueType, seasonId, allPlayerRanks[rankIndex].rankScores);
            } else {
              currentRank = selectRank(queueType, seasonId, allPlayerRanks[rankIndex].rankScores);
            }
          }
          return (
            <SummonerRowContainer
              key={index}
              postGameData={data}
              showPerfScore={showPerfScore}
              rank={currentRank || defaultRank}
              matchDuration={matchDuration}
              matchId={matchId}
              maxDamage={maxDamage}
              regionId={regionId}
              isMe={data.riotUserName === matchSummary.riotUserName && data.riotTagLine === matchSummary.riotTagLine}
              win={isWin}
              patch={patch}
            />
          );
        })}
      </div>
    </div>
  );
}

interface SummonerRowContainerProps {
  className?: string;
  postGameData: PostGamePlayerData;
  showPerfScore: boolean;
  rank: RankType | Pick<RankType, "tier" | "rank">;
  matchDuration: number;
  matchId: number;
  maxDamage: number;
  regionId: string;
  isMe: boolean;
  win: boolean;
  patch: string;
}

interface SummonerRowOtherProps {
  rowProps: {
    damagePerc: number;
    rankImgSrc: string;
    kda: string | number;
    kdaColor: string;
    winClass: string;
    itemList: number[];
    profileOwnerString: string;
    profileUrl: string;
    winString: string;
  };
}

const SummonerRowContainer = (props: SummonerRowContainerProps) => {
  const { postGameData, maxDamage, regionId, isMe, win, rank } = props;
  const { riotUserName, riotTagLine, items, kills, assists, deaths, damage } = postGameData;

  const itemList: number[] = useMemo(() => {
    const list = Array(6).fill(0);
    items.forEach((e, i) => {
      if (e !== 0 && i < items.length - 1) {
        list[i] = e;
      }
    });
    list.push(items[items.length - 1]);
    return list;
  }, [items]);

  const kda = calculateKDA(kills || 0, deaths || 0, assists || 0);

  const rowProps: SummonerRowOtherProps["rowProps"] = {
    damagePerc: damage / maxDamage,
    rankImgSrc: getS13RankUrl(rank.tier, true),
    kda,
    kdaColor: getKdaColor(kda) || "",
    winClass: win ? "" : "is-loss",
    itemList,
    profileOwnerString: isMe ? "is-profile-owner" : "",
    profileUrl: getProfileOverviewUrl(regionId, riotUserName, riotTagLine),
    winString: isMe ? (win ? "is-profile-owner_win" : "is-profile-owner_loss") : "",
  };

  return (
    <>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_MEDIUM">
        <MobileSummonerRow {...props} rowProps={rowProps} />
      </MediaQuery>
      <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
        <DesktopSummonerRow {...props} rowProps={rowProps} />
      </MediaQuery>
    </>
  );
};

const MobileSummonerRow = (props: SummonerRowContainerProps & SummonerRowOtherProps) => {
  const { getChampionImgFromSprite } = getRiotAssetsContext();
  const { className, rowProps, postGameData, patch, matchDuration, rank, win } = props;
  const { itemList } = rowProps;

  const summonerRowClassNames = classnames(
    "summoner-row",
    "summoner-row_mobile",
    rowProps.winString,
    rowProps.winClass,
    className,
  );

  const totalCS = postGameData.cs + postGameData.jungleCs;
  const csPerMinute = (Math.round((totalCS / (matchDuration / 60)) * 10) / 10).toFixed(1);

  return (
    <div className={summonerRowClassNames}>
      <div className="col-1">
        <div className="champion-container">
          <div className="champion-face">{getChampionImgFromSprite(postGameData.championId, { size: 36 })}</div>
          <div className="champion-level">{postGameData.level}</div>
        </div>
        <div className="summoner-spells">
          <div className="summoner-spell">
            {postGameData.summonerSpells[0] > 0 && <SummonerSpell spellId={postGameData.summonerSpells[0]} />}
          </div>
          <div className="summoner-spell">
            {postGameData.summonerSpells[1] > 0 && <SummonerSpell spellId={postGameData.summonerSpells[1]} />}
          </div>
        </div>
        <div className="runes">
          <div className="rune">
            <Rune runeId={postGameData.keystone} />
          </div>
          <div className="rune">
            <Rune runeId={postGameData.subStyle} />
          </div>
        </div>
        <div>
          <div className="player">
            <div className="player-info">
              {rank.tier !== "unranked" && (
                <div className="player-rank" style={{ color: getTierColor(rank.tier.toLowerCase()) }}>
                  {getProBuildRankName(rank.tier, rank.rank, true)}
                </div>
              )}
              <Link to={rowProps.profileUrl} className={classnames("summoner-name")}>
                {postGameData.riotUserName}
              </Link>
            </div>
            <div className="kda">
              <div className="counts">
                {postGameData.kills} <span className="slash">/</span> <span className="deaths">{postGameData.deaths}</span>{" "}
                <span className="slash">/</span> {postGameData.assists}
              </div>
              <span>
                <span className="ratio" style={{ color: rowProps.kdaColor }}>
                  {rowProps.kda}
                </span>{" "}
                <span className="kda-constant">KDA</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className="items-list">
          <div className="horizontal-items">
            <ItemsList items={itemList} winConditionClass={rowProps.winClass} patch={patch} dim={16} />
          </div>
        </div>
        <div className="other-stats">
          <div>
            <span className="cs">
              {totalCS} ({csPerMinute})
            </span>
          </div>
          <div className="damage-bar">
            <div
              style={{ width: `${rowProps.damagePerc * 100}%` }}
              className={classnames("damage-percent", {
                "is-loss": !win,
              })}
            />
            <div className="damage-text">{thousandCondense(postGameData.damage)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopSummonerRow = (props: SummonerRowContainerProps & SummonerRowOtherProps) => {
  const { getChampionImgFromSprite } = getRiotAssetsContext();
  const { className, rowProps, postGameData, showPerfScore, patch, rank } = props;
  const { itemList } = rowProps;

  const summonerRowClassNames = classnames(
    "summoner-row",
    "summoner-row_desktop",
    !showPerfScore && "summoner-row_hide-perf",
    rowProps.winString,
    rowProps.winClass,
    className,
  );

  return (
    <div className={summonerRowClassNames}>
      <div className="player">
        <div className="champion-container">
          <div className="champion-face">{getChampionImgFromSprite(postGameData.championId, { size: 36 })}</div>
          <div className="champion-level">{postGameData.level}</div>
        </div>
        <div className="summoner-spells">
          <div className="summoner-spell">
            {postGameData.summonerSpells[0] > 0 && <SummonerSpell spellId={postGameData.summonerSpells[0]} />}
          </div>
          <div className="summoner-spell">
            {postGameData.summonerSpells[1] > 0 && <SummonerSpell spellId={postGameData.summonerSpells[1]} />}
          </div>
        </div>
        <div className="runes">
          <div className="rune">
            <Rune runeId={postGameData.keystone} patch={patch} />
          </div>
          <div className="rune">
            <Rune runeId={postGameData.subStyle} patch={patch} />
          </div>
        </div>
        <div className="player-name-and-rank">
          <Link title={postGameData.riotUserName} className="summoner-name" to={rowProps.profileUrl}>
            {postGameData.riotUserName}
          </Link>
          <div className="rank-section">
            <img className="rank-img" src={rowProps.rankImgSrc} />
            <div className="rank-text">{getProBuildRankName(rank.tier, rank.rank, true)}</div>
          </div>
        </div>
      </div>
      {showPerfScore && (
        <div className={classnames("carry-score", { "carry-score_highest": postGameData.hasHighestCarry })}>
          {postGameData.carryScore || "-"}
          {postGameData.hasHighestCarry && <MedalIcon className="medal-icon" />}
        </div>
      )}
      <div className="kda">
        <div className="counts">
          {postGameData.kills} <span className="slash">/</span> <span className="deaths">{postGameData.deaths}</span>{" "}
          <span className="slash">/</span> {postGameData.assists}
        </div>
        <div className="label">
          <span className="ratio" style={{ color: rowProps.kdaColor }}>
            {rowProps.kda}
          </span>
          &nbsp;KDA
        </div>
      </div>
      <div className="damage">
        <div className="amount">{postGameData.damage.toLocaleString()}</div>
        <div className="damage-bar">
          <div className="top-bar" style={{ width: `${rowProps.damagePerc * 100}%` }} />
          <div className="grey-bar" style={{ width: `${(1 - rowProps.damagePerc) * 100}%` }} />
        </div>
      </div>
      <div>{thousandCondense(postGameData.gold)}</div>
      <div className="cs">{postGameData.cs + postGameData.jungleCs}</div>
      <div className="wards">{postGameData.wardsPlaced}</div>
      <div className="items-list">
        <div className="grid-items">
          <div className="main-items">
            <ItemsList items={itemList.slice(0, 6)} winConditionClass={rowProps.winClass} patch={patch} dim={16} />
          </div>
          <div>
            <ItemsList items={[itemList[itemList.length - 1]]} winConditionClass={rowProps.winClass} patch={patch} dim={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface SummonerRowHeaderProps {
  teamName: string;
  win: boolean;
  showPerfScore: boolean;
}
const SummonerRowHeader = (props: SummonerRowHeaderProps) => {
  const { teamName, win, showPerfScore } = props;
  const teamTextClassName = win ? "" : "red";
  const statusText = win ? "Victory" : "Defeat";
  return (
    <div className={classnames("row-header", { "row-header_hide-perf": !showPerfScore })}>
      <div>
        <span className={`status-label ${teamTextClassName}`}>{statusText}&nbsp;</span>({teamName} Team)
      </div>
      {showPerfScore && <div>Carry</div>}
      <div>KDA</div>
      <div>Damage</div>
      <div>Gold</div>
      <div className="cs-header">CS</div>
      <div className="wards-header">Wards</div>
      <div>
        <div className="post-items-header">Items</div>
      </div>
    </div>
  );
};
