import { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import "moment-duration-format";
import { ClipsModal } from "@ugg/shared/components/SummonerProfiles/ClipsModal";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";
import { useGetUserClips } from "@ugg/shared/api/requests/allstar/get-clips";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { ReactComponent as AllstarLogo } from "@ugg/shared/assets/svg/allstar-logo.svg";
import { ReactComponent as ChainIcon } from "@ugg/shared/assets/svg/chain.svg";
import { ReactComponent as PlayIcon } from "@ugg/shared/assets/svg/play.svg";

interface SummonerProfileHighlightsProps {
  className?: string;
  onClipOpen?: (riotUserName: string, riotTagLine: string, region: string) => void;
}

export function SummonerProfileHighlights(props: SummonerProfileHighlightsProps) {
  const { className, onClipOpen } = props;
  const { riotUserName, riotTagLine, region } = useSummonerProfileContext();

  const { data, loading, error } = useGetUserClips(`${riotUserName}#${riotTagLine}`);
  const { clips } = data?.data || {};
  const hasClips = clips && clips.length > 0;

  let content = null;
  if (loading) {
    content = null;
  } else if (!hasClips) {
    content = (
      <div className="flex-1 flex flex-col items-center justify-center">
        <div className="text-[14px] text-lavender-50">Your match highlights are being generated.</div>
        <div className="text-[14px] text-lavender-50">Sit tight or check back later!</div>
      </div>
    );
  } else {
    content = (
      <div className="grid gap-[18px] grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
        {clips.map((clip) => (
          <Clip
            key={clip._id}
            title={clip.clipTitle}
            timestamp={new Date(clip.createdDate).getTime()}
            thumbnail={clip.clipImageThumbURL}
            src={clip.clipUrl}
            onOpen={() => onClipOpen?.(riotUserName, riotTagLine, region)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={classNames("flex flex-col w-full p-[18px] bg-purple-400 rounded-[3px]", {}, className)}>
      <div className="flex items-center mb-[18px] max-sm:mb-[12px]">
        <HeaderBullet>Your Highlights</HeaderBullet>
        <div className="flex items-end ml-auto whitespace-pre">
          <span className="leading-none text-[12px] font-medium text-lavender-50">Powered by</span>
          <span> </span>
          <AllstarLogo className="flex-none w-[70px]" />
        </div>
      </div>
      {content}
    </div>
  );
}

function Clip({
  title,
  timestamp,
  thumbnail,
  src,
  onOpen,
}: {
  title: string;
  timestamp: number;
  thumbnail: string;
  src: string;
  onOpen?: () => void;
}) {
  const [modal, setModal] = useState(false);

  return (
    <div>
      <div className="relative w-full pt-[56.25%] group/thumbnail hover:cursor-pointer" onClick={() => setModal(true)}>
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center rounded-[3px] overflow-hidden">
          <img src={thumbnail} />
          <div className="absolute z-1 w-full h-full bg-[rgba(0,0,0,0.5)]"></div>
          <PlayIcon className="absolute z-2 flex-none w-[36px] h-[36px] group-hover/thumbnail:opacity-50" />
        </div>
      </div>
      <div className="mt-[12px] text-[12px] text-white font-semibold truncate">{title}</div>
      <div className="flex items-center mt-[7px]">
        <ChainIcon className="flex-none w-[12px] h-[12px] p-[1px] mr-[6px]" />
        <span className="text-[12px] text-lavender-50">{moment(timestamp).locale("clip").fromNow()}</span>
      </div>
      {modal && (
        <ClipsModal
          className={classNames({
            "top-[var(--app-header-height)]": process.env.PLATFORM === "app",
          })}
          title={title}
          timestamp={timestamp}
          src={src}
          onOpen={onOpen}
          onClose={() => setModal(false)}
        />
      )}
    </div>
  );
}
