import { useLocation } from "react-router-dom";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { pathToRegexp } from "path-to-regexp";

export enum SUMMONER_PROFILE_PAGES {
  OVERVIEW = "overview",
  CHAMPION_STATS = "champion-stats",
  LIVE_GAME = "live-game",
  MATCH = "match",
}

const urlPages: Record<SUMMONER_PROFILE_PAGES, string | string[]> = {
  [SUMMONER_PROFILE_PAGES.OVERVIEW]: AppRoutes.PROFILE_OVERVIEW,
  [SUMMONER_PROFILE_PAGES.CHAMPION_STATS]: AppRoutes.PROFILE_CHAMPION_STATS,
  [SUMMONER_PROFILE_PAGES.LIVE_GAME]: AppRoutes.PROFILE_LIVE_GAME,
  [SUMMONER_PROFILE_PAGES.MATCH]: AppRoutes.PROFILE_MATCH_PAGE,
};

export function useSummonerProfilePage() {
  const location = useLocation();
  const { pathname } = location;

  const foundPage = Object.entries(urlPages).find(([page, pagePath]) => {
    const execPaths = Array.isArray(pagePath) ? pagePath : [pagePath];
    for (const path of execPaths) {
      if (pathToRegexp(path).exec(pathname)) {
        return page as keyof typeof urlPages;
      }
    }
  });

  return (foundPage && foundPage[0]) as keyof typeof urlPages;
}

// switch(page) {
//   case "pro-build":
//   case "probuilds":
//     return "pro-build";
//   case "counter":
//   case "counters":
//     return "counters";
//   default:
//     return page;
// }
