import { useState } from "react";
import { useGlobal } from "reactn";
import { useSummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";
import { useBlastedPatches } from "@ugg/shared/api/requests/patches";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { hasPerfScore } from "@ugg/shared/utils/performance-score-helpers";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";
import { getProfileMatchLoaderUrl } from "@ugg/shared/routes/app-routes";
import { ExpandedMatchCardNav } from "./ExpandedMatchCardNav";
import BuildSection from "./BuildSection";
import PostGameContainer from "./PostGameContainer";
import ScoringSection from "./ScoringSection";
import Performance from "./Performance";
import { Timeline } from "./Timeline";
import { Metrics } from "./Metrics";

interface ExpandedMatchCardProps {
  matchId: number;
  version: string;
  regionId: string;
  riotUserName: string;
  riotTagLine: string;
  hidePerfScore: boolean;
  winConditionClass: string;
}

export default function ExpandedMatchCard(props: ExpandedMatchCardProps) {
  const { matchId, version, regionId, riotUserName, riotTagLine, hidePerfScore, winConditionClass } = props;
  const [chosenTab, setChosenTab] = useState("post-game");
  const { data: patches, loading: loadingPatches } = useBlastedPatches();
  const { data, loading, error } = useSummonerMatch(regionId, riotUserName, riotTagLine, version, matchId.toString());
  const [windowBreakpoint] = useGlobal("responsive");
  const isMobile = ["MOBILE_MEDIUM", "MOBILE_SMALL"].includes(windowBreakpoint);

  if (!data || loading || error || loadingPatches) {
    return (
      <div className="expanded-match-card-container expanded-match-card-container_loading">
        <div className="loader">
          <div className="spinthatshit-loader">
            <div className="spinner" />
          </div>
        </div>
      </div>
    );
  }

  // const shouldDisplayScoreSection = hasPerfScore(data.match.matchSummary.queueType);
  const fullMatchURL = getProfileMatchLoaderUrl(regionId, riotUserName, riotTagLine, version, matchId);

  const { queueType } = data.match.matchSummary;
  const currentPatch = patches.find((patch) => patch.id === version);
  const seasonId = (currentPatch && parseInt(currentPatch.season)) || INTERNAL_CURRENT_SEASON;

  let tabsArray = [
    {
      id: "post-game",
      title: "Post Game",
      active: chosenTab === "post-game",
      onClick: (id: string) => setChosenTab(id),
      component: <PostGameContainer seasonId={seasonId} data={data?.match} hidePerfScore={hidePerfScore} />,
    },
    {
      id: "performance",
      title: "Performance",
      active: chosenTab === "performance",
      onClick: (id: string) => setChosenTab(id),
      component: <Performance data={data.match} />,
    },
    {
      id: "item-buid",
      title: "Item Build",
      active: chosenTab === "item-buid",
      onClick: (id: string) => setChosenTab(id),
      component: <BuildSection data={data.match} winConditionClass={winConditionClass} />,
    },
  ];
  if (!isMobile) {
    tabsArray = tabsArray.concat([
      ...(hideTimeline(queueType)
        ? []
        : [
            {
              id: "timeline",
              title: "Timeline",
              active: chosenTab === "timeline",
              onClick: (id: string) => setChosenTab(id),
              component: <Timeline data={data.match} />,
            },
          ]),
      {
        id: "metrics",
        title: "Metrics",
        active: chosenTab === "metrics",
        onClick: (id: string) => setChosenTab(id),
        component: <Metrics data={data.match} />,
      },
    ]);
    // tabsArray.push({
    //   id: "scoring",
    //   title: "Scoring",
    //   active: chosenTab === "scoring",
    //   onClick: (id: string) => setChosenTab(id),
    //   component: (
    //     <ScoringSection data={data.match} mainSummoner={{ riotUserName, riotTagLine }} winConditionClass={winConditionClass} />
    //   ),
    // });
  }

  return (
    <div className="expanded-match-card-container">
      <ExpandedMatchCardNav tabs={tabsArray} matchUrl={fullMatchURL} />
      {tabsArray.find((tab) => tab.id === chosenTab)?.component}
    </div>
  );
}

function hideTimeline(queueType: string) {
  return [
    QueueTypeS.ARAM,
    QueueTypeS.ARAM_CLASH,
    QueueTypeS.NEXUS_BLITZ,
    QueueTypeS.RANKED_TFT,
    QueueTypeS.CUSTOM_GAME,
    QueueTypeS.ARENA,
    QueueTypeS.ARENA_DUO,
    QueueTypeS.SWARM,
  ].includes(queueType as QueueTypeS);
}
