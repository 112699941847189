import { useQuery, QueryHookOptions, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { convertLeagueClientRegion } from "@ugg/shared/utils/region-helpers";
import { RankScore, RankScoreSchema } from "./profile-ranks";
import { PlayerInfo, PlayerInfoInterface } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";

export const GET_LIVE_GAME_EXISTS = gql`
  query LiveGameExists($regionId: String!, $riotUserName: String!, $riotTagLine: String!) {
    liveGameExists(regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine)
  }
`;

export function useBoolLiveGameExist(riotUserName: string, riotTagLine: string, region: string, queryOptions?: QueryHookOptions) {
  return useQuery<{ liveGameExists: boolean }>(GET_LIVE_GAME_EXISTS, {
    ...(queryOptions || {}),
    variables: {
      riotUserName,
      riotTagLine,
      regionId: convertLeagueClientRegion(region),
    },
  });
}

export const TeamPlayerSchema = `
  arenaPlacements {
    _1
    _2
    _3
    _4
    _5
    _6
    _7
    _8
  }
  banId
  championId
  championLosses
  championWins
  championStats {
    kills
    deaths
    assists
  }
  currentRole
  onRole
  partyNumber
  previousSeasonRankScore {
    ${RankScoreSchema}
  }
  currentSeasonRankScore {
    ${RankScoreSchema}
  }
  roleDatas {
    games
    roleName
    wins
  }
  summonerIconId
  riotUserName
  riotTagLine
  summonerRuneA
  summonerRuneB
  summonerRuneData
  summonerSpellA
  summonerSpellB
  threatLevel
`;

export const LIVE_GAME = gql`
  query GetLiveGame(
    $regionId: String!,
    $riotUserName: String!,
    $riotTagLine: String!
  ) {
    getLiveGame(
      regionId: $regionId,
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine
    ) {
      gameLengthSeconds
      gameType
      queueId
      teamA {
        ${TeamPlayerSchema}
      }
      teamB {
        ${TeamPlayerSchema}
      }
    }
  }
`;

export interface LiveGamePlayer {
  arenaPlacements: {
    _1: number;
    _2: number;
    _3: number;
    _4: number;
    _5: number;
    _6: number;
    _7: number;
    _8: number;
  };
  banId: number;
  championId: number;
  championLosses: number;
  championWins: number;
  championStats: {
    kills: number;
    deaths: number;
    assists: number;
  };
  currentRole: string; // graphql has Int but actually returns String
  onRole: boolean;
  partyNumber: number;
  previousSeasonRankScore: RankScore | null;
  currentSeasonRankScore: RankScore | null;
  roleDatas: Array<{
    games: number;
    roleName: string;
    wins: number;
  }>;
  summonerIconId: number;
  riotUserName: string;
  riotTagLine: string;
  summonerRuneA: number;
  summonerRuneB: number;
  summonerRuneData: number[];
  summonerSpellA: number;
  summonerSpellB: number;
  threatLevel: number;
}

export interface LiveGameData {
  getLiveGame: {
    gameLengthSeconds: number;
    gameType: QueueTypeS;
    queueId: number;
    teamA: LiveGamePlayer[];
    teamB: LiveGamePlayer[];
  };
}

interface CheckProfileExistData {
  profilePlayerInfo: PlayerInfoInterface;
}

export function useLiveGame(riotUserName: string, riotTagLine: string, regionId: string, queryOptions: QueryHookOptions = {}) {
  return useQuery<LiveGameData>(LIVE_GAME, {
    ...queryOptions,
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
    },
  });
}

//-----------------------------------------------------------------------------------------------------------------

const CHECK_PROFILE_EXIST = gql`
  query ($region: String!, $riotUserName: String!, $riotTagLine: String!) {
    profilePlayerInfo(regionId: $region, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      ${PlayerInfo}
    }
  }
`;

export function useCheckProfileExist() {
  const [checkProfile, { data, loading, error }] = useLazyQuery<CheckProfileExistData>(CHECK_PROFILE_EXIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return { checkProfile, data, loading, error };
}

//-----------------------------------------------------------------------------------------------------------------

const LIVE_GAME_EXIST = gql`
  query ($region: String!, $riotUserName: String!, $riotTagLine: String!) {
    getLiveGame(regionId: $region, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      gameType
      queueId
    }
    profilePlayerInfo(regionId: $region, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      ${PlayerInfo}
    }
  }
`;

interface LiveGameExistData {
  getLiveGame: {
    gameType: string;
    queueId: number;
  };
  profilePlayerInfo: PlayerInfoInterface;
}

export function useLazyLiveGameExist(riotUserName: string, riotTagLine: string, region: string) {
  const [fetchLiveGame, { data, loading, error }] = useLazyQuery<LiveGameExistData>(LIVE_GAME_EXIST, {
    variables: {
      riotUserName,
      riotTagLine,
      region: convertLeagueClientRegion(region),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  return { fetchLiveGame, data, loading, error };
}

export function useLiveGameExist(riotUserName: string, riotTagLine: string, region: string, queryOptions?: QueryHookOptions) {
  return useQuery<LiveGameExistData>(LIVE_GAME_EXIST, {
    ...(queryOptions || {}),
    variables: {
      riotUserName,
      riotTagLine,
      region: convertLeagueClientRegion(region),
    },
  });
}
