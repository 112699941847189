import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { replacePlusFromURLPath } from "@ugg/shared/utils/url-query-params";

export function usePerformanceScore(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  patch: string,
  matchId: string,
  queryOptions: QueryHookOptions = {},
) {
  const apiParams = {
    regionId: region || "",
    riotUserName: replacePlusFromURLPath(riotUserName) || "",
    riotTagLine: replacePlusFromURLPath(riotTagLine) || "",
    matchId: matchId || "",
    version: patch || "",
  };

  return useQuery<PerformanceScoreData>(GET_PERFORMANCE_SCORE, {
    ...queryOptions,
    variables: apiParams,
  });
}

export const PerformanceScoreSchema = `
  damageShare
  damageShareAgg
  damageShareTotal
  finalLvlDiff
  finalLvlDiffAgg
  finalLvlDiffTotal
  goldShare
  goldShareAgg
  goldShareTotal
  hardCarry
  killParticipation
  killParticipationAgg
  killParticipationTotal
  kpOverGs
  kpOverGsAgg
  kpOverGsTotal
  riotUserName
  riotTagLine
  teamplay
  visionScore
  visionScoreAgg
  visionScoreTotal
`;

export interface PerformanceScore {
  damageShare: number;
  damageShareAgg: number;
  damageShareTotal: number;
  finalLvlDiff: number;
  finalLvlDiffAgg: number;
  finalLvlDiffTotal: number;
  goldShare: number;
  goldShareAgg: number;
  goldShareTotal: number;
  hardCarry: number;
  killParticipation: number;
  killParticipationAgg: number;
  killParticipationTotal: number;
  kpOverGs: number;
  kpOverGsAgg: number;
  kpOverGsTotal: number;
  riotUserName: string;
  riotTagLine: string;
  teamplay: number;
  visionScore: number;
  visionScoreAgg: number;
  visionScoreTotal: number;
}

interface PerformanceScoreData {
  fetchPerformanceScore: PerformanceScore[];
}

export const GET_PERFORMANCE_SCORE = gql`
  query fetchPerformanceScore(
    $matchId: String!,
    $regionId: String!,
    $riotUserName: String!,
    $riotTagLine: String!,
    $version: String!
  ){
    fetchPerformanceScore(
      matchId: $matchId,
      regionId: $regionId,
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine,
      version: $version
    ){
      ${PerformanceScoreSchema}
    }
  }
`;
