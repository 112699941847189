import classnames from "classnames";
import { capitalizeFirstLetter } from "@ugg/shared/utils/string";
import { TooltipContainer } from "@outplayed/tooltips";
import { ReactComponent as Penta } from "@ugg/shared/assets/svg/multikills/penta.svg";
import { ReactComponent as Quadra } from "@ugg/shared/assets/svg/multikills/quadra.svg";
import { ReactComponent as Triple } from "@ugg/shared/assets/svg/multikills/triple.svg";
import { ReactComponent as Double } from "@ugg/shared/assets/svg/multikills/double.svg";

function getMultiKillIcon(multikill: string) {
  switch (multikill) {
    case "penta":
      return Penta;
    case "quadra":
      return Quadra;
    case "triple":
      return Triple;
    case "double":
      return Double;
    default:
      return null;
  }
}

interface MultikillIndicatorProps {
  multiKillType: string | null;
  disableText?: boolean;
  enableTooltip?: boolean;
}

export default function MultikillIndicator(props: MultikillIndicatorProps) {
  const { multiKillType, disableText, enableTooltip } = props;

  if (!multiKillType) {
    return null;
  }

  const MultiKillIcon = getMultiKillIcon(multiKillType);
  const multikillLabel = `${capitalizeFirstLetter(multiKillType)}`;

  const html = (
    <div className={classnames("multikill-indicator-container", multiKillType)}>
      {MultiKillIcon && (
        <div className={classnames("multikill-icon", multiKillType)}>
          <MultiKillIcon />
        </div>
      )}
      {!disableText && <div className="multikill-text">{multikillLabel}</div>}
    </div>
  );

  if (enableTooltip) {
    return <TooltipContainer tooltipInfo={multikillLabel}>{html}</TooltipContainer>;
  }

  return html;
}
