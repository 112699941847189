import { useMemo } from "react";
import classNames from "classnames";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getRoleSVG, RoleC, RoleN } from "@ugg/shared/utils/role-helpers";
import { ReactComponent as CheckmarkIcon } from "svg/checkmark.svg";
import { ReactComponent as XIcon } from "svg/x.svg";
import { calculateLiveGameScore, calculateELOKicker } from "@ugg/shared/utils/live-game-helpers";
import { LiveGamePlayerPlus } from "@ugg/shared/utils/live-game-helpers";

const roles = [RoleN.TOP, RoleN.JUNGLE, RoleN.MIDDLE, RoleN.BOTTOM, RoleN.SUPPORT];

interface HeadToHeadProps {
  className?: string;
  teamA: LiveGamePlayerPlus[];
  teamB: LiveGamePlayerPlus[];
}

const HeadToHead = (props: HeadToHeadProps) => {
  const { getChampionImg } = getRiotAssetsContext();
  const context = useSummonerProfileContext();
  const { realRiotUserName, realRiotTagLine } = context;
  const { className, teamA, teamB } = props;

  const matchups = useMemo(() => {
    const matchupData: {
      [key: number]: {
        playerA: LiveGamePlayerPlus | null;
        playerB: LiveGamePlayerPlus | null;
        headToHeadWinner: number;
      };
    } = {};
    roles.forEach((role) => {
      const playerA = teamA.find((player) => RoleC.convertToInt(player.currentRole, true) === role) || null;
      const playerB = teamB.find((player) => RoleC.convertToInt(player.currentRole, true) === role) || null;
      const playerAScores = calculateLiveGameScore(playerA);
      const playerBScores = calculateLiveGameScore(playerB);
      const eloKickerA = calculateELOKicker(playerAScores, playerBScores);
      const eloKickerB = calculateELOKicker(playerBScores, playerAScores);

      const head2headScoreA = playerAScores.seasonScore + playerAScores.championScore + eloKickerA;
      const head2headScoreB = playerBScores.seasonScore + playerBScores.championScore + eloKickerB;

      let headToHeadWinner = 0;
      if (head2headScoreA > head2headScoreB) {
        headToHeadWinner = 100;
      } else if (head2headScoreA < head2headScoreB) {
        headToHeadWinner = 200;
      } else if (playerA && playerB) {
        if (playerA.championWinRate > playerB.championWinRate) {
          headToHeadWinner = 100;
        } else if (playerA.championWinRate < playerB.championWinRate) {
          headToHeadWinner = 200;
        } else if (playerA.seasonWinRate > playerB.seasonWinRate) {
          headToHeadWinner = 100;
        } else if (playerA.seasonWinRate < playerB.seasonWinRate) {
          headToHeadWinner = 200;
        }
      }

      matchupData[role] = { playerA, playerB, headToHeadWinner };
    });

    return matchupData;
  }, [teamA, teamB]);

  return (
    <div
      className={classNames(
        "flex px-[18px] gap-[18px] bg-purple-200 max-md:gap-[12px] max-xs:px-[12px] max-sm:flex-col max-xxs:gap-[6px]",
        className,
      )}
    >
      {roles.map((role) => {
        const { playerA, playerB, headToHeadWinner } = matchups[role];
        return (
          <div key={role} className="flex-1 flex items-center gap-[6px] p-[6px] rounded-[2px] bg-[#1d1d3e] overflow-hidden">
            <div
              className={classNames("flex-none flex items-center self-stretch justify-center w-[18px] rounded-[1px]", {
                "bg-accent-blue-400": headToHeadWinner === 100,
                "bg-accent-orange-500": headToHeadWinner === 200,
              })}
            >
              <div className="[&_svg]:w-[10px]">{getRoleSVG(RoleC.convertToString(role), { active: true })}</div>
            </div>
            <div className="flex-1 flex flex-col justify-center overflow-hidden">
              {[playerA, playerB].map((player, index) => {
                const isWinner = player?.teamId === headToHeadWinner;
                const isViewedProfile = realRiotUserName === player?.riotUserName && realRiotTagLine === player.riotTagLine;
                return (
                  <div
                    key={index}
                    className={classNames("flex items-center p-[2px]", {
                      "pb-[5px] border-b-[1px] border-b-accent-purple-700": index === 0,
                      "pt-[4px]": index === 1,
                    })}
                  >
                    <div
                      className={classNames(
                        "flex-none mr-[6px] p-[1px] w-[18px] h-[18px] rounded-[3px] border-[1px] border-accent-purple-700 bg-purple-200",
                        {
                          "!border-accent-blue-400": player?.teamId === 100 && isWinner,
                          "!border-accent-orange-500": player?.teamId === 200 && isWinner,
                        },
                      )}
                    >
                      <div className="flex items-center justify-center w-full h-full rounded-[3px] overflow-hidden">
                        {player?.championId && (
                          <img className="w-full h-full scale-[1.1]" src={getChampionImg(player.championId)} />
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames("flex-1 pr-[6px] text-[11px] font-medium text-lavender-50 truncate", {
                        "!text-accent-orange-700": isViewedProfile,
                      })}
                    >
                      {player?.riotUserName}
                    </div>
                    <div
                      className={classNames("flex items-center justify-center w-[18px] h-[18px] rounded-[2px] ml-[6px]", {
                        "bg-accent-blue-400": player?.teamId === 100,
                        "bg-accent-orange-500": player?.teamId === 200,
                        "!bg-accent-purple-700": !isWinner,
                      })}
                    >
                      {isWinner ? (
                        <CheckmarkIcon className="w-[10px] flex-none" />
                      ) : (
                        <XIcon className="w-[8px] flex-none fill-lavender-50 opacity-50" />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeadToHead;

// const teamA = [
// {
//   currentRole: "top",
//   championWins: 0,
//   championLosses: 1,
//   currentSeasonRankScore: {
//     wins: 63,
//     losses: 77,
//     tier: "gold",
//     rank: "ii",
//     lp: 14
//   }
// },
// {
//   currentRole: "jungle",
//   championWins: 0,
//   championLosses: 1,
//   currentSeasonRankScore: {
//     wins: 0,
//     losses: 1,
//     tier: null,
//     rank: null,
//     lp: 0
//   }
// },
// {
//   currentRole: "mid",
//   championWins: 2,
//   championLosses: 0,
//   currentSeasonRankScore: {
//     wins: 0,
//     losses: 1,
//     tier: null,
//     rank: null,
//     lp: 0
//   }
// },
// {
//   currentRole: "adc",
//   championWins: 0,
//   championLosses: 1,
//   currentSeasonRankScore: {
//     wins: 9,
//     losses: 5,
//     tier: "silver",
//     rank: "ii",
//     lp: 2
//   }
// },
// {
//   currentRole: "support",
//   championWins: 0,
//   championLosses: 1,
//   currentSeasonRankScore: {
//     wins: 0,
//     losses: 1,
//     tier: null,
//     rank: null,
//     lp: 0
//   }
// },
// ]
// const teamB = [
//   {
//     currentRole: "top",
//     championWins: 0,
//     championLosses: 1,
//     currentSeasonRankScore: {
//       wins: 25,
//       losses: 27,
//       tier: "gold",
//       rank: "i",
//       lp: 26
//     }
//   },
//   {
//     currentRole: "jungle",
//     championWins: 7,
//     championLosses: 8,
//     currentSeasonRankScore: {
//       wins: 0,
//       losses: 1,
//       tier: "platinum",
//       rank: "ii",
//       lp: 30
//     }
//   },
//   {
//     currentRole: "mid",
//     championWins: 0,
//     championLosses: 1,
//     currentSeasonRankScore: {
//       wins: 0,
//       losses: 1,
//       tier: "platinum",
//       rank: "iii",
//       lp: 59
//     }
//   },
//   {
//     currentRole: "adc",
//     championWins: 65,
//     championLosses: 55,
//     currentSeasonRankScore: {
//       wins: 3,
//       losses: 10,
//       tier: "gold",
//       rank: "iii",
//       lp: 5
//     }
//   },
//   {
//     currentRole: "support",
//     championWins: 0,
//     championLosses: 1,
//     currentSeasonRankScore: {
//       wins: 0,
//       losses: 1,
//       tier: null,
//       rank: null,
//       lp: 0
//     }
//   },
// ]
