import window from "global";
import { useGlobal } from "reactn";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANG, getUserLanguage } from "../utils/locale-helpers";
import { addCookie } from "../utils/cookies";

export function useLanguage(): [string, (lang: string) => void] {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useGlobal("language");

  const _setLanguage = (_language: string) => {
    let finalLang = getUserLanguage({ language: _language }) || DEFAULT_LANG;

    if (window) {
      addCookie("ugg_lang", finalLang);
      // localStorage.setItem("ugg_lang", finalLang);
    }

    i18n && i18n.changeLanguage(finalLang);

    setLanguage(finalLang);
  };

  return [language, _setLanguage];

  // return { type: SET_LOCALE, lang: finalLang };
}
