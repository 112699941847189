import classnames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

interface SummonerTagProps {
  className?: string;
  type?: string;
  size?: string;
  tag?: string;
  meta?: any;
  championId?: number;
  hasBorder?: boolean;
  disableTooltip?: boolean;
}

export default function SummonerTag(props: SummonerTagProps) {
  const { className, type, size, tag, meta, championId, hasBorder, disableTooltip } = props;
  const { getChampionName } = getRiotAssetsContext();

  const metaParse = () => {
    if (typeof meta === "string") {
      return (meta || "").replace("%champion", getChampionName(championId));
    }
    return meta;
  };

  const tagClassNames = classnames("summoner-tag", className, {
    ...(type && { [type]: !!type }),
    ...(size && { [size]: !!size }),
    "has-border": !!hasBorder,
  });
  return (
    <TooltipContainer disableTooltip={!meta || disableTooltip} tooltipInfo={<span>{metaParse()}</span>}>
      <div className={tagClassNames}>{tag}</div>
    </TooltipContainer>
  );
}
