import { useMemo } from "react";
import { RoleC, RoleS, getRoleImg } from "@ugg/shared/utils/role-helpers";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { MatchSummary } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";

interface MatchStatsExpandedProps {
  matches: MatchSummary[];
  totalGames: number;
}

export default function MatchStatsExpanded(props: MatchStatsExpandedProps) {
  const { matches, totalGames } = props;
  const context = useSummonerProfileContext();
  const { realRiotUserName, realRiotTagLine } = context;

  const rolesPlayed = useMemo(() => {
    let roles: Record<string, number> = {
      [RoleS.TOP]: 0,
      [RoleS.JUNGLE]: 0,
      [RoleS.MIDDLE]: 0,
      [RoleS.BOTTOM]: 0,
      [RoleS.SUPPORT]: 0,
    };
    for (const match of matches) {
      for (const [idx, user] of match["teamA"].concat(match["teamB"]).entries()) {
        const role = RoleC.convertToString(user["role"]);
        if (role in roles && user["riotUserName"] === realRiotUserName && user["riotTagLine"] === realRiotTagLine) {
          roles[role] += 1;
        }
      }
    }
    return roles;
  }, [matches]);

  return (
    <div className="w-full bg-purple-400 px-[18px] py-[12px] max-sm:px-[12px]">
      <h1 className="mb-[12px] text-[14px] font-medium text-lavender-50">Preferred Roles</h1>
      <div className="flex gap-[22px] w-full">
        {Object.entries(rolesPlayed).map(([key, value]) => (
          <div key={key} className="flex items-center flex-1">
            <img src={getRoleImg(key)} className="w-[17px] h-[17px] mr-[7px]" />
            <div className="flex-1 h-[6px] rounded-[3px] bg-purple-100 ">
              <div style={{ width: `${(value / totalGames) * 100}%` }} className="h-full rounded-[3px] bg-accent-blue-400"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
