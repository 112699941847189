import { ReactComponent as MinionsLogo } from "@ugg/shared/assets/svg/arena/minion-logo.svg";
import { ReactComponent as PorosLogo } from "@ugg/shared/assets/svg/arena/poro-logo.svg";
import { ReactComponent as KrugsLogo } from "@ugg/shared/assets/svg/arena/krug-logo.svg";
import { ReactComponent as ScuttlesLogo } from "@ugg/shared/assets/svg/arena/scuttle-logo.svg";
import { ReactComponent as GrompsLogo } from "@ugg/shared/assets/svg/arena/gromp-logo.svg";
import { ReactComponent as RaptorsLogo } from "@ugg/shared/assets/svg/arena/raptor-logo.svg";
import { ReactComponent as SentinelsLogo } from "@ugg/shared/assets/svg/arena/sentinel-logo.svg";
import { ReactComponent as WolvesLogo } from "@ugg/shared/assets/svg/arena/wolf-logo.svg";

import { TeamData } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";

export const arenaTeams = [
  { name: "Poros", icon: <PorosLogo /> },
  { name: "Minions", icon: <MinionsLogo /> },
  { name: "Scuttles", icon: <ScuttlesLogo /> },
  { name: "Krugs", icon: <KrugsLogo /> },
  { name: "Raptors", icon: <RaptorsLogo /> },
  { name: "Sentinels", icon: <SentinelsLogo /> },
  { name: "Wolves", icon: <WolvesLogo /> },
  { name: "Gromps", icon: <GrompsLogo /> },
];

type ArenaTeam = {
  summonerName: string;
  riotUserName: string;
  riotTagLine: string;
  teamId: number;
};

export function mergeArenaTeams(teamA: TeamData[], teamB: TeamData[]) {
  let finalTeamPlayerRows: ArenaTeam[][] = [];

  for (let i = 1; i <= 8; i++) {
    const teamSummoners: ArenaTeam[] = [];
    let subTeamId: number = 0;
    for (const team of [teamA, teamB]) {
      for (const member of team) {
        if (member.placement === i) {
          subTeamId = member.playerSubteamId;
          teamSummoners.push({
            summonerName: member.summonerName || "",
            riotUserName: member.riotUserName || "",
            riotTagLine: member.riotTagLine || "",
            teamId: subTeamId,
          });
        }
      }
    }
    finalTeamPlayerRows.push(teamSummoners);
  }

  return finalTeamPlayerRows;
}
