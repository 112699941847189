import { Switch, Route, Redirect } from "react-router-dom";
import { SummonerProfileOverviewPage } from "components/SummonerProfiles/pages/SummonerProfileOverviewPage";
import { SummonerProfileChampionStatsPage } from "./pages/SummonerProfileChampionStatsPage";
import { SummonerProfileLiveGamePage } from "./pages/SummonerProfileLiveGamePage";
import { AppRoutes } from "@ugg/shared/routes/app-routes";
import { SummonerProfilesHighlightsCTA } from "./highlights/SummonerProfilesHighlightsCTA";

export function SummonerProfileRoutes() {
  return (
    <Switch>
      <Route path={AppRoutes.PROFILE_OVERVIEW} component={SummonerProfileOverviewPage} />
      <Route path={AppRoutes.PROFILE_CHAMPION_STATS} component={SummonerProfileChampionStatsPage} />
      <Route path={AppRoutes.PROFILE_LIVE_GAME} component={SummonerProfileLiveGamePage} />
      <Route path={AppRoutes.PROFILE_HIGHLIGHTS} component={SummonerProfilesHighlightsCTA} />
      <Redirect from={AppRoutes.PROFILE_ROOT} to={AppRoutes.PROFILE_OVERVIEW} />
    </Switch>
  );
}
