import { ChampionBan } from "@ugg/shared/components/SummonerProfiles/live-game/ChampionBan";
import { LiveGameData } from "@ugg/shared/api/requests/summoner-profiles/live-game";

interface ArenaLiveGameBanListProps {
  data: LiveGameData["getLiveGame"];
}

export function ArenaLiveGameBanList(props: ArenaLiveGameBanListProps) {
  const { teamA, teamB } = props.data;

  const bans = [...new Set([...teamA, ...teamB].map((player) => player.banId).filter((championId) => championId > 0))];

  return (
    <div className="flex items-center justify-between flex-wrap gap-[10px] px-[18px] py-[12px] max-xs:px-[12px] border-b-[1px] border-purple-500 bg-[#1D1D3E]">
      <div className="flex-1 text-[12px] font-bold text-white">Bans</div>
      <div className="flex flex-wrap gap-[12px] max-xs:gap-[8px]">
        {bans.map((championId) => (
          <ChampionBan className="max-xs:w-[24px] max-xs:h-[24px]" championId={championId} />
        ))}
      </div>
    </div>
  );
}
