import { window } from "global";
import React, { useState, useEffect, useMemo, useRef } from "react";
import classnames from "classnames";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import FilterModal from "../FilterModal";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { ReactComponent as IconX } from "@ugg/shared/assets/svg/x.svg";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

const FETCH_PLAYED_WITH = gql`
  query FetchPlayedWith($regionId: String!, $riotUserName: String!, $riotTagLine: String!) {
    fetchPlayedWith(regionId: $regionId, riotUserName: $riotUserName, riotTagLine: $riotTagLine) {
      riotIds {
        tagLine
        username
      }
    }
  }
`;

interface FetchPlayedWithData {
  fetchPlayedWith: {
    riotIds: Array<{ tagLine: string; username: string }>;
  };
}

interface SummonerProps {
  className?: string;
  value: string;
  onFilterChange: (type: string, option: { value: string }) => void;
  filterKey?: string;
  hideDropdownIndicator?: boolean;
  customButton?: React.ReactNode;
  isMobile?: boolean;
  fontSize?: number;
}

export default function Summoner(props: SummonerProps) {
  const {
    className,
    value,
    onFilterChange,
    filterKey = "duoRiotId",
    hideDropdownIndicator,
    customButton,
    isMobile,
    fontSize,
  } = props;

  const { riotUserName, riotTagLine, region } = useSummonerProfileContext();

  const [getSummoners, { loading, data }] = useLazyQuery<FetchPlayedWithData>(FETCH_PLAYED_WITH, {
    ssr: false,
    variables: {
      regionId: region,
      riotUserName,
      riotTagLine,
    },
  });

  const filterRef = useRef<HTMLDivElement>(null);
  const [inputActive, setInputActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [highlightedItem, setHighlightedItem] = useState(0);

  const { fetchPlayedWith } = data || {};
  const { riotIds } = fetchPlayedWith || {};

  const allSummoners = (riotIds || [])
    .map((playedWith) => {
      const name = `${playedWith.username} #${playedWith.tagLine}`;
      return {
        name,
        value: name,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const mobileOptions = allSummoners.map((summoner) => ({
    label: summoner.name,
    value: summoner.value,
  }));

  allSummoners.unshift({
    name: "All Summoners",
    value: "all",
  });

  mobileOptions.unshift({
    label: "All Summoners",
    value: "all",
  });

  const resetFilter = () => {
    setInputActive(false);
    setInputValue("");
    setHighlightedItem(0);
  };

  useEffect(() => {
    if (window && filterRef.current && inputActive) {
      const outsideClick = (e: MouseEvent) => {
        if (filterRef.current && e.target !== null && !filterRef.current.contains(e.target as HTMLElement)) {
          resetFilter();
        }
      };
      window.addEventListener("click", outsideClick);

      return () => window.removeEventListener("click", outsideClick);
    }
  }, [inputActive]);

  useEffect(() => {
    setHighlightedItem(0);
  }, [inputValue]);

  const onClick = (e: React.MouseEvent) => {
    !inputActive && setInputActive(true);
    getSummoners();
  };

  const onFilterSelect = (summoner: (typeof summonerList)[number] | null) => {
    if (summoner) {
      onFilterChange(filterKey, summoner);
      resetFilter();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const summonerList = useMemo(() => {
    if (!inputValue.trim()) return allSummoners;

    return allSummoners.filter((summoner) => {
      const normalizedName = summoner.name.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
      const normalizedInput = inputValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
      return normalizedName.startsWith(normalizedInput);
    });
  }, [data, inputValue]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      // enter
      onFilterSelect(summonerList[highlightedItem]);
    } else if (e.key === "ArrowUp") {
      // up
      e.preventDefault();
      setHighlightedItem(Math.abs(summonerList.length + highlightedItem - 1) % summonerList.length);
    } else if (e.key === "ArrowDown") {
      // down
      e.preventDefault();
      setHighlightedItem(Math.abs(summonerList.length + highlightedItem + 1) % summonerList.length);
    }
  };

  const duoRiotId = value;

  const FilterComponent = (
    <React.Fragment>
      {
        // Need this because clicks don't trigger on parent <div>
        isMobile && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          ></div>
        )
      }
      <div ref={filterRef} className={classnames("filter-select", "filter-select_champion", className)} style={{ fontSize }}>
        <div className="champion-control" onClick={onClick}>
          {/*<span className="vs">vs.</span>*/}
          <div className="champion-label">
            {inputActive ? (
              <input
                style={{ fontSize }}
                placeholder="Search summoner"
                value={inputValue}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus
              />
            ) : (
              <div className="champion">
                {duoRiotId ? (
                  <React.Fragment>
                    <div className="champion-name">{duoRiotId}</div>
                  </React.Fragment>
                ) : (
                  <span>All Summoners</span>
                )}
              </div>
            )}
          </div>
          {!inputActive && !hideDropdownIndicator && <TriangleArrowDown className="custom-indicator" />}
          {!isMobile && duoRiotId && !inputActive && (
            <div
              className="clear-champion"
              onClick={(e) => {
                e.stopPropagation();
                onFilterChange(filterKey, { value: "" });
              }}
            >
              <IconX className="x-icon" />
            </div>
          )}
        </div>
        {inputActive && summonerList.length > 0 && (
          <div className="champion-menu">
            {loading ? (
              <div style={{ padding: "20px" }}>
                <div className="spinthatshit-loader">
                  <div className="spinner"></div>
                </div>
              </div>
            ) : (
              summonerList.map((summoner, index) => {
                const className = classnames("champion-menu_item", {
                  highlighted: index === highlightedItem,
                });
                return (
                  <div
                    key={summoner.value}
                    className={className}
                    onMouseEnter={() => setHighlightedItem(index)}
                    onClick={() => onFilterSelect(summoner)}
                  >
                    <div className="champion-name">{summoner.name}</div>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );

  return (
    <div className="filter-select-wrapper">
      {isMobile ? (
        <FilterModal
          button={customButton || FilterComponent}
          title={"Change Summoner"}
          group={"summoner"}
          options={mobileOptions}
          curValue={{
            label: duoRiotId,
            value: duoRiotId,
          }}
          loading={loading}
          onApply={(selected) => onFilterChange(filterKey, { value: selected?.value || "" })}
          onToggleModal={() => getSummoners()}
        />
      ) : (
        FilterComponent
      )}
    </div>
  );
}
