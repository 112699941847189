import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";

export const RankScoreSchema = `
  lastUpdatedAt
  losses
  lp
  promoProgress
  queueType
  rank
  role
  seasonId
  tier
  wins
`;

export const GET_PLAYER_RANKS = gql`
  query getPlayerRanks(
    $riotUserName: String!
    $riotTagLine: String!
    $regionId: String!
    $seasonId: Int!
  ) {
    fetchProfileRanks(
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine
      regionId: $regionId,
      seasonId: $seasonId
    ) {
      rankScores {
        ${RankScoreSchema}
      }
    }
  }
`;

export interface RankScore {
  lastUpdatedAt: number;
  losses: number;
  lp: number;
  promoProgress: string;
  queueType: string;
  rank: string;
  role: string;
  seasonId: number;
  tier: string;
  wins: number;
}

interface ProfileRanksData {
  fetchProfileRanks: {
    rankScores: RankScore[];
  };
}

export function useProfileRanks(
  riotUserName: string,
  riotTagLine: string,
  regionId: string,
  seasonId?: number,
  queryOptions?: QueryHookOptions,
) {
  return useQuery<ProfileRanksData>(GET_PLAYER_RANKS, {
    ...(queryOptions || {}),
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
      seasonId: seasonId || INTERNAL_CURRENT_SEASON,
    },
  });
}
