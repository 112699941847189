import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useGlobal } from "reactn";
import moment from "moment";
import "moment-duration-format";
import Portal from "@ugg/shared/components/common/Portal";
import { ReactComponent as ChainIcon } from "@ugg/shared/assets/svg/chain.svg";

interface ClipsModalProps {
  className?: string;
  title: string;
  timestamp: number;
  src: string;
  onOpen?: () => void;
  onClose?: () => void;
}

export function ClipsModal(props: ClipsModalProps) {
  const { className, title, timestamp, src, onOpen, onClose } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const [adsCovered, setAdsCovered] = useGlobal("adsCovered");

  useEffect(() => {
    onOpen?.();
    setMounted(true);
    setAdsCovered(true);

    return () => {
      setAdsCovered(false);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      e.stopPropagation();
      if (e.currentTarget === e.target) {
        onClose?.();
      }
    };

    containerRef.current?.addEventListener("click", handleOutsideClick);

    return () => {
      containerRef.current?.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <Portal dest="modal-portal">
      <div
        ref={containerRef}
        className={classNames(
          `fixed z-[99999999999] top-0 bottom-0 left-0 right-0 
          flex items-center justify-center bg-purple-500 
          bg-opacity-50 transition-[background-color] duration-100`,
          {
            "!bg-opacity-90": mounted,
          },
          className,
        )}
        onClick={onClose}
      >
        <div
          className={classNames("w-[calc(100vw-24px)] max-w-[960px] scale-[.97] transition-[transform] duration-100", {
            "!scale-100": mounted,
          })}
        >
          <div className="relative w-full pt-[56.25%]">
            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-purple-300">
              <iframe className="w-full h-full" src={src} allow="clipboard-write" allowFullScreen />
            </div>
          </div>
          <div className="p-[18px] bg-purple-400 rounded-b-[3px]">
            <div className="text-[18px] text-white font-semibold">{title}</div>
            <div className="flex items-center mt-[12px]">
              <ChainIcon className="flex-none w-[12px] h-[12px] p-[1px] mr-[6px]" />
              <span className="text-[12px] text-lavender-50">{moment(timestamp).locale("clip").fromNow()}</span>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
