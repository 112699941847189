import classNames from "classnames";
import { ReactComponent as ChevronUp2 } from "@ugg/shared/assets/svg/chevron-up-2.svg";

interface ArrowToggleProps {
  className?: string;
  setToggle: (toggle: boolean) => void;
  isOpen: boolean;
}

export function ArrowToggle(props: ArrowToggleProps) {
  const { className, setToggle, isOpen } = props;

  const toggle = () => {
    setToggle(!isOpen);
  };

  return (
    <div
      className={classNames("flex items-center justify-center w-[20px] h-[20px] rounded-[3px] hover:cursor-pointer", className)}
      onClick={toggle}
    >
      <ChevronUp2
        className={classNames("w-[8px] [&>path]:fill-white", {
          "rotate-180": !isOpen,
        })}
      />
    </div>
  );
}
