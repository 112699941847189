import classnames from "classnames";
import { Item } from "@outplayed/tooltips";
import { ReactComponent as IconX } from "@ugg/shared/assets/svg/x.svg";

interface ItemTimelineEntry {
  itemId: number;
  timestamp: number;
  sold: boolean;
  count: number;
}

interface ItemTimelineProps {
  patch: string;
  spriteSize: number;
  items: Array<{
    itemId: number;
    timestamp: number;
    type: number;
  }>;
  disableTooltip?: boolean;
  winConditionClass?: string;
}

export default function ItemTimeline(props: ItemTimelineProps) {
  const { patch, spriteSize, items, disableTooltip, winConditionClass } = props;

  const stackDuplicateItems = (visit: ItemTimelineEntry[]) => {
    return visit.reduce<ItemTimelineEntry[]>((acc, cur) => {
      const dupeIndex = acc.findIndex((item) => cur.itemId === item.itemId);
      const newEntry = { ...cur, count: 1 };
      if (dupeIndex < 0) {
        // New item to list
        acc.push(newEntry);
      } else {
        // Update count and latest timestamp
        if (acc[dupeIndex].sold === cur.sold) {
          acc[dupeIndex].count++;
          acc[dupeIndex].timestamp = cur.timestamp;
        } else {
          acc.push(newEntry);
        }
      }

      return acc;
    }, []);
  };

  const formatItemPath = () => {
    let visit: { time: number; items: ItemTimelineEntry[] } = {
      time: 0,
      items: [],
    };
    const path: Array<typeof visit> = [];

    items.forEach((item, index) => {
      const time = Math.trunc(item.timestamp / 60000);
      const itemObj = {
        itemId: item.itemId,
        timestamp: item.timestamp,
        sold: item.type === 2,
        count: 0,
      };

      if (path.length === 0 && visit.items.length === 0) {
        visit.time = time;
        visit.items.push(itemObj);
        return;
      }

      if (visit.time === time) {
        visit.items.push(itemObj);
      } else {
        visit.items = stackDuplicateItems(visit.items);
        path.push(visit);
        visit = {
          time,
          items: [itemObj],
        };
      }

      if (index === items.length - 1) {
        visit.items = stackDuplicateItems(visit.items);
        path.push(visit);
      }
    });

    return path;
  };

  const buildPath = () => {
    const formattedItems = formatItemPath();
    return formattedItems.map((itemsPerVisit, index) => {
      const time = itemsPerVisit.time;

      let visits = itemsPerVisit.items.map((item, index2) => {
        return (
          <div key={`${index}-${index2}`}>
            <div className="item-purchased">
              <Item
                className="sprite"
                style={{ width: "100%", borderRadius: "15%" }}
                itemId={item.itemId}
                spriteSize={spriteSize}
                isSprite
                patch={patch}
                disableTooltip={disableTooltip}
              />
              {item.count > 1 && <div className="item-count">{item.count}</div>}
              {item.sold && (
                <div className="item-sold">
                  <IconX className="item-sold-img" />
                </div>
              )}
            </div>
          </div>
        );
      });

      return (
        <div key={index} className="items-purchased-wrapper">
          <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <div className={classnames("items-purchased-by-minute", winConditionClass)}>{visits}</div>
            <div className="timestamp">{`${time} min`}</div>
          </div>
          {index === formattedItems.length - 1 ? null : <div className={classnames("separator", winConditionClass)}></div>}
        </div>
      );
    });
  };

  return <div className="item-timeline">{buildPath()}</div>;
}
