import { useState, useEffect, useRef } from "react";
import moment from "moment";
import "moment-duration-format";
import classNames from "classnames";
import { QueueTypeN, getQueueTypeName } from "@ugg/shared/utils/queue-type-helpers";

export function GameInfo(props: { className?: string; time: number; queueType: QueueTypeN }) {
  const { className, time, queueType } = props;

  return (
    <span className={classNames("text-[14px] font-medium text-accent-gray-100", className)}>
      <span>{getQueueTypeName(queueType)}</span>
      <span className="text-lavender-500">{" / "}</span>
      <LiveGameTimer time={time} />
    </span>
  );
}

function LiveGameTimer({ time }: { time: number }) {
  const timerRef = useRef<NodeJS.Timer>();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let count = 0;
    timerRef.current = setInterval(() => {
      setCounter(++count);
    }, 1000);

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, []);

  return <span className="tabular-nums">{moment.duration(time + counter, "seconds").format("mm:ss", { trim: false })}</span>;
}
