import classNames from "classnames";
import { ReactComponent as TriangleArrowUp } from "@ugg/shared/assets/svg/triangle-arrow-up.svg";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

export function LPGainArrows({ className, lpAvg }: { className?: string; lpAvg: number }) {
  return (
    <span className={classNames("flex items-center whitespace-pre", className)}>
      {lpAvg > 0 && (
        <>
          <TriangleArrowUp className="mr-[3px] w-[6px] [&_path]:fill-accent-blue-400" />
          <span>{lpAvg}</span>
          <span>{" LP"}</span>
        </>
      )}
      {lpAvg < 0 && (
        <>
          <TriangleArrowDown className="mr-[3px] w-[6px] [&_path]:fill-accent-orange-500" />
          <span>{Math.abs(lpAvg)}</span>
          <span>{" LP"}</span>
        </>
      )}
      {lpAvg === 0 && (
        <>
          <span>0</span>
          <span>{" LP"}</span>
        </>
      )}
    </span>
  );
}
