import classNames from "classnames";
interface ErrorSummonerNotFoundProps {
  className?: string;
  riotUserName: string;
  riotTagLine: string;
  region: string;
}

export default function ErrorSummonerNotFound(props: ErrorSummonerNotFoundProps) {
  const { className, riotUserName, riotTagLine, region } = props;

  return (
    <div className={classNames("flex flex-col items-center w-full", className)}>
      <div className="flex flex-col items-center justify-center mb-[18px] text-center">
        <div className="text-[36px] font-bold">
          Oh no! We couldn't find summoner "{riotUserName} #{riotTagLine}" in {region.toUpperCase()}
        </div>
        <div className="mt-[10px] w-full max-w-[500px] text-[21px] text-center">
          Double check your region and spelling of your game name and tag.
        </div>
      </div>
      <img className="w-full max-w-[500px] mt-[48px]" src="https://static.bigbrain.gg/assets/lol/art/404-dark.png" alt="404" />
    </div>
  );
}
