import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";

export const Tag = `
  tag
  type
  category
  meta
`;
export interface SummonerTag {
  tag: string;
  type: string;
  category: string;
  meta: string;
}

//-----------------------------------------------------------------------------------------------------------------

const LIVE_GAME_TAGS = gql`
  query LiveGameTags($queueId: Int!, $regionId: String!, $summoners: [LiveGameSummoner]!) {
    liveGameTags(queueId: $queueId, regionId: $regionId, summoners: $summoners) {
      riotUserName
      riotTagLine
      tagList {
        ${Tag}
      }
    }
  }
`;

interface LiveGameTagsData {
  liveGameTags: Array<{
    riotUserName: string;
    riotTagLine: string;
    tagList: SummonerTag[];
  }>;
}

export const useLiveGameTags = (
  summoners: Array<{ riotUserName: string; riotTagLine: string; championId: number }>,
  regionId: string,
  queueId: number,
  queryOptions: QueryHookOptions = {},
) => {
  return useQuery<LiveGameTagsData>(LIVE_GAME_TAGS, {
    ...queryOptions,
    variables: {
      summoners,
      regionId,
      queueId,
    },
  });
};
