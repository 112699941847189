import gql from "graphql-tag";
import { useQuery, useLazyQuery, QueryHookOptions } from "@apollo/client";
import { replacePlusFromURLPath } from "@ugg/shared/utils/url-query-params";
import { SummonerProfileInitSimple, PlayerInfo } from "./profile-init";
import { MatchSummary, MatchSummaryInfo } from "./match-summaries";
import { RankScore } from "./profile-ranks";
import { Tag, SummonerTag } from "./summoner-tags";
import { PerformanceScoreSchema, PerformanceScore } from "./performance";

export const GET_SINGLE_MATCH_DATA = gql`
  query match(
    $matchId: String!,
    $regionId: String!,
    $riotUserName: String!,
    $riotTagLine: String!,
    $version: String!
  ) {
    match(
      matchId: $matchId,
      regionId: $regionId,
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine,
      version: $version
    ) {
      allPlayerRanks {
        rankScores {
          lastUpdatedAt
          losses
          lp
          queueType
          rank
          role
          seasonId
          tier
          wins
        }
        riotUserName
        riotTagLine
      }
      historicalData {
        kaDifferenceFrames {
          oppValue
          timestamp
          youValue
        }
        xpDifferenceFrames {
          oppValue
          timestamp
          youValue
        }
        teamOneOverview {
          bans
          baronKills
          dragonKills
          gold
          inhibitorKills
          kills
          riftHeraldKills
          towerKills
        }
        teamTwoOverview {
          bans
          baronKills
          dragonKills
          gold
          inhibitorKills
          kills
          riftHeraldKills
          towerKills
        }
        runes
        skillPath
        statShards
        accountIdV3
        csDifferenceFrames {
          oppValue
          timestamp
          youValue
        }
        finishedItems{
          itemId
          timestamp
          type
        }
        goldDifferenceFrames {
          oppValue
          timestamp
          youValue
        }
        itemPath {
          itemId
          timestamp
          type
        }
        matchId
        metricsData {
          cs
          jungleCs
          level
          pid
          position
          riotTagLine
          riotUserName
          timestamp
          totalDamageDoneToChampions
          totalDamageTaken
          totalGold
          xp
        }
        postGameData {
          assists
          augments
          carryPercentage
          championId
          cs
          damage
          damageTaken
          deaths
          gold
          items
          jungleCs
          keystone
          kills
          level
          role
          subStyle
          riotUserName
          riotTagLine
          summonerSpells
          teamId
          wardsPlaced
          level
        }
        timelineData {
          assistPids
          assistRiotIds {
            username
            tagLine
          }
          buildingType
          eventType
          killerId
          killerIds
          laneType
          monsterSubtype
          monsterType
          pid
          position
          riotTagLine
          riotUserName
          teamId
          timestamp
          towerType
          victimId
          victimRiotTagLine
          victimRiotUserName
          wardType
        }
        primaryStyle
        queueType
        regionId
        subStyle
        riotUserName
        riotTagLine
      }
      matchSummary {
        ${MatchSummaryInfo}
      }
      playerInfo {
        ${PlayerInfo}
      }
      performanceScore {
        ${PerformanceScoreSchema}
      }
      winningTeam
    }
  }
`;

export interface DifferenceFrame {
  oppValue: number;
  timestamp: number;
  youValue: number;
}

interface TeamOverview {
  bans: number[];
  baronKills: number;
  dragonKills: number;
  gold: number;
  inhibitorKills: number;
  kills: number;
  riftHeraldKills: number;
  towerKills: number;
}

export type EventType =
  | "pause_end"
  | "item_purchased"
  | "item_undo"
  | "skill_level_up"
  | "ward_placed"
  | "ward_kill"
  | "level_up"
  | "item_destroyed"
  | "champion_kill"
  | "champion_special_kill"
  | "monster_kill" // custom value converted from elite_monster_kill
  | "elite_monster_kill"
  | "turret_plate_destroyed"
  | "item_sold"
  | "building_kill"
  | "objective_bounty_prestart"
  | "objective_bounty_finish"
  | "game_end";
export type BuildingType = "tower_building" | "inhibitor_building";
export type TowerType = "outer_turret" | "inner_turret" | "base_turret" | "nexus_turret";
export type WardType =
  | "yellow_trinket"
  | "blue_trinket"
  | "control_ward"
  | "sight_ward"
  | "teemo_mushroom"
  | "undefined"
  | "vision_ward" // deprecated
  | "yellow_trinket_upgrade"; // deprecated
export type MonsterType = "dragon" | "baron_nashor" | "horde" | "riftherald";
export type MonsterSubType =
  | "air_dragon"
  | "elder_dragon"
  | "hextech_dragon"
  | "fire_dragon"
  | "earth_dragon"
  | "water_dragon"
  | "chemtech_dragon";

export interface SummonerMatch {
  winningTeam: number;
  allPlayerRanks: Array<{
    rankScores: RankScore[];
    riotUserName: string;
    riotTagLine: string;
  }>;
  matchSummary: MatchSummary;
  playerInfo: SummonerProfileInitSimple["playerInfo"];
  performanceScore: PerformanceScore[];
  historicalData: {
    csDifferenceFrames: DifferenceFrame[];
    xpDifferenceFrames: DifferenceFrame[];
    goldDifferenceFrames: DifferenceFrame[];
    kaDifferenceFrames: DifferenceFrame[];
    teamOneOverview: TeamOverview;
    teamTwoOverview: TeamOverview;
    runes: number[];
    skillPath: number[];
    statShards: number[];
    finishedItems: Array<{
      itemId: number;
      timestamp: number;
      type: number;
    }>;
    itemPath: Array<{
      itemId: number;
      timestamp: number;
      type: number;
    }>;
    matchId: string;
    metricsData: Array<{
      cs: number;
      jungleCs: number;
      level: number;
      pid: number;
      position: [{ x: number; y: number }];
      riotTagLine: string;
      riotUserName: string;
      timestamp: number;
      totalDamageDoneToChampions: number;
      totalDamageTaken: number;
      totalGold: number;
      xp: number;
    }>;
    postGameData: Array<{
      assists: number;
      augments: number[];
      carryPercentage: number;
      championId: number;
      cs: number;
      damage: number;
      damageTaken: number;
      deaths: number;
      gold: number;
      items: number[];
      jungleCs: number;
      keystone: number;
      kills: number;
      level: number;
      role: number;
      subStyle: number;
      riotUserName: string;
      riotTagLine: string;
      summonerSpells: number[];
      teamId: number;
      wardsPlaced: number;
    }>;
    timelineData: Array<{
      assistPids: number[] | null;
      assistRiotIds: Array<{
        tagLine: string;
        username: string;
      }>;
      buildingType: BuildingType | null;
      eventType: EventType;
      killerId: number | null;
      killerIds: number[] | null;
      laneType: string | null;
      monsterSubtype: MonsterSubType | null;
      monsterType: MonsterType | null;
      pid: number;
      position: Array<{ x: number; y: number }> | null;
      riotTagLine: string;
      riotUserName: string;
      teamId: number | null;
      timestamp: number;
      towerType: TowerType | null;
      victimId: number | null;
      victimRiotTagLine: string;
      victimRiotUserName: string;
      wardType: WardType;
    }>;
    primaryStyle: number;
    queueType: string;
    regionId: string;
    subStyle: number;
    riotUserName: string;
    riotTagLine: string;
  };
}

export interface SummonerMatchData {
  match: SummonerMatch;
}

const getSummonerMatchVariables = (region: string, riotUserName: string, riotTagLine: string, patch: string, matchId: string) => {
  return {
    regionId: region || "",
    riotUserName: replacePlusFromURLPath(riotUserName) || "",
    riotTagLine: replacePlusFromURLPath(riotTagLine) || "",
    matchId: matchId || "",
    version: patch || "",
  };
};

export function useSummonerMatch(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  patch: string,
  matchId: string,
  queryOptions: QueryHookOptions = {},
) {
  return useQuery<SummonerMatchData>(GET_SINGLE_MATCH_DATA, {
    ...queryOptions,
    variables: getSummonerMatchVariables(region, riotUserName, riotTagLine, patch, matchId),
  });
}

export function useLazySummonerMatch(
  region: string,
  riotUserName: string,
  riotTagLine: string,
  patch: string,
  matchId: string,
  queryOptions: QueryHookOptions = {},
) {
  return useLazyQuery(GET_SINGLE_MATCH_DATA, {
    ...queryOptions,
    variables: getSummonerMatchVariables(region, riotUserName, riotTagLine, patch, matchId),
  });
}

export const GET_MATCH_PAGE_EXTRA_DATA = gql`
  query matchPageExtraData(
    $matchIdString: String!,
    $matchIdInt: Int!,
    $regionId: String!,
    $riotUserName: String!,
    $riotTagLine: String!,
    $version: String!
  ) {
    fetchPostgameTags(
      matchId: $matchIdInt,
      regionId: $regionId,
      version: $version
    ) {
      riotUserName
      riotTagLine
      tagList {
        ${Tag}
      }
    }
    fetchPerformanceScore(
      matchId: $matchIdString,
      regionId: $regionId,
      riotUserName: $riotUserName,
      riotTagLine: $riotTagLine,
      version: $version
    ) {
      ${PerformanceScoreSchema}
    }
  }
`;

export interface MatchPageExtraData {
  fetchPostgameTags: Array<{
    riotUserName: string;
    riotTagLine: string;
    tagList: SummonerTag[];
  }>;
  fetchPerformanceScore: PerformanceScore[];
}

export function useMatchPageExtraData(
  riotUserName: string,
  riotTagLine: string,
  regionId: string,
  version: string,
  matchId: number,
  queryOptions: QueryHookOptions = {},
) {
  return useQuery<MatchPageExtraData>(GET_MATCH_PAGE_EXTRA_DATA, {
    ...queryOptions,
    variables: {
      riotUserName: decodeURIComponent(riotUserName),
      riotTagLine: decodeURIComponent(riotTagLine),
      regionId,
      version,
      matchIdString: String(matchId),
      matchIdInt: matchId,
    },
  });
}
