import { Request } from "express";
import { window } from "global";
import { addCookie, removeCookie, getCookie } from "../utils/cookies";

export const DEFAULT_LANG = "en_US";

export const uggSupportedLanguages = [
  "en_US",
  "cs_CZ",
  "de_DE",
  "el_GR",
  "es_ES",
  "fr_FR",
  "hu_HU",
  "it_IT",
  "ja_JP",
  "ko_KR",
  "pl_PL",
  "pt_BR",
  "ro_RO",
  "ru_RU",
  "th_TH",
  "tr_TR",
  "vn_VN",
  "zh_CN",
  "zh_TW",
];

export const defaultBrowserLangauges = [
  "en_US",
  // "cs_CZ",
  // "de_DE",
  // "el_GR",
  "es_ES",
  "fr_FR",
  // "hu_HU",
  // "it_IT",
  // "ja_JP",
  // "ko_KR",
  "pl_PL",
  // "pt_BR",
  // "ro_RO",
  // "ru_RU",
  // "th_TH",
  // "tr_TR",
  // "vn_VN",
  "zh_CN",
  // "zh_TW"
];

export const LANGUAGE_OPTIONS = [
  { label: "English", value: "en_US" },
  // { label: "en_AU", value: "en_AU" },
  // { label: "en_GB", value: "en_GB" },
  // { label: "en_PH", value: "en_PH" },
  // { label: "en_SG", value: "en_SG" },
  // { label: "es_AR", value: "es_AR" },
  // { label: "es_MX", value: "es_MX" },
  { label: "Deutsch", value: "de_DE" },
  { label: "Español", value: "es_ES" },
  { label: "Français", value: "fr_FR" },
  { label: "Italiano", value: "it_IT" },
  { label: "Polski", value: "pl_PL" },
  { label: "Ελληνικά", value: "el_GR" },
  { label: "Română", value: "ro_RO" },
  { label: "Magyar", value: "hu_HU" },
  { label: "Čeština", value: "cs_CZ" },
  { label: "Português", value: "pt_BR" },
  { label: "Русский", value: "ru_RU" },
  { label: "Türkçe", value: "tr_TR" },
  { label: "Tiếng Việt", value: "vn_VN" },
  { label: "ไทย", value: "th_TH" },
  { label: "日本語", value: "ja_JP" },
  { label: "한국어", value: "ko_KR" },
  { label: "简体中文", value: "zh_CN" },
  { label: "繁體中文", value: "zh_TW" },
  // { label: "zh_MY", value: "zh_MY" },
];

// Browser lang code -> Riot land code
const langMapping: Record<string, string> = {
  vi: "vn_VN",
};

export function findRiotLangMatch(langToCheck: string) {
  const riotLangCode = langMapping[langToCheck];
  const normalizeLang = (riotLangCode || langToCheck).replace("-", "_").toLowerCase();
  const matchLang = uggSupportedLanguages.find((el) => {
    return el.toLowerCase().indexOf(normalizeLang) === 0;
  });
  return matchLang;
}

export function getUserLanguage(args?: { req?: Request; language?: string }) {
  const { req, language } = args || {};
  let finalLang = DEFAULT_LANG;
  if (language) {
    const matchLang = findRiotLangMatch(language);
    if (matchLang) finalLang = matchLang;
  } else {
    let cookieLang = "",
      acceptedLanguages = [];

    if (window) {
      cookieLang = getCookie("ugg_lang");
      acceptedLanguages = window.navigator.languages;
    } else if (req) {
      cookieLang = req.cookies["ugg_lang"];
      acceptedLanguages = req.acceptsLanguages();
    }
    const possibleLanguages = cookieLang ? [cookieLang, ...acceptedLanguages] : acceptedLanguages;
    const matchLang = findRiotLangMatch(possibleLanguages[0] || "");

    if (
      // TEMPORARILY DISABLED (Always default to English)
      // (!cookieLang && defaultBrowserLangauges.includes(matchLang)) ||
      cookieLang &&
      matchLang
    ) {
      finalLang = matchLang;
    }
  }

  return finalLang;

  // addCookie("ugg_lang", "en_US");
  // removeCookie("ugg_lang");
  // const cookieLang = getCookie("ugg_lang");
}
