import { createContext, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";
import { useLocation } from "react-router";
import { Location } from "history";
import { matchPattern } from "browser-extension-url-match";
import { useLazyQuery } from "@apollo/client";

export interface LightModeContextInterface {
  enabled: boolean;
  allowed: boolean;
}

const LightModeContextInitialState: LightModeContextInterface = {
  enabled: false,
  allowed: false,
};

const LightModeContext = createContext<LightModeContextInterface>(LightModeContextInitialState);
const LightModeDispatchContext = createContext<React.Dispatch<React.SetStateAction<LightModeContextInterface>> | undefined>(
  undefined,
);

const DUMMY_HOSTNAME = "https://u.gg";
const ALLOWED_PATHNAMES = ["/lol/profile/*"];

const LIGHT_MODE = gql`
  query LightMode($enabled: Boolean!) {
    lightMode(enabled: $enabled) {
      success
    }
  }
`;

const isLightModeAllowed = (location: Location) =>
  ALLOWED_PATHNAMES.map((url) => matchPattern(DUMMY_HOSTNAME + url).match(DUMMY_HOSTNAME + location.pathname)).includes(true);

const LightModeProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [lightMode, setLightMode] = useState({
    enabled: false,
    allowed: false,
  });

  const [postLightMode] = useLazyQuery(LIGHT_MODE, {
    ssr: false,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const uggLightModeEnabled = localStorage.getItem("uggLightModeEnabled");

    if (uggLightModeEnabled) {
      setLightMode((prevState) => ({ ...prevState, enabled: true }));
    }
  }, []);

  useEffect(() => {
    setLightMode((prevState) => ({
      ...prevState,
      allowed: isLightModeAllowed(location),
    }));
  }, [location]);

  useEffect(() => {
    if (lightMode.enabled && lightMode.allowed) {
      document?.querySelector("html")?.classList.add("jedi");
    } else {
      document?.querySelector("html")?.classList.remove("jedi");
    }
  }, [lightMode.enabled, lightMode.allowed]);

  useEffect(() => {
    if (isLightModeAllowed(location)) {
      postLightMode({
        variables: {
          enabled: lightMode.enabled,
        },
      });
    }
  }, [location, lightMode.enabled]);

  return (
    <LightModeContext.Provider value={lightMode}>
      <LightModeDispatchContext.Provider value={setLightMode}>{children}</LightModeDispatchContext.Provider>
    </LightModeContext.Provider>
  );
};

export { LightModeProvider, LightModeContext, LightModeDispatchContext };
