import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";

const GET_PLAYER_LP = gql`
  query getPlayerLP(
    $riotUserName: String!
    $riotTagLine: String!
    $regionId: String!
    $patchVersion: String!
    $timeframe: String!
  ) {
    fetchPlayerLpTimeline(
      riotUserName: $riotUserName
      riotTagLine: $riotTagLine
      regionId: $regionId
      patchVersion: $patchVersion
      timeframe: $timeframe
    ) {
      lpTrack {
        championId
        matchId
        lp
        rank
        score
        tier
      }
    }
  }
`;

export interface LPTrack {
  championId: number;
  matchId: number;
  lp: number;
  rank: string;
  score: number;
  tier: string;
}

interface PlayerLPData {
  fetchPlayerLpTimeline: Array<{
    lpTrack: LPTrack[];
  }>;
}

export function usePlayerLP(
  riotUserName: string,
  riotTagLine: string,
  regionId: string,
  additionalVaraibles?: { patchVersion?: string; timeframe?: string },
  queryOptions: QueryHookOptions = {},
) {
  const { patchVersion, timeframe } = additionalVaraibles || {};

  return useQuery<PlayerLPData>(GET_PLAYER_LP, {
    ...queryOptions,
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
      timeframe: timeframe || "seasonal",
      patchVersion: patchVersion || "12_1",
    },
  });
}
