import { window } from "global";
import { useEffect, useMemo, useContext } from "react";
import { renderToString } from "react-dom/server";
import classnames from "classnames";
import LPGraph from "./LPGraph";
import { UpdateDispatchContext, ActionTypes } from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { usePlayerLP, LPTrack } from "@ugg/shared/api/requests/summoner-profiles/lp-timeline";
import { useBlastedPatches } from "@ugg/shared/api/requests/patches";
import { getProBuildRankName, convertTierToValue, convertRomanNumeral } from "@ugg/shared/utils/rank-helpers";
import { getLPGraphTierColor, getPromotionMarkerURL } from "./lp-graph-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { ReactComponent as TriangleArrowUp } from "@ugg/shared/assets/svg/triangle-arrow-up.svg";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";

interface LPBlockContainerProps {
  queueType: string;
  tier: string;
}

export default function LPBlockContainer(props: LPBlockContainerProps) {
  const dispatch = useContext(UpdateDispatchContext);
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const { queueType, tier } = props;
  const { data: patches, loading: loadingPatches } = useBlastedPatches();

  const {
    data: lp1,
    loading: loadingLP1,
    error: errorLP1,
    refetch: refetchLP1,
  } = usePlayerLP(riotUserName, riotTagLine, region, { patchVersion: patches?.[0]?.id }, { ssr: false, skip: loadingPatches });

  useEffect(() => {
    if (!loadingPatches && patches) {
      dispatch({
        type: ActionTypes.ADD_UPDATES,
        payload: {
          lp1: refetchLP1,
        },
      });
    }
  }, [riotUserName, riotTagLine, region, loadingPatches]);

  const fetchingData = loadingPatches || loadingLP1;
  const data = useMemo(() => {
    if (fetchingData) {
      return null;
    }

    const MAX_DATA_POINTS = 100;
    const fullLPList = [lp1].reduce<LPTrack[]>((acc, curr, index) => {
      const { fetchPlayerLpTimeline } = curr || {};
      const timelineIndex = queueType === QueueTypeS.RANKED_SOLO ? 0 : 1;
      const { lpTrack } = (fetchPlayerLpTimeline && fetchPlayerLpTimeline[timelineIndex]) || {};
      const filteredLPTrack = (lpTrack || []).filter((point) => !!point.matchId && typeof point.score === "number");
      return [...acc, ...(filteredLPTrack || [])];
    }, []);
    const lpList = fullLPList.slice(-1 * MAX_DATA_POINTS);

    let min: number | undefined = undefined;
    let max: number | undefined = undefined;
    let minTier = "";
    let maxTier = "";
    const promotions = [];
    const demotions = [];
    const series = lpList.map((point, index) => {
      let promotedRank = undefined;
      let promotedTier = undefined;
      let demotedRank = undefined;
      let demotedTier = undefined;
      const rankKey = `${point.tier}_${point.rank}`.toLowerCase();
      if (index !== 0) {
        const prevPoint = lpList[index - 1];
        if ((point.tier && point.tier.toLowerCase()) === (prevPoint.tier && prevPoint.tier.toLowerCase())) {
          if (convertRomanNumeral(point.rank) < convertRomanNumeral(prevPoint.rank)) {
            // Rank promoted
            promotions.push(rankKey);
            promotedRank = rankKey;
          } else if (convertRomanNumeral(point.rank) > convertRomanNumeral(prevPoint.rank)) {
            // Rank demoted
            demotions.push(rankKey);
            demotedRank = rankKey;
          }
        } else if (convertTierToValue(point.tier) > convertTierToValue(prevPoint.tier)) {
          // Tier promoted
          promotions.push(rankKey);
          promotedTier = rankKey;
        } else if (convertTierToValue(point.tier) < convertTierToValue(prevPoint.tier)) {
          // Tier demoted
          demotions.push(rankKey);
          demotedTier = rankKey;
        }
      }
      if (min === undefined || min > point.score) {
        min = point.score;
        minTier = point.tier;
      }
      if (max === undefined || max < point.score) {
        max = point.score;
        maxTier = point.tier;
      }

      return {
        data: point,
        x: index,
        y: point.score,
        promotedRank,
        promotedTier,
        demotedRank,
        demotedTier,
        diff: lpList[0] ? point.score - lpList[0].score : 0,
        // UNCOMMENT BELOW TO ADD PROMOTION/DEMOTION MARKERS
        // dataLabels: {
        //   enabled: (promotedRank || promotedTier || demotedRank || demotedTier),
        //   useHTML: true,
        //   allowOverlap: true,
        //   formatter: function () {
        //     const { promotedRank, promotedTier, demotedRank, demotedTier, dataLabel } = this.point;
        //     const milestones = (promotedRank || promotedTier || demotedRank || demotedTier);
        //     const [tier, rank] = milestones.split("_");
        //     const classNames = classnames("lp-graph_data-label", {
        //       "lp-graph_data-label_hidden": !dataLabel,
        //       "lp-graph_data-label_promoted": (promotedRank || promotedTier),
        //       "lp-graph_data-label_promoted-inverse": (promotedRank || promotedTier) && (dataLabel && dataLabel.alignOptions.verticalAlign === "top"),
        //       "lp-graph_data-label_demoted": (demotedRank || demotedTier),
        //       "lp-graph_data-label_demoted-inverse": (demotedRank || demotedTier) && (dataLabel && dataLabel.alignOptions.verticalAlign === "bottom"),
        //       // "lp-graph_data-label_inverse": dataLabel && dataLabel.alignOptions.verticalAlign === "top"
        //     })
        //     return renderToString(
        //       <div className={classNames} style={{"zoom": zoom}}>
        //         <div className="rank-text" style={{ color: "#FFFFFF" }}>
        //           {getProBuildRankName(tier, rank, true)}
        //         </div>
        //         <TriangleArrowUp className="triangle-arrow" />
        //       </div>
        //     );
        //   }
        // },
        // marker: {
        //   enabled: (promotedRank || promotedTier || demotedRank || demotedTier),
        //   states: {
        //     hover: {
        //       enabled: true
        //     }
        //   },
        //   symbol: (promotedTier || demotedTier) ? `url(${getPromotionMarkerURL((promotedTier || demotedTier).split("_")[0])})` : undefined,
        //   fillColor: getLPGraphTierColor(point.tier),
        //   width: 14 / zoomScale,
        //   height: 14 / zoomScale
        // }
      };
    });

    return { series, min, max, minTier, maxTier };
  }, [loadingPatches, lp1]);

  const { series, min, max, minTier, maxTier } = data || {};
  let content = null;
  if (!window || fetchingData || !data) {
    content = (
      <div className="lp-graph_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else {
    content = <LPGraph series={series} min={min} max={max} tier={tier} minTier={minTier || ""} maxTier={maxTier || ""} />;
  }

  const totalMatchLP = series && series.length > 0 ? series[series.length - 1].diff : null;
  // const latestRank = (series && series.length > 0) && series[series.length - 1];
  // const currentSeason = patches && SEASON_OPTIONS.find(option => parseInt(patches[0].season) === option.value);
  // const currentSeasonLabel = currentSeason && currentSeason.label;

  if (!series) {
    return (
      <div className="lp-graph_loading graph-spinner">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-section content-section_no-padding lp-graph">
      <div className="header-container">
        <div className="lp-graph_header">
          <div className="flex-apart">
            <div>Last {series.length} games</div>
            {!fetchingData && totalMatchLP !== null && (
              <div className="match-lp">
                <div className="flex-center" style={{ margin: "0 2px" }}>
                  {totalMatchLP === null ? (
                    ""
                  ) : totalMatchLP === 0 ? (
                    <strong style={{ color: "#5f5f7b" }}>&ndash;</strong>
                  ) : (
                    <TriangleArrowDown className={`lp-gain-indicator ${totalMatchLP > 0 ? "gain" : "loss"}`} />
                  )}
                  <span className="match-lp-value">{Math.abs(totalMatchLP)} LP</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {content}
      <div className="flex-apart_bottom">
        <div>{series.length} games ago</div>
        <div>Last game</div>
      </div>
    </div>
  );
}
