import classnames from "classnames";
import { Link } from "react-router-dom";
import { TooltipContainer } from "@outplayed/tooltips";
import { getS13RankUrl } from "@outplayed/riot-assets";
import {
  getMaxSeasonPlacements,
  mergeLPs,
  MatchLP,
} from "@ugg/shared/components/SummonerProfiles/overview/match-history/match-history-helpers";
import { getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";
import { ReactComponent as TriangleArrowUp } from "@ugg/shared/assets/svg/triangle-arrow-up.svg";
import { ReactComponent as DoubleHearts } from "@ugg/shared/assets/svg/double-hearts-filled.svg";
import { ReactComponent as CircleQuestion } from "@ugg/shared/assets/svg/circle-question.svg";

// const tooltipInfo = (
//   <div>
//     <span>LP gains and losses (including promotion series) are calculated based on snapshots of the summoner’s rank. Rank snapshots are periodically recorded automatically and when updating a profile. To have more accurate readings of LP and rank, <strong>try updating the profile after every match</strong> to get the latest rank snapshot.</span>
//     <div style={{marginTop: 12}}>Inaccurate readings may be due to the following:</div>
//     <ol>
//       <li>Infrequent profile updates</li>
//       <li>Placement matches</li>
//       <li>Rank/LP decay from inactivity</li>
//       <li>Dodging ranked matches</li>
//     </ol>
//   </div>
// )

const tooltipInfo = (
  <div className="match-summary_lp-tooltip">
    <p>Due to API call limits, LP per game will occasionally be missing. To prevent “? LP”, here's what you can do:</p>
    <li>Manually clicking “Update” after every match will keep your LP per game accurate.</li>
    <div className="divider" />
    <div className="lp-priority">
      <Link to="/support-us">
        <DoubleHearts />
      </Link>
      <p>
        To thank our supporters, Ad-Free profiles are first in API call queue. <Link to="/support-us">Learn More</Link>
      </p>
    </div>
  </div>
);

interface MatchSummaryLPDisplayProps {
  version: string;
  matchLP: MatchLP;
  serverLPInfo: MatchLP;
  disableTooltip?: boolean;
  isMobile?: boolean;
}

export default function MatchSummaryLPDisplay(props: MatchSummaryLPDisplayProps) {
  const { version, matchLP, disableTooltip, isMobile, serverLPInfo } = props;
  const mergedLP = mergeLPs(matchLP, serverLPInfo);

  const { lp, promoTarget, promoProgress, promotedTo, placement } = mergedLP || {};
  if ((isNaN(lp) || lp === null) && !promoProgress && (!placement || placement < 0)) {
    return (
      <div className="match-lp lp-missing">
        <span className="lp-value">? LP</span>
        <TooltipContainer
          tooltipInfo={tooltipInfo}
          disableTooltip={disableTooltip}
          customStyle={{ maxWidth: "none" }}
          tooltipHoverable
        >
          <div className="flex-center" style={{ marginLeft: 5 }}>
            <CircleQuestion className="lp-question" />
          </div>
        </TooltipContainer>
      </div>
    );
  }

  return (
    <div className="match-lp">
      {placement && placement > 0 ? (
        <Placement placement={placement} version={version} />
      ) : (
        <div
          className={classnames("lp-diff-container", {
            "ranked-up": promotedTo && (!isNaN(lp) || promoProgress),
            "promotion-failed": !isNaN(lp) && promoProgress,
          })}
        >
          <LPDiff lp={lp} />
          <PromoProgress promoTarget={promoTarget} promoProgress={promoProgress} />
          <RankUp lp={lp} promoProgress={promoProgress} promotedTo={promotedTo} />
        </div>
      )}
    </div>
  );
}

const Placement = (props: Pick<MatchLP, "placement"> & { version: string }) => {
  const { placement, version } = props;
  if (!placement || placement < 0) return null;

  const maxPlacementGames = getMaxSeasonPlacements(version);

  return (
    <div className="placement-matches">
      <div className="placement-label">P</div>
      <div className="placement-progress">
        <span className="current-placement-match">{placement}</span>
        <span className="total-placement-matches">/{maxPlacementGames}</span>
      </div>
    </div>
  );
};

const PromoProgress = (props: { promoTarget: MatchLP["promoTarget"] | null; promoProgress: MatchLP["promoProgress"] | null }) => {
  const { promoTarget, promoProgress } = props;
  if (!promoProgress) return null;

  return (
    <div className="promos">
      {promoTarget && (
        <div className="promo-target">
          <img src={getS13RankUrl(promoTarget, true)} />
        </div>
      )}
      <div className="promo-progress">
        {promoProgress.split("").map((outcome, index) => {
          const promoGameClassNames = classnames("promo_game", {
            promo_game__win: outcome === "W",
            promo_game__loss: outcome === "L",
          });
          return <div key={index} className={promoGameClassNames} />;
        })}
      </div>
    </div>
  );
};

const LPDiff = (props: { lp: MatchLP["lp"] | null }) => {
  const { lp } = props;
  if (lp === null || lp === undefined) return null;

  return (
    <div className="lp-gain_loss">
      {lp > 0 ? (
        <TriangleArrowUp className={"lp-arrow gain"} />
      ) : lp < 0 ? (
        <TriangleArrowDown className={"lp-arrow loss"} />
      ) : (
        <span className="no-diff">&ndash;</span>
      )}
      <span className="lp-value">{`${Math.abs(lp)} LP`}</span>
    </div>
  );
};

const RankUp = (props: {
  lp: MatchLP["lp"] | null;
  promoProgress: MatchLP["promoProgress"] | null;
  promotedTo: MatchLP["promotedTo"] | null;
}) => {
  const { lp, promotedTo, promoProgress } = props;
  if (!promotedTo || lp === null || (lp !== null && lp !== undefined && isNaN(lp) && !promoProgress)) return null;

  return (
    <div className="rank-up">
      <TriangleArrowUp className="lp-arrow" />
      <img className="promoted-tier-img" src={getS13RankUrl(promotedTo.tier, true)} />
      <div className="promoted-rank">{getProBuildRankName(promotedTo.tier, promotedTo.rank, true)}</div>
    </div>
  );
};
