import { useRef } from "react";
import moment from "moment";
import "moment-duration-format";
import { TooltipContainer } from "@outplayed/tooltips";
import { UpdateButton } from "../UpdateButton";

const UPDATE_OUTDATED_TEXT = "a long time ago";

function getLastModifiedFromNow(lastModified: number) {
  if (!lastModified) return UPDATE_OUTDATED_TEXT;

  return moment(lastModified * 1000).fromNow();
}

const overXHoursAgo = (fromNowString: string, x = 12) => {
  if (fromNowString === UPDATE_OUTDATED_TEXT) return true;
  if (fromNowString.toLowerCase().indexOf("minute") !== -1) return false;
  if (fromNowString.toLowerCase().indexOf("second") !== -1) return false;
  if (fromNowString.toLowerCase().indexOf("hour") === -1) return true;
  const num = fromNowString.split(" ")[0];

  if (parseInt(num) >= x) return true;
  return false;
};

export function HeaderUpdateButton(props: { lastModified: number }) {
  const lastUpdatedFromNow = getLastModifiedFromNow(props.lastModified);
  const outOfDate = overXHoursAgo(lastUpdatedFromNow, 12);
  const tooltipRef = useRef<{ forceClose: Function }>({ forceClose: () => {} });

  return (
    <div className="flex">
      <TooltipContainer ref={tooltipRef} tooltipInfo={`Last Updated: ${lastUpdatedFromNow}`}>
        <div onClick={() => tooltipRef.current && tooltipRef.current.forceClose()}>
          <UpdateButton outOfDate={outOfDate} />
        </div>
      </TooltipContainer>
    </div>
  );
}
