import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { LiveGameMain } from "./LiveGameMain";
import { useLiveGame } from "@ugg/shared/api/requests/summoner-profiles/live-game";

export function LiveGameContainer() {
  const context = useSummonerProfileContext();
  const { realRiotUserName, realRiotTagLine, region } = context;

  const { loading, data, error, refetch } = useLiveGame(realRiotUserName, realRiotTagLine, region, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    ssr: false,
  });

  let content = null;
  if (loading) {
    content = <LiveGameLoading />;
  } else if (!data || error) {
    content = <LiveGameError riotUserName={realRiotUserName} riotTagLine={realRiotTagLine} />;
  } else {
    const { getLiveGame } = data;
    content = <LiveGameMain data={getLiveGame} refetch={() => refetch()} />;
  }

  return content;
}

function LiveGameLoading() {
  return (
    <div className="w-full flex justify-center h-[600px] p-[18px] max-md:p-[12px] rounded-[3px] bg-purple-200">
      <div className="spinthatshit-loader">
        <div className="spinner" />
      </div>
    </div>
  );
}

function LiveGameError({ riotUserName, riotTagLine }: { riotUserName: string; riotTagLine: string }) {
  return (
    <div className="w-full flex flex-col items-center justify-center h-[300px] rounded-[3px] p-[18px] max-md:p-[12px] bg-purple-200">
      <div className="text-white text-[24px] mb-[34px] font-bold text-center">{`'${riotUserName} #${riotTagLine}' is not currently in game.`}</div>
      <div className="text-[16px] text-lavender-50 text-center">
        Game just start? <span className="text-white font-bold">Refresh</span> when the Loading Screen appears.
      </div>
    </div>
  );
}
