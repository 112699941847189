import { PrimaryRoles } from "@ugg/shared/interfaces/champions/primary-roles.interface";
import { RoleS, RoleC, normalizeRole } from "@ugg/shared/utils/role-helpers";
import SummonerTag from "@ugg/shared/components/common/SummonerTag";
import { SummonerTag as SummonerTagInterface } from "@ugg/shared/api/requests/summoner-profiles/summoner-tags";
import { LiveGamePlayer } from "@ugg/shared/api/requests/summoner-profiles/live-game";
import { QueueTypeS } from "./queue-type-helpers";
import { ReactComponent as TagError } from "@ugg/shared/assets/svg/live-game/app/tag-error.svg";
import { ReactComponent as TagLoader } from "@ugg/shared/assets/svg/live-game/app/tag-loader.svg";

interface LiveGameScore {
  seasonScore: number;
  championScore: number;
  elo: number;
  isUnranked: boolean;
}

export type TagInfo = {
  tags: Record<string, SummonerTagInterface[]> | null;
  tagLoading: boolean;
  tagError: boolean;
  errorTagTimeout: boolean;
};

export interface LiveGamePlayerPlus extends LiveGamePlayer {
  teamId: number;
  seasonGamesPlayed: number;
  seasonWinRate: number;
  championGamesPlayed: number;
  championWinRate: number;
  playerTags: SummonerTagInterface[];
  playerTagsLoading: boolean | undefined;
  playerTagsError: boolean | undefined;
  playerTagsTimeout: boolean | undefined;
}

export function getInitialRole(
  gameType: string,
  currentRole: string,
  championId?: number,
  primaryRoles?: number[][] | PrimaryRoles,
) {
  const convertedRole = RoleC.convertToInt(currentRole) || RoleC.convertToInt(currentRole, true);
  if (gameType === QueueTypeS.ARAM) {
    return RoleS.ARAM;
  } else if (gameType === QueueTypeS.URF) {
    return RoleS.URF;
  } else if (convertedRole === 0) {
    if (primaryRoles && championId) {
      const championRoles = primaryRoles[championId];
      const mainRole = championRoles && championRoles[0];
      return mainRole ? RoleC.convertToString(mainRole) : RoleS.MIDDLE;
    } else {
      return RoleS.MIDDLE;
    }
  } else {
    return normalizeRole(currentRole);
  }
}

export function getTeamDataPlus(team: LiveGamePlayer[], teamId: number, tagInfo?: TagInfo): LiveGamePlayerPlus[] {
  return team
    .map((player: LiveGamePlayer) => {
      const { wins, losses } = player.currentSeasonRankScore || {};
      const seasonGamesPlayed = (wins || 0) + (losses || 0);
      const seasonWinRate = seasonGamesPlayed > 0 ? (wins || 0) / seasonGamesPlayed : 0;
      const championGamesPlayed = (player.championWins || 0) + (player.championLosses || 0);
      const championWinRate = championGamesPlayed > 0 ? (player.championWins || 0) / championGamesPlayed : 0;
      const playerTags = (tagInfo?.tags && tagInfo.tags[`${player.riotUserName}_${player.riotTagLine}`]) || [];
      const playerTagsLoading = tagInfo?.tagLoading;
      const playerTagsError = tagInfo?.tagError;
      const playerTagsTimeout = tagInfo?.errorTagTimeout;

      return {
        ...player,
        teamId,
        seasonGamesPlayed,
        seasonWinRate,
        championGamesPlayed,
        championWinRate,
        playerTags,
        playerTagsLoading,
        playerTagsError,
        playerTagsTimeout,
      };
    })
    .sort((a, b) => {
      const roleOrder = [4, 1, 5, 3, 2];
      const aRoleIndex = roleOrder.findIndex((role) => role === RoleC.convertToInt(a.currentRole, true));
      const bRoleIndex = roleOrder.findIndex((role) => role === RoleC.convertToInt(b.currentRole, true));
      return aRoleIndex - bRoleIndex;
    });
}

export const eloTierScores: { [key: string]: number } = {
  iron: 0,
  bronze: 400,
  silver: 800,
  gold: 1200,
  platinum: 1600,
  diamond: 2000,
  master: 2400,
  grandmaster: 2400,
  challenger: 2400,
};

export const eloRankScores: { [key: string]: number } = {
  i: 300,
  ii: 200,
  iii: 100,
  iv: 0,
};

export function getLiveGameWinRateColor(winRate: number) {
  if (winRate >= 70) {
    return "#ff9b00";
  } else if (winRate >= 60) {
    return "#3273fa";
  } else if (winRate >= 55) {
    return "#7ea4f4";
  } else if (winRate >= 45) {
    return "#cddcfe";
  } else {
    return "#7687ad";
  }
}

export function calculateLiveGameScore(player: LiveGamePlayerPlus | null): LiveGameScore {
  const defaultElo = 800;

  if (!player) {
    return {
      seasonScore: 0,
      championScore: 0,
      elo: defaultElo,
      isUnranked: true,
    };
  }

  const { currentSeasonRankScore, seasonWinRate, seasonGamesPlayed, championWinRate, championGamesPlayed } = player;
  const isUnranked = !currentSeasonRankScore || !currentSeasonRankScore.tier;
  const seasonScore = seasonWinRate * Math.log(seasonGamesPlayed);
  const championKicker =
    championWinRate >= 0.7 && championGamesPlayed >= 5 ? (championWinRate - seasonWinRate) * Math.log(championGamesPlayed) : 0;
  const championScore = championWinRate * Math.log(championGamesPlayed) + championKicker;
  const elo = isUnranked
    ? defaultElo
    : eloTierScores[currentSeasonRankScore.tier.toLowerCase()] +
      eloRankScores[currentSeasonRankScore.rank.toLowerCase()] +
      (currentSeasonRankScore.lp || 0);

  return {
    seasonScore: !isNaN(seasonScore) ? seasonScore : 0,
    championScore: !isNaN(championScore) ? championScore : 0,
    elo,
    isUnranked,
  };
}

export function calculateELOKicker(playerA: LiveGameScore, playerB: LiveGameScore) {
  if (playerA.isUnranked && playerB.elo <= 800) {
    return 0;
  }
  const eloDiff = playerA.elo - playerB.elo;
  return eloDiff > 0 ? eloDiff / 250 : 0;
}

export function renderTags(player: LiveGamePlayerPlus, maxLength?: number, tagSize?: "medium" | "large") {
  const tagMaxListLength = maxLength === undefined ? 99999 : maxLength;
  if (player.playerTagsTimeout) {
    return (
      <div className="tag-area_message">
        <TagError className="tag-error" /> Tags Timed Out
      </div>
    );
  } else if (player.playerTagsLoading) {
    return (
      <div className="tag-area_message">
        <TagLoader className="tag-loader" /> Loading Tags
      </div>
    );
  } else if (player.playerTagsError) {
    return (
      <div className="tag-area_message">
        <TagError className="tag-error" /> Currently Unavailable
      </div>
    );
  } else {
    return (
      <>
        {player.playerTags.slice(0, tagMaxListLength).map((tagInfo, idx) => (
          <SummonerTag
            key={idx}
            tag={tagInfo.tag}
            type={tagInfo.type}
            meta={tagInfo.meta}
            size={tagSize || "medium"}
            championId={player.championId}
          />
        ))}
        {player.playerTags.length > tagMaxListLength && (
          <SummonerTag
            tag={"+" + (player.playerTags.length - 4)}
            type={"neutral"}
            meta={player.playerTags.slice(4).map((tagInfo, idx) => {
              return <div key={idx}>{tagInfo.tag}</div>;
            })}
            size={tagSize || "medium"}
          />
        )}
      </>
    );
  }
}
