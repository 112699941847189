import { useState, useEffect, useMemo, Dispatch, SetStateAction } from "react";
import classnames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { SummonerMatch } from "@ugg/shared/api/requests/summoner-profiles/single-match";

type PlayerStats = {
  level: number;
  championId: number;
  className: string;
  Damage: number;
  Kills: number;
  KDA: string | number;
  Gold: number;
  Wards: number;
  CS: number;
};

interface PerformanceProps {
  data: SummonerMatch;
}

export default function Performance(props: PerformanceProps) {
  const { data } = props;
  const { historicalData, winningTeam } = data;
  const { postGameData } = historicalData;
  const [currentStat, setCurrentStat] = useState<keyof PlayerStats>("Damage");
  const [progress, setProgress] = useState<Record<string, number>>({});
  const tabs: Array<keyof PlayerStats | "Player"> = ["Player", "Kills", "KDA", "Damage", "Gold", "Wards", "CS"];

  const playerStats = useMemo(() => {
    const userStats: Record<string, PlayerStats> = {};
    for (const player of postGameData) {
      if (player.riotUserName && player.riotTagLine) {
        userStats[`${player.riotUserName} #${player.riotTagLine}`] = {
          level: player["level"],
          championId: player["championId"],
          className: player["teamId"] === winningTeam ? "win" : "loss",
          Damage: player["damage"],
          Kills: player["kills"],
          KDA: player["deaths"] === 0 ? "Perfect" : (player["kills"] + player["assists"]) / player["deaths"],
          Gold: player["gold"],
          Wards: player["wardsPlaced"],
          CS: player["cs"] + player["jungleCs"],
        };
      }
    }
    return userStats;
  }, [postGameData, winningTeam]);

  const displayRows = useMemo(() => {
    const progressStats: Record<string, number> = {};
    const allPlayerPairs = Object.keys(playerStats).map((key) => [key, playerStats[key]] as const);
    allPlayerPairs.sort((a, b) => {
      if (currentStat === "KDA") {
        if (a[1][currentStat] === "Perfect" && b[1][currentStat] === "Perfect") return 0;
        if (b[1][currentStat] === "Perfect") return 1;
        else if (a[1][currentStat] === "Perfect") return -1;
      }
      return Number(b[1][currentStat]) - Number(a[1][currentStat]);
    });
    // const sortedRows = Object.fromEntries(allPlayerPairs);
    if (allPlayerPairs.length !== 0) {
      // const baseValue = sortedRows[Object.keys(sortedRows)[0]][currentStat];
      let baseValue = allPlayerPairs[0][1][currentStat];
      for (const value of Object.values(allPlayerPairs)) {
        const playerName = value[0];
        const playerStats = value[1];

        if (currentStat === "KDA") {
          const foundNumberKDA = allPlayerPairs.find((player) => typeof player[1][currentStat] === "number");
          baseValue = (foundNumberKDA ? foundNumberKDA[1][currentStat] : 1) as number;
          if (playerStats[currentStat] === "Perfect") {
            progressStats[playerName] = 100;
          } else {
            progressStats[playerName] = (Number(playerStats[currentStat]) / baseValue) * 100;
          }
        } else if (typeof baseValue === "number") {
          progressStats[playerName] = (Number(playerStats[currentStat]) / baseValue) * 100;
        }
      }
    }
    setProgress(progressStats);
    return allPlayerPairs;
  }, [currentStat, playerStats]);

  return (
    <div className="mobile-static">
      <div className="mobile-slide">
        <div className="expanded-performance-container">
          <div className="description-header">
            {tabs.map((tab) => (
              <SelectButton title={tab} setCurrentStat={setCurrentStat} currentStat={currentStat} key={tab} />
            ))}
          </div>
          <div className="performance-rows">
            {displayRows.map(([username, userData]) => (
              <PerformanceRow
                key={username}
                userData={userData}
                username={username}
                currentStat={currentStat}
                progress={progress[username]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

interface PerformanceRowProps {
  username: string;
  userData: PlayerStats;
  currentStat: keyof PlayerStats;
  progress: number;
}
const PerformanceRow = (props: PerformanceRowProps) => {
  const { userData, username, currentStat, progress } = props;
  const { getChampionImg } = getRiotAssetsContext();
  const result = userData["className"];
  const selectedResult = `selected-${result}`;
  const progressResult = `progress-${result}`;
  const kda = userData["KDA"] === "Perfect" ? "Perfect" : Number(userData["KDA"]).toFixed(2);
  return (
    <div className={`performance-row ${result}`}>
      <div className="image-container">
        <img src={getChampionImg(userData["championId"])} className="champion-img"></img>
      </div>
      <div className="player-container">
        <div className="username">{username}</div>
        <div className="progress-bar">
          <div className={progressResult} style={{ width: `${progress}%` }}></div>
        </div>
      </div>
      <div className={classnames("kills", currentStat === "Kills" ? selectedResult : "")}>{userData["Kills"]}</div>
      <div className={classnames("kda", currentStat === "KDA" ? selectedResult : "")}>{kda}</div>
      <div className={classnames("damage", currentStat === "Damage" ? selectedResult : "")}>
        {numberWithCommas(userData["Damage"])}
      </div>
      <div className={classnames("gold", currentStat === "Gold" ? selectedResult : "")}>{numberWithCommas(userData["Gold"])}</div>
      <div className={classnames("wards", currentStat === "Wards" ? selectedResult : "")}>{userData["Wards"]}</div>
      <div className={classnames("cs", currentStat === "CS" ? selectedResult : "")}>{userData["CS"]}</div>
    </div>
  );
};

interface SelectButtonProps {
  title: keyof PlayerStats | "Player";
  currentStat: keyof PlayerStats;
  setCurrentStat: Dispatch<SetStateAction<keyof PlayerStats>>;
}

const SelectButton = (props: SelectButtonProps) => {
  const { title, setCurrentStat, currentStat } = props;
  const selected = title === currentStat ? "selected" : "";
  const containerSelected = title === currentStat ? "tab-container-selected" : "";
  return (
    <div className={`tab ${containerSelected}`}>
      {title === "Player" ? (
        title
      ) : (
        <>
          <div
            className={`tab-title ${selected}`}
            onClick={() => {
              setCurrentStat(title);
            }}
          >
            {title}
          </div>
        </>
      )}
    </div>
  );
};
