import { useState, useEffect, useCallback } from "react";
import { getAllstarURI } from "./allstar-helpers";
import { normalizeRiotId } from "@ugg/shared/utils/riot-id-helpers";
import { convertLeagueClientRegion } from "@ugg/shared/utils/region-helpers";

export interface UserClipsData {
  message: string;
  requestId: string;
  data: {
    clips: Array<{
      _id: string;
      clipUrl: string;
      username: string;
      roundNumber: null;
      clipLength: number;
      status: string;
      onDemand: boolean;
      clipTitle: string;
      shareId: string;
      createdDate: string;
      updated: string;
      views: number;
      clipSnapshotURL: string;
      clipImageThumbURL: string;
      requestId: string;
      metadata: Array<{
        key: string;
        value: string;
      }>;
    }>;
    currentPage: number;
    count: number;
    limit: number;
  };
}

export function useGetUserClips(
  riotId: string,
  options: { onDemand?: boolean; spectating?: boolean; inProgress?: boolean; skip?: boolean } = {},
) {
  const { onDemand = false, spectating = false, inProgress = false, skip = false } = options;
  const [state, setState] = useState<{
    data: UserClipsData | null;
    loading: boolean;
    error: any;
  }>({
    data: null,
    loading: false,
    error: null,
  });

  const request = useCallback(() => {
    setState({ data: null, loading: true, error: null });

    const searchParams = new URLSearchParams({
      riotID: normalizeRiotId(riotId),
      onDemand: onDemand ? "1" : "0",
      spectating: spectating ? "1" : "0",
      inProgress: inProgress ? "1" : "0",
    });
    const url = `${getAllstarURI()}/user/clips?${searchParams.toString()}`;

    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setState({ data: json, loading: false, error: null });
      })
      .catch((error) => {
        setState({ data: null, loading: false, error });
      });
  }, [riotId, onDemand, spectating, inProgress]);

  useEffect(() => {
    if (skip) return;

    request();
  }, [request, skip]);

  return { ...state, refetch: request };
}

export interface LeagueClipsData {
  message: string;
  requestId: string;
  data: {
    clips: Array<{
      _id: string;
      clipUrl: string;
      username: string;
      demoUrl: string;
      roundNumber: null;
      clipLength: number;
      status: string;
      onDemand: boolean;
      clipTitle: string;
      shareId: string;
      createdDate: string;
      updated: string;
      views: number;
      clipSnapshotURL: string;
      clipImageThumbURL: string;
      requestId: string;
      metadata: Array<{
        key: string;
        value: string;
      }>;
    }>;
    currentPage: number;
    count: number;
    matchId: string;
  };
}

export function useGetLeagueClips(
  region: string,
  matchId: string,
  options: {
    riotId?: string;
    onDemand?: boolean;
    spectating?: boolean;
    inProgress?: boolean;
    skip?: boolean;
  } = {},
) {
  const { riotId, onDemand = false, spectating = false, inProgress = false, skip = false } = options;
  const [state, setState] = useState<{
    data: LeagueClipsData | null;
    loading: boolean;
    error: any;
  }>({
    data: null,
    loading: false,
    error: null,
  });

  const request = useCallback(() => {
    setState({ data: null, loading: true, error: null });

    const searchParams = new URLSearchParams({
      // region: convertLeagueClientRegion(region),
      region: convertLeagueClientRegion(region).toUpperCase(),
      matchId: matchId,
      onDemand: onDemand ? "1" : "0",
      inProgress: inProgress ? "1" : "0",
    });
    // Specating clips only returned when passed user
    if (riotId && spectating) {
      searchParams.append("riotID", riotId);
      searchParams.append("spectating", "1");
    }
    const url = `${getAllstarURI()}/league/clips?${searchParams.toString()}`;

    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setState({ data: json, loading: false, error: null });
      })
      .catch((error) => {
        setState({ data: null, loading: false, error });
      });
  }, [region, matchId, riotId, onDemand, spectating, inProgress]);

  useEffect(() => {
    if (skip) return;

    request();
  }, [request, skip]);

  return { ...state, refetch: request };
}
