import { useState, useEffect } from "react";
import { useGetUserClips } from "@ugg/shared/api/requests/allstar/get-clips";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { SummonerProfileHighlights } from "@ugg/shared/components/SummonerProfiles/highlights/SummonerProfileHighlights";
import AppDownloadButton from "components/Buttons/AppDownloadButton";
import { ReactComponent as AllstarLogo } from "@ugg/shared/assets/svg/allstar-logo.svg";
import MediaQuery from "components/MediaQuery";
import { datadogLogs } from "@datadog/browser-logs";
import { getAllstarClipOpenLog } from "@ugg/shared/api/requests/allstar/allstar-helpers";

export function SummonerProfilesHighlightsCTA() {
  const { riotUserName, riotTagLine, region } = useSummonerProfileContext();
  const [mounted, setMounted] = useState(false);

  const { data, loading, error } = useGetUserClips(`${riotUserName}#${riotTagLine}`);
  const { clips } = data?.data || {};
  const hasClips = clips && clips.length > 0;

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || loading) return null;

  if (hasClips) {
    return (
      <SummonerProfileHighlights
        className="mt-[24px]"
        onClipOpen={(riotUserName, riotTagLine, region) => {
          const log = getAllstarClipOpenLog("profile highlights page", "site", { riotUserName, riotTagLine, region });
          datadogLogs.logger.info(log.message, log.context);
        }}
      />
    );
  }

  return (
    <>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_SMALL">
        <div className="mt-[36px] max-w-[1016px] flex-1 flex flex-col items-center justify-center">
          <div className="max-w-[650px] w-[100%] max-h-[329px] flex flex-column items-start justify-start relative">
            <div className="flex flex-nowrap gap-[4px] absolute right-[5px] top-[10px] text-[12px] font-medium text-lavender-50">
              Powered by <AllstarLogo className="h-[14px]" />
            </div>
            <img src="https://static.bigbrain.gg/assets/lol/backgrounds/highlights_cta_bg.png" />
            <div className="flex flex-col items-start justify-center mt-[18px]">
              <div className="text-[18px] font-bold font-[Barlow]">YOUR BEST MOMENTS</div>
              <div className="text-[14px] font-[Barlow]">AUTOMATICALLY CLIPPED AND SAVED</div>
              <div className="text-[14px] font-[Barlow]">WITH THE U.GG DESKTOP APP.</div>
              <AppDownloadButton
                className="mt-[18px]"
                url="/app?utm_medium=siteprofilehighlight"
                label="Download App"
                icon="cloud"
              />
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery min="MOBILE_MEDIUM" max="DESKTOP_LARGE">
        <div className="mt-[120px] max-w-[1016px] flex-1 flex flex-col items-center justify-center">
          <div className="max-w-[650px] w-[100%] sm:max-w-[800px] max-h-[329px] flex flex-row items-center justify-end relative">
            <div className="flex flex-nowrap gap-[4px] absolute right-0 top-[24px] text-[12px] font-medium text-lavender-50">
              Powered by <AllstarLogo className="h-[14px]" />
            </div>
            <div className="flex flex-col items-start justify-center absolute left-0">
              <div className="text-[20px] xs:text-[36px] font-bold font-[Barlow]">YOUR BEST MOMENTS</div>
              <div className="text-[18px] xs:text-[30px] font-[Barlow]">AUTOMATICALLY CLIPPED AND SAVED</div>
              <div className="text-[18px] xs:text-[30px] font-[Barlow]">WITH THE U.GG DESKTOP APP.</div>
              <AppDownloadButton
                className="mt-[8px] xs:mt-[36px]"
                url="/app?utm_medium=siteprofilehighlight"
                label="Download App"
                icon="cloud"
              />
            </div>
            <div className="bg-gradient">
              <img className="relative z-[-1]" src="https://static.bigbrain.gg/assets/lol/backgrounds/highlights_cta_bg.png" />
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
}
