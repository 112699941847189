import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { NavTab, PageNav } from "@ugg/shared/components/common/PageNav";
import {
  getProfileOverviewUrl,
  getProfileChampionStatsUrl,
  getProfileLiveGameUrl,
  getProfileHighlightsUrl,
} from "@ugg/shared/routes/app-routes";
import { useBoolLiveGameExist } from "@ugg/shared/api/requests/summoner-profiles/live-game";
import { skipAllstar } from "@ugg/shared/api/requests/allstar/allstar-helpers";
import { APP_BUILD_TYPE } from "@ugg/shared/constants/constants";

export function SummonerProfilePageNav({ className }: { className?: string }) {
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const { data } = useBoolLiveGameExist(riotUserName, riotTagLine, region, { ssr: false, fetchPolicy: "network-only" });

  const navTabs: NavTab[] = [
    {
      path: getProfileOverviewUrl(region, riotUserName, riotTagLine),
      activePaths: [getProfileOverviewUrl(region, riotUserName, riotTagLine)],
      label: "Overview",
      reset: true,
    },
    {
      path: getProfileChampionStatsUrl(region, riotUserName, riotTagLine),
      label: "Champion Stats",
    },
    {
      path: getProfileLiveGameUrl(region, riotUserName, riotTagLine),
      label: "Live Game",
      live: !!data?.liveGameExists,
    },
  ];

  if (!skipAllstar(region)) {
    navTabs.push({
      path: getProfileHighlightsUrl(region, riotUserName, riotTagLine),
      label: "Highlights",
      new: true,
    });
  }

  return <PageNav className={className} tabs={navTabs} />;
}
