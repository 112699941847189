import { window } from "global";
import { useGlobal } from "reactn";
import { CSSTransition } from "react-transition-group";

export function FadeTransitionWrapper({ children }: { children: React.ReactNode }) {
  const [allowCssTransition] = useGlobal("allowCssTransition");

  return (
    <CSSTransition
      classNames={{
        appear: "opacity-0",
        appearDone: "transition-opacity duration-[200ms] opacity-100",
      }}
      timeout={0}
      in
      appear={allowCssTransition}
    >
      {children}
    </CSSTransition>
  );
}
