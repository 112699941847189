import { window } from "global";
import { useState, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import ButtonSuccess from "components/Buttons/ButtonSuccess";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { updateProfileCustomizations } from "@ugg/shared/api/requests/summoner-profiles/profile-customizations";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { ReactComponent as IconX } from "svg/x.svg";
import { ReactComponent as SearchIcon } from "svg/search-icon.svg";
import { ReactComponent as CheckboxIcon } from "svg/checkbox.svg";
import { ReactComponent as EditIcon } from "svg/edit-icon.svg";

export function ProfileBackgroundButton({ className }: { className?: string }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div
        className={classNames("flex items-center justify-center h-[44px] cursor-pointer", className)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <EditIcon className="w-[14px] mr-[8px]" />
        <div className="text-white text-[16px] font-medium hover:text-accent-blue-400">Edit</div>
      </div>
      {openModal && <ProfileBackgroundModal close={() => setOpenModal(false)} />}
    </>
  );
}

function ThumbnailImage({ imgSrc }: { imgSrc: string }) {
  const [imageLoaded, setImageLoaded] = useState("");
  const isLoaded = imgSrc === imageLoaded;
  return (
    <div className="w-full h-full rounded-[3px] bg-purple-200 bg-opacity-50 overflow-hidden">
      <img style={{ display: isLoaded ? "" : "none" }} className="w-full" src={imgSrc} onLoad={() => setImageLoaded(imgSrc)} />
      {!isLoaded && (
        <div className="spinthatshit-loader h-full">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
}

interface ProfileBackgroundModalProps {
  close: () => void;
}
export function ProfileBackgroundModal(props: ProfileBackgroundModalProps) {
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const { useChampionFull, getChampionIdByName, getChampionSplashImg } = getRiotAssetsContext();
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;
  const { loading: loadingProfileInit, data: profileInitData } = useSummonerProfileInitSimple(
    region,
    riotUserName,
    riotTagLine,
    {},
  );
  const { customizationData } = (profileInitData && profileInitData.profileInitSimple) || {};

  const [buttonText, setButtonText] = useState("Save Changes");
  const [filterText, setFilterText] = useState("");
  const [chosenSkin, setChosenSkin] = useState(customizationData?.headerBg);
  const { data: championFull, loading: loadingChampionFull } = useChampionFull();
  const [setHeaderBg, { data, loading }] = updateProfileCustomizations();

  const findDefaultChampionIndex = () => {
    const champName = customizationData && customizationData.headerBg?.split("_")[0];
    return getChampionIdByName(champName || "") || 1;
  };
  const [chosenChampIndex, setChosenChampIndex] = useState(findDefaultChampionIndex());

  if (chosenChampIndex === -1 && chosenChampIndex !== findDefaultChampionIndex()) {
    setChosenChampIndex(findDefaultChampionIndex());
  }

  const clickChamp = (champName: string) => {
    setChosenChampIndex(getChampionIdByName(champName));
    setButtonText("Save Changes");

    if (thumbnailContainerRef.current) {
      thumbnailContainerRef.current.scrollTop = 0;
    }
  };

  const filteredChamps = useMemo(() => {
    if (!championFull) return [];
    const list = Object.values(championFull)
      .filter((champion) => {
        return champion.skins && (filterText === "" || champion.id.toLowerCase().includes(filterText));
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    return list;
  }, [championFull, filterText]);

  useEffect(() => {
    if (window) {
      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "inherit";
      };
    }
  }, []);

  useEffect(() => {
    if (data) {
      props.close();
    }
  }, [data]);

  return (
    <div>
      <div className="fixed z-[99999999999] top-0 bottom-0 left-0 right-0 bg-purple-500 bg-opacity-[.75]" />
      <div className="fixed z-[9999999999999] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[814px] h-[622px] rounded-[6px] bg-lavender-700">
        <div className="flex flex-col h-full p-[24px]">
          <IconX className="ml-auto mb-[12px] w-[14px] cursor-pointer [&_path]:fill-white" onClick={props.close} />
          <div className="mb-[24px] font-['Barlow'] text-[18px] font-semibold">Select New Header Background</div>
          <div className="flex-1 flex gap-[24px] overflow-auto">
            <div className="flex flex-col basis-[160px]">
              <div className="relative flex items-center mb-[12px] w-full bg-white rounded-[3px]">
                <input
                  className="flex-1 pl-[4px] pr-[24px] w-full h-[30px] border-[2px] rounded-[3px] bg-white text-[14px] text-black outline-none focus:border-accent-blue-400"
                  type="text"
                  placeholder="Search Champion"
                  onChange={(e) => setFilterText(e.target.value.toLowerCase())}
                  autoFocus
                />
                <SearchIcon className="absolute right-[6px] w-[16px]" />
              </div>
              <div className="flex-1 flex flex-col gap-[6px] pr-[12px] overflow-y-auto">
                {filteredChamps &&
                  Object.values(filteredChamps).map((champion, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(
                          "flex items-center p-[8px] rounded-[3px] text-[12px] text-lavender-50 cursor-pointer hover:text-white hover:font-bold",
                          {
                            "font-bold text-white bg-accent-purple-700": parseInt(champion.key) === chosenChampIndex,
                          },
                        )}
                        onClick={() => clickChamp(champion.id)}
                      >
                        {champion.name}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex-1 flex flex-col">
              <div ref={thumbnailContainerRef} className="flex-1 grid grid-cols-[1fr_1fr] gap-[18px] overflow-y-auto">
                {championFull?.[chosenChampIndex] &&
                  championFull[chosenChampIndex].skins.map((skin, index) => {
                    const champName = championFull[chosenChampIndex].id;
                    const { num, name } = skin;
                    const imgSrc = getChampionSplashImg(championFull[chosenChampIndex].key, { splashId: num });
                    const isSelected = chosenSkin === `${champName}_${num}`;

                    return (
                      <div key={index}>
                        <div
                          className="group/thumbnail relative w-full h-[90px] cursor-pointer"
                          onClick={() => {
                            setChosenSkin(`${champName}_${num}`);
                            setButtonText("Save Changes");
                          }}
                        >
                          <ThumbnailImage imgSrc={imgSrc} />
                          <div
                            className={classNames(
                              "absolute top-0 bottom-0 left-0 right-0 rounded-[3px] group-hover/thumbnail:border-[1px] group-hover/thumbnail:border-solid group-hover/thumbnail:border-accent-blue-400 group-hover/thumbnail:bg-accent-blue-400 group-hover/thumbnail:bg-opacity-40",
                              {
                                "border-[1px] border-solid border-accent-blue-400 bg-accent-blue-400 bg-opacity-40": isSelected,
                              },
                            )}
                          />
                          {isSelected && (
                            <CheckboxIcon className="absolute top-[50%] left-[50%] w-[18px] h-[18px] translate-x-[-50%] translate-y-[-50%]" />
                          )}
                        </div>
                        <div
                          className={classNames("mt-[6px] text-[12px] font-medium text-lavender-50", {
                            "!text-white": isSelected,
                          })}
                        >
                          {name === "default" ? champName : name}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="flex items-center justify-end mt-[24px]">
                <div
                  className="mr-[20px] text-lavender-50 text-[12px] cursor-pointer hover:text-white hover:underline"
                  onClick={() => setChosenSkin("")}
                >
                  Clear Current Header
                </div>
                <ButtonSuccess
                  className="btn-blue"
                  label="Save Changes"
                  onClick={() => setHeaderBg({ headerBg: chosenSkin })}
                  isLoading={loading}
                  success={data}
                  bForceSubmitOnEnter={true}
                  disable={buttonText === "Saved!"}
                  style={buttonText === "Saved!" ? { background: "grey" } : {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
