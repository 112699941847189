import classnames from "classnames";
import { StatShard } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";

export type SetterFn = (isActive: boolean, newShard: number, row: number) => void;

interface EditableStatShardTreeProps {
  patch?: string;
  activeShards: number[];
  isMobile?: boolean;
  disableTooltip?: boolean;
  setterFunction?: SetterFn;
  ssr?: boolean;
}

export default function EditableStatShardTree(props: EditableStatShardTreeProps) {
  const { useStatShards } = getRiotAssetsContext();
  const { patch, activeShards, isMobile, disableTooltip, setterFunction, ssr } = props;
  const { data } = useStatShards({ patch, ssr });
  const [statShardPatch, statShardJSON] = data || [];

  const buildPerks = (row: number) => {
    const dataRow = statShardJSON?.[row];
    const list = dataRow?.shards.map((shard, index) => {
      const isActive = shard.id === Number(activeShards[row]);

      const shardClassNames = classnames("shard", {
        "shard-active": isActive,
        "shard-inactive": !isActive,
      });

      return (
        <div
          key={index}
          className={shardClassNames}
          onClick={(_e) => {
            setterFunction && setterFunction(isActive, shard.id, row);
          }}
        >
          <StatShard shardRow={row} shardId={shard.id} disableTooltip={disableTooltip} patch={patch} />
        </div>
      );
    });

    const perkRowClassNames = classnames("perk-row", "stat-shard-row");

    return (
      <div key={row} className={perkRowClassNames}>
        <div className={classnames("row-marker", "row-active")} />
        <div className="perks">{list}</div>
      </div>
    );
  };

  if (!data || !activeShards || activeShards.every((shard) => shard === -1)) {
    return null;
  }

  return (
    <div className={classnames({ "rune-tree_mobile": isMobile })}>
      <div className="rune-tree stat-shards-container">{[0, 1, 2].map((row) => buildPerks(row))}</div>
    </div>
  );
}
