import React, { useCallback } from "react";
import classnames from "classnames";
import { MediaQuery } from "@outplayed/responsive";
import DefaultSelect from "@ugg/shared/components/FilterManager/DefaultSelect";
import { ReactComponent as TriangleArrowDown } from "@ugg/shared/assets/svg/triangle-arrow-down.svg";
import { validParamsProfile } from "@ugg/shared/query-params/valid-params-profile";

interface ChampionFiltersProps {
  filters: (typeof validParamsProfile)["champion-stats"]["default"];
  // setFilters: (filters: Record<string, any>) => void;
  setFilters: React.Dispatch<React.SetStateAction<(typeof validParamsProfile)["champion-stats"]["default"]>>;
  setFiltersChanged: (state: boolean) => void;
}

export default function ChampionsFilters(props: ChampionFiltersProps) {
  const { filters, setFilters, setFiltersChanged } = props;

  const onFilterChange = useCallback(
    (type: keyof (typeof validParamsProfile)["champion-stats"]["default"], option: { label: string; value: string }) => {
      setFiltersChanged(true);
      setFilters({ ...filters, [type]: option.value });
    },
    [filters],
  );

  const getLabel = (options: Array<{ label: string; value: string }>, value: string) => {
    const foundOption = options.find((option) => option.value === value);
    return (foundOption && foundOption.label) || "";
  };

  const queueTypeOptions = validParamsProfile["champion-stats"]["queueType"];

  const Filters = ({ isMobile }: { isMobile?: boolean }) => (
    <React.Fragment>
      <DefaultSelect
        title={"Change Queue Type"}
        group={"queueType"}
        customButton={
          <div className="filter-select_mobile">
            <span>{getLabel(queueTypeOptions, filters.queueType)}</span>
            <TriangleArrowDown className="dropdown-indicator" />
          </div>
        }
        className={`filter-select-transparent`}
        isMobile={isMobile}
        options={queueTypeOptions}
        onChange={(selected) => {
          onFilterChange("queueType", selected);
        }}
        value={filters.queueType}
        width={130}
        fontSize={12}
        isSearchable={false}
      />
    </React.Fragment>
  );

  return (
    <div className="match-block_filters">
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        {(mediaQueryClassNames) => (
          <div className={classnames(mediaQueryClassNames, "flex-center")}>
            <Filters />
          </div>
        )}
      </MediaQuery>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        {(mediaQueryClassNames) => (
          <div className={classnames(mediaQueryClassNames, "flex-center")}>
            <Filters isMobile />
          </div>
        )}
      </MediaQuery>
    </div>
  );
}
