import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { useProfileHistoricRanks } from "@ugg/shared/api/requests/summoner-profiles/historic-ranks";
import { usePlayerOverallRanking } from "@ugg/shared/api/requests/leaderboards/ladder-ranking";
import { useSummonerProfilePage } from "@ugg/shared/pages/summoner-profile-pages";
import { SummonerProfileUpdateProvider } from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { SummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { QueueTypeN } from "@ugg/shared/utils/queue-type-helpers";

interface SummonerProfileProviderProps {
  children: React.ReactNode;
}

export function SummonerProfileProvider(props: SummonerProfileProviderProps) {
  const location = useLocation();
  let { riotUserName, riotTagLine, region } = useParams<{ riotUserName: string; riotTagLine: string; region: string }>();
  riotUserName = decodeURIComponent(riotUserName);
  riotTagLine = decodeURIComponent(riotTagLine);
  const page = useSummonerProfilePage();

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [location.pathname]);

  const { loading: loadingRanking } = usePlayerOverallRanking(riotUserName, riotTagLine, region, QueueTypeN.RANKED_SOLO, {
    errorPolicy: "all",
  });
  const { loading: loadingHistoric } = useProfileHistoricRanks(riotUserName, riotTagLine, region, QueueTypeN.RANKED_SOLO);
  const {
    loading: loadingProfileInit,
    data,
    error,
  } = useSummonerProfileInitSimple(region, riotUserName, riotTagLine, {}, { notifyOnNetworkStatusChange: true });
  const { profileInitSimple } = data || {};
  const { playerInfo } = profileInitSimple || {};
  const { riotUserName: realRiotUserName, riotTagLine: realRiotTagLine } = playerInfo || {};

  const isLoading = loadingProfileInit || loadingRanking || loadingHistoric;
  const isError = !profileInitSimple;

  const contextValue = {
    page,
    isLoading,
    isError,
    riotUserName,
    riotTagLine,
    realRiotUserName: realRiotUserName || "",
    realRiotTagLine: realRiotTagLine || "",
    region,
  };

  return (
    <SummonerProfileUpdateProvider>
      <SummonerProfileContext.Provider value={contextValue}>{props.children}</SummonerProfileContext.Provider>
    </SummonerProfileUpdateProvider>
  );
}
