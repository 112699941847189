import { useMemo } from "react";
import PostGameTeamSection, { PostGamePlayerData } from "./PostGameTeamSection";
import { sortTeamByRoles } from "@ugg/shared/utils/role-helpers";
import { hasPerfScore, getPerfScore } from "@ugg/shared/utils/performance-score-helpers";
import { SummonerMatchData } from "@ugg/shared/api/requests/summoner-profiles/single-match";
// import { getRiotAssetsContext, getIcon } from "@outplayed/riot-assets";
// import { ReactComponent as BaronIcon } from "@ugg/shared/assets/svg/post-match/objective-baron.svg";
// import { ReactComponent as DragonIcon } from "@ugg/shared/assets/svg/post-match/objective-dragon.svg";
// import { ReactComponent as TowerIcon } from "@ugg/shared/assets/svg/post-match/objective-towers.svg";
// import { ReactComponent as RoundXIcon } from "@ugg/shared/assets/svg/round-x.svg";

interface PostGameContainerProps {
  data: SummonerMatchData["match"];
  seasonId: number;
  hidePerfScore: boolean;
}

export default function PostGameContainer(props: PostGameContainerProps) {
  const { seasonId, data, hidePerfScore } = props;
  const { historicalData, matchSummary, performanceScore, winningTeam } = data || {};
  const { riotUserName, summonerName, riotTagLine, queueType, teamA, teamB } = matchSummary || {};
  const { postGameData, teamOneOverview, teamTwoOverview } = historicalData || {};

  const { team1, team2, currentSummonerTeam } = useMemo(() => {
    let currentSummonerTeam = postGameData.find((player) => {
      return player.riotUserName === riotUserName && player.riotTagLine === riotTagLine;
    })?.teamId;

    if (!currentSummonerTeam) {
      currentSummonerTeam = [...teamA, ...teamB].find((el) => el.summonerName === summonerName)?.teamId;
    }

    let team1: PostGamePlayerData[] = postGameData.filter((player) => player.teamId === currentSummonerTeam);
    let team2: PostGamePlayerData[] = postGameData.filter((player) => player.teamId !== currentSummonerTeam);

    // Add carry score/performance to each player if applicable
    if (performanceScore && hasPerfScore(queueType)) {
      [team1, team2] = [team1, team2].map((team) => {
        return team.map((player) => {
          const scores = performanceScore.find(
            (el) => el?.riotUserName === player.riotUserName && el?.riotTagLine === player.riotTagLine,
          );
          const { hardCarry, teamplay } = scores || {};
          if (hardCarry !== undefined && teamplay !== undefined && !isNaN(hardCarry) && !isNaN(teamplay)) {
            const carryScore = getPerfScore(hardCarry, teamplay);
            return {
              ...player,
              carryScore: hidePerfScore || carryScore <= 0 ? null : carryScore,
            };
          }

          return player;
        });
      });

      // Find highest carry scores of each team
      let highscores = [team1, team2].map((team) => {
        let currentMax = 0;
        team.forEach((player) => {
          if (player.carryScore && player.carryScore > currentMax) {
            currentMax = player.carryScore;
          }
        });
        return currentMax;
      });

      // Set highest carry score flag for each player
      [team1, team2] = [team1, team2].map((team, index) => {
        return team.map((player) => ({
          ...player,
          hasHighestCarry: player?.carryScore === highscores[index],
        }));
      });
    }

    // Sort team by roles
    team1 = sortTeamByRoles<PostGamePlayerData>(team1);
    team2 = sortTeamByRoles<PostGamePlayerData>(team2);

    return { team1, team2, currentSummonerTeam };
  }, [postGameData]);

  // const topTeamOverview = currentSummonerTeam === 100 ? teamOneOverview : teamTwoOverview; // current summoner's team
  // const botTeamOverview = currentSummonerTeam === 100 ? teamTwoOverview : teamOneOverview; // opponent team
  // const teamOneOutcomeColor = winningTeam === currentSummonerTeam ? "blue" : "red";
  // const teamTwoOutcomeColor = winningTeam === currentSummonerTeam ? "red" : "blue";

  const champPerfs = [...team1, ...team2];
  let maxDamage = 0;
  for (let i = 0; i < champPerfs.length; i++) {
    if (champPerfs[i].damage > maxDamage) maxDamage = champPerfs[i].damage;
  }

  return (
    <div className="expanded-match_post-game">
      {/* <div className="performance-summary">
        <div>
          <Bans bans={topTeamOverview.bans} color={teamOneOutcomeColor} />
          <div className="kill-icons kill-icons_1">
            <IconAndCount icon={<BaronIcon className={`icon ${teamOneOutcomeColor}`} />} count={topTeamOverview.baronKills}/>
            <IconAndCount icon={<DragonIcon className={`icon ${teamOneOutcomeColor}`} />} count={topTeamOverview.dragonKills}/>
            <IconAndCount icon={<TowerIcon className={`icon tower ${teamOneOutcomeColor}`} />} count={topTeamOverview.towerKills}/>
          </div>
        </div>
        <GoldDistribution isWinningTeam={winningTeam === currentSummonerTeam} wonGame={winningTeam === currentSummonerTeam} team1Gold={topTeamOverview.gold} team2Gold={botTeamOverview.gold}/>
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
          <Bans bans={botTeamOverview.bans} color={teamTwoOutcomeColor} reverse />
          <div className="kill-icons kill-icons_2">
            <IconAndCount icon={<TowerIcon className={`icon tower ${teamTwoOutcomeColor}`} />} count={botTeamOverview.towerKills}/>
            <IconAndCount icon={<DragonIcon className={`icon ${teamTwoOutcomeColor}`} />} count={botTeamOverview.dragonKills}/>
            <IconAndCount icon={<BaronIcon className={`icon ${teamTwoOutcomeColor}`} />} count={botTeamOverview.baronKills}/>
          </div>
        </div>
      </div> */}
      <div className={`teams-info`}>
        <PostGameTeamSection data={data} team={team1} maxDamage={maxDamage} seasonId={seasonId} />
        <PostGameTeamSection data={data} team={team2} maxDamage={maxDamage} seasonId={seasonId} />
      </div>
    </div>
  );
}

// const IconAndCount = (props) => {
//   const { icon, count } = props;
//   return <div className="icon-and-count">
//     {icon}
//     <div className="count-text">{count}</div>
//   </div>
// };

// const GoldDistribution = (props) => {
//   const { team1Gold, team2Gold, isWinningTeam } = props;
//   const team1Perc = team1Gold / (team1Gold + team2Gold);
//   const team2Perc = 1 - team1Perc;
//   return <div className="gold-distribution">
//     <div>Gold</div>
//     <div className="bar-distribution">
//       <div>{team1Gold.toLocaleString()}</div>
//       <div className="bar">
//         <div className={isWinningTeam ? "team1px" : "team2px"} style={{ width: `${team1Perc * 100}%` }} />
//         <div className={isWinningTeam ? "team2px" : "team1px"} style={{ width: `${team2Perc * 100}%` }} />
//       </div>
//       <div>{team2Gold.toLocaleString()}</div>
//     </div>
//   </div>
// }

// const Bans = ({ bans, color, reverse }) => {
//   const { getChampionImg } = getRiotAssetsContext();
//   if (!bans || bans.length === 0) {
//     return null;
//   }

//   return (
//     <div className="champion-bans" style={{ flexDirection: reverse ? "row-reverse" : "row" }}>
//       <RoundXIcon className={`ban-icon ban-icon_${color}`} />
//       {
//         bans.map((championId, index) => (
//           <div key={index} className={`champion-ban champion-ban_${color} ${reverse ? "champion-ban_reverse" : ""}`}>
//             {championId !== -1
//               ? <img src={getChampionImg(championId)} />
//               : <img className="no-ban" src={getIcon("lol/icons/blankChampIcon.svg")} />
//             }
//           </div>
//         ))
//       }
//     </div>
//   )
// }
