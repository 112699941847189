import { useState, useEffect, useRef, useContext } from "react";
import classnames from "classnames";
import {
  UpdateStateContext,
  UpdateDispatchContext,
  ActionTypes,
} from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { useUpdateProfile } from "@ugg/shared/api/requests/summoner-profiles/update-profile";
import { ReactComponent as Exclamation } from "@ugg/shared/assets/svg/exclamation.svg";
import { ReactComponent as CheckMark } from "@ugg/shared/assets/svg/check-line.svg";

const UpdateSpinner = () => {
  return (
    <div className="animate-spin w-[24px] h-[24px] border-[#ffffff_transparent_#ffffff_transparent] border-[2px] rounded-[50%]" />
  );
};

interface UpdateButtonProps {
  outOfDate?: boolean;
}

const DEFAULT_MESSAGE = "Update";

export function UpdateButton(props: UpdateButtonProps) {
  const dispatch = useContext(UpdateDispatchContext);
  const { isUpdatingProfile, profileRefetches, onUpdateCompleted, onUpdateError } = useContext(UpdateStateContext);

  const context = useSummonerProfileContext();
  const { region, riotUserName, riotTagLine } = context;
  const [alert, setAlert] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState<React.ReactNode>(DEFAULT_MESSAGE);
  const [updateComplete, setUpdateComplete] = useState(false);

  const completeTimeout = useRef<NodeJS.Timeout>();
  const resetTimeout = useRef<NodeJS.Timeout>();

  const [updatePlayerProfile, { data, loading, error, networkStatus }] = useUpdateProfile({
    ssr: false,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      console.log("completed!");
      if (data.updatePlayerProfile.success) {
        // successfully updated user's profile
        finished();
      } else {
        // failed to update, set error message
        bad_request(data.updatePlayerProfile.errorReason);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const reset = () => {
    setProgress(0);
    setMessage(DEFAULT_MESSAGE);
    setUpdateComplete(false);
    dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: false });
    if (completeTimeout.current) clearTimeout(completeTimeout.current);
    if (resetTimeout.current) clearTimeout(resetTimeout.current);
  };

  const { refetch: refetchSummonerProfile } = useSummonerProfileInitSimple(region, riotUserName, riotTagLine, {});

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    reset();
  }, [riotUserName, riotTagLine, region]);

  useEffect(() => {
    if (!isUpdatingProfile && updateComplete && Object.keys(profileRefetches)) {
      const entries = Object.entries(profileRefetches);
      const promises = entries.map(([key, fn]) => {
        try {
          return fn && fn();
        } catch (err) {
          return null;
        }
      });
      Promise.all(promises)
        .then((responses) => {
          dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: false });
          const responseMap: Record<string, any> = {};
          entries.forEach(([key, value], index) => {
            responseMap[key] = responses[index];
          });
          onUpdateCompleted && onUpdateCompleted(responseMap);
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: false });
          onUpdateError && onUpdateError(error);
        });
    }
  }, [updateComplete, isUpdatingProfile, profileRefetches]);

  const bad_request = (message: string) => {
    if (completeTimeout.current) clearTimeout(completeTimeout.current);
    completeTimeout.current = setTimeout(() => {
      dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: false });
      setMessage(DEFAULT_MESSAGE);
    }, 6000);

    setMessage(`(Error Code: ${message})`);
    setAlert(true);
  };

  const finished = () => {
    setProgress(100);
    dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: true });
    refetchSummonerProfile();

    if (completeTimeout.current) clearTimeout(completeTimeout.current);
    completeTimeout.current = setTimeout(() => {
      setUpdateComplete(true);
      dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: false });
      setMessage(
        <div style={{ fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          Updated
          <CheckMark style={{ marginLeft: "5px", height: "18px", width: "18px" }} />
        </div>,
      );
    }, 1000);

    if (resetTimeout.current) clearTimeout(resetTimeout.current);
    resetTimeout.current = setTimeout(() => {
      reset();
    }, 2500);
  };

  const startUpdate = () => {
    dispatch({ type: ActionTypes.IS_UPDATING_PROFILE, payload: true });
    setUpdateComplete(false);
    setProgress((1 / 20) * 100);
    setAlert(false);
    setMessage(<UpdateSpinner />);
    updatePlayerProfile(region, riotUserName, riotTagLine);
  };

  const { outOfDate } = props;
  const disableUpdateButton = isUpdatingProfile || alert;
  const updateButtonClassNames = classnames("summoner-profile_update-button", {
    "_is-updating": disableUpdateButton,
    success: updateComplete,
    error: alert,
  });

  return (
    <button className={updateButtonClassNames} onClick={startUpdate} disabled={disableUpdateButton}>
      {isUpdatingProfile && <div className="progress-bar" style={{ width: `${progress}%` }} />}
      <div className="flex-center" style={{ position: "relative" }}>
        {message}
        {outOfDate && !disableUpdateButton && !updateComplete && (
          <div className="update-alert">
            <Exclamation />
          </div>
        )}
      </div>
    </button>
  );
}
