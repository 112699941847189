import { useState, useRef } from "react";
import classNames from "classnames";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { MediaQuery } from "@outplayed/responsive";
import MatchStatsExpanded from "./MatchStatsExpanded";
import { ArrowToggle } from "@ugg/shared/components/common/ArrowToggle";
import { calculateKDA, getKdaColorBestChampsClassName } from "@ugg/shared/utils/kda";
import { getWinRateBestChampsColorClassName } from "@ugg/shared/utils/win-rates";
import { round } from "@ugg/shared/utils/math";
import { MatchSummary } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";

interface MatchStatsProps {
  className?: string;
  matches: MatchSummary[];
}

export function MatchStats(props: MatchStatsProps) {
  const { className, matches } = props;
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const { getChampionImg, getChampionName } = getRiotAssetsContext();

  const [isOpen, setIsOpen] = useState(false);

  const getWinRate = (matches: MatchSummary[], championId?: number) => {
    let wins = 0,
      losses = 0;

    for (const match of matches) {
      if (championId === undefined || (championId && match["championId"] === championId)) {
        match["win"] ? ++wins : ++losses;
      }
    }
    return {
      wins,
      losses,
      winRate: round((wins / (wins + losses)) * 100),
    };
  };

  const getOverallKDA = (matches: MatchSummary[]) => {
    let kills = 0,
      deaths = 0,
      assists = 0,
      totalGames = matches.length;
    for (const match of matches) {
      kills += match["kills"];
      assists += match["assists"];
      deaths += match["deaths"];
    }
    return {
      kda: deaths === 0 ? "Perfect" : round((kills + assists) / deaths, 2),
      kills: round(kills / totalGames, 1),
      assists: round(assists / totalGames, 1),
      deaths: round(deaths / totalGames, 1),
    };
  };

  const getTopThreeChampsPlayed = (matches: MatchSummary[]) => {
    let allChampsPlayed: Map<
      number,
      {
        games: number;
        wins: number;
        losses: number;
        kills: number;
        assists: number;
        deaths: number;
      }
    > = new Map();
    for (const match of matches) {
      const currentChampion = allChampsPlayed.get(match["championId"]);
      if (!currentChampion) {
        allChampsPlayed.set(match["championId"], {
          games: 1,
          wins: match["win"] ? 1 : 0,
          losses: match["win"] ? 0 : 1,
          kills: match["kills"],
          assists: match["assists"],
          deaths: match["deaths"],
        });
      } else {
        currentChampion["games"] += 1;
        currentChampion["wins"] += match["win"] ? 1 : 0;
        currentChampion["losses"] += match["win"] ? 0 : 1;
        currentChampion["kills"] += match["kills"];
        currentChampion["assists"] += match["assists"];
        currentChampion["deaths"] += match["deaths"];
      }
    }
    const topThree = [...allChampsPlayed.entries()].sort((a, b) => b[1]["games"] - a[1]["games"]).splice(0, 3);
    return new Map(topThree);
  };

  const { wins, losses, winRate } = getWinRate(matches);
  const kdaStats = getOverallKDA(matches);
  const topThreeChampions = getTopThreeChampsPlayed(matches);
  const totalGames = matches.length;

  const options = {
    chart: {
      type: "pie",
      animation: false,
    },
    series: [
      {
        data: [
          {
            y: wins,
            color: "#3273FA",
          },
          {
            y: losses,
            color: "#FF4E50",
          },
        ],
        states: {
          hover: {
            enabled: false,
          },
        },
        enableMouseTracking: false,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: false,
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        size: 28,
        innerSize: 16,
        borderWidth: 0,
      },
    },
    title: {
      text: "",
    },
    marker: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
  };

  const chartCallback = (chart: Highcharts.Chart) => {
    if (chart) {
      chart.reflow();
    }
  };

  if (matches.length <= 0 || !topThreeChampions || totalGames === 0 || !kdaStats) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      <div className="flex items-center flex-wrap gap-y-[8px] w-full min-h-[66px] px-[18px] py-[10px] max-sm:px-[12px] bg-purple-400 box-border">
        <div className="flex items-center gap-[16px] mr-[18px]">
          <div className="h-[40px] w-[28px] mt-[6px]">
            <HighchartsReact
              highcharts={Highcharts}
              ref={chartRef}
              options={options}
              containerProps={{
                style: { width: "100%", height: "100%" },
              }}
              callback={chartCallback}
            />
          </div>
          <div className="flex flex-col justify-center w-[80px] whitespace-nowrap">
            <div className="mb-[4px] font-semibold text-[14px] leading-[17px]">{winRate}% WR</div>
            <span className="font-normal text-[11px] leading-[13px] text-lavender-50">Last {totalGames} games</span>
          </div>
          <div
            className={classNames("flex flex-col justify-center items-center whitespace-nowrap", {
              "!max-w-[75px]": process.env.PLATFORM === "app",
            })}
          >
            <div className="mb-[2px] font-semibold text-[14px] leading-[17px]">{kdaStats["kda"]} KDA</div>
            <div className="font-normal text-[11px] leading-[13px] text-lavender-50">
              {kdaStats["kills"]}
              <span className="text-lavender-300"> / </span>
              {kdaStats["deaths"]}
              <span className="text-lavender-300"> / </span>
              {kdaStats["assists"]}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "flex-1 grid gap-[14px] grid-cols-[repeat(auto-fill,116px)] grid-rows-[33px_33px] max-h-[33px] max-xs:flex-wrap max-xs:basis-full overflow-hidden",
            {
              "!gap-[10px]": process.env.PLATFORM === "app",
            },
          )}
        >
          {[...topThreeChampions.entries()].map(([key, data]) => {
            const { kills, deaths, assists, wins, losses } = data;
            const kda = calculateKDA(kills, deaths, assists);
            return (
              <div key={key} className="flex items-center text-[11px]">
                <div className="flex-none mr-[6px] w-[33px] h-[33px] rounded-[3px] overflow-hidden max-xs:w-[28px] max-xs:h-[28px]">
                  <img className="w-full h-full scale-[1.1]" src={getChampionImg(key)} alt={getChampionName(key)} />
                </div>
                <div className="flex flex-col whitespace-nowrap">
                  <div className="mb-[2px]">
                    <span
                      className={classNames(
                        "font-semibold",
                        getWinRateBestChampsColorClassName(getWinRate(matches, key).winRate),
                      )}
                    >
                      {getWinRate(matches, key).winRate}%
                    </span>
                    <span className="text-lavender-50">
                      {" "}
                      ({wins}W {losses}L)
                    </span>
                  </div>
                  <div className={classNames(getKdaColorBestChampsClassName(kda))}>{kda} KDA</div>
                </div>
              </div>
            );
          })}
        </div>
        <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
          <div className="ml-[4px]">
            <ArrowToggle
              className={classNames("hover:bg-purple-500", {
                "bg-purple-500": isOpen,
                "bg-purple-100": !isOpen,
              })}
              isOpen={isOpen}
              setToggle={setIsOpen}
            />
          </div>
        </MediaQuery>
      </div>
      {isOpen && (
        <div className="w-full border-t-[1px] border-purple-500">
          <MatchStatsExpanded matches={matches} totalGames={totalGames} />
        </div>
      )}
    </div>
  );
}
