import { window } from "global";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import { MediaQuery } from "@outplayed/responsive";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import MatchSummary from "./MatchSummary";
import ExpandedMatchCard from "./ExpandedMatchCard";
import ArenaMatchSummary from "@ugg/shared/components/SummonerProfiles/match-card/arena/ArenaMatchSummary";
import ExpandedArenaMatchCard from "@ugg/shared/components/SummonerProfiles/match-card/arena/ExpandedArenaMatchCard";
import { MatchSummary as MatchSummaryInterface } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { MatchLP } from "../overview/match-history/match-history-helpers";
import { isBadPerfScore } from "@ugg/shared/utils/performance-score-helpers";
import { QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";

interface MatchCardProps {
  data: MatchSummaryInterface;
  matchLP: MatchLP;
  isNew: boolean;
  observable: boolean;
}

export default function MatchCard(props: MatchCardProps) {
  const { comparePatch } = getRiotAssetsContext();
  const [hovered, setHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, matchLP, isNew, observable } = props;
  const { riotUserName, riotTagLine } = useParams<{ riotUserName: string; riotTagLine: string }>();

  const observerNode = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const [showMatchCard, setShowMatchCard] = useState(!observable);

  useEffect(() => {
    if (showMatchCard && observer.current) {
      return observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowMatchCard(true);
        }
      },
      {
        rootMargin: "200px 0px 200px 0px",
        threshold: 0,
      },
    );

    if (observerNode.current) {
      observer.current.observe(observerNode.current);
    }

    return () => observer.current?.disconnect();
  }, [showMatchCard]);

  if (!data) {
    return null;
  }

  let { win, matchDuration, version, regionId, matchId, teamA, teamB } = data;

  const remake = matchDuration <= 270;
  const winConditionClass = `match_${remake ? "remake" : win ? "win" : "lose"}`;
  const hidePerfScore = isBadPerfScore(teamA) || isBadPerfScore(teamB);

  if (window && !showMatchCard) {
    return (
      <div className={`match-card-container ${winConditionClass}`} ref={observerNode}>
        <MediaQuery min="MOBILE_SMALL" max="MOBILE_MEDIUM">
          <div className={`match-summary_mobile ${winConditionClass}`} />
        </MediaQuery>
        <MediaQuery min="MOBILE_LARGE" max="DESKTOP_LARGE">
          <div className={`match-summary_desktop ${winConditionClass}`} />
        </MediaQuery>
      </div>
    );
  }

  const disableDropdown = comparePatch(data.version, "13_23") < 0; // Riot ID changes
  const matchCardClassNames = classnames("match-card-container", winConditionClass, {
    "is-expanded": isExpanded,
    "is-hovered": !disableDropdown && hovered,
  });

  if (data.queueType === QueueTypeS.ARENA) {
    return (
      <div className={matchCardClassNames} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <ArenaMatchSummary
          data={data}
          isNew={isNew}
          winConditionClass={winConditionClass}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        {isExpanded && (
          <ExpandedArenaMatchCard
            riotUserName={data.riotUserName || riotUserName}
            riotTagLine={data.riotTagLine || riotTagLine}
            regionId={regionId}
            matchId={matchId}
            version={version}
            winConditionClass={winConditionClass}
          />
        )}
      </div>
    );
  }

  return (
    <div className={matchCardClassNames} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <MatchSummary
        data={data}
        isNew={isNew}
        matchLP={matchLP}
        winConditionClass={winConditionClass}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        disableDropdown={disableDropdown}
      />
      {isExpanded && (
        <ExpandedMatchCard
          riotUserName={data.riotUserName || riotUserName}
          riotTagLine={data.riotTagLine || riotTagLine}
          regionId={regionId}
          matchId={matchId}
          version={version}
          hidePerfScore={hidePerfScore}
          winConditionClass={winConditionClass}
        />
      )}
    </div>
  );
}
