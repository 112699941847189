import classNames from "classnames";
import { GameInfo } from "./GameInfo";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";
import { LiveGameData } from "@ugg/shared/api/requests/summoner-profiles/live-game";

interface LiveGameHeaderProps {
  className?: string;
  title?: React.ReactNode;
  data: LiveGameData["getLiveGame"];
  refresh?: () => void;
}

export function LiveGameHeader(props: LiveGameHeaderProps) {
  const { className, title, data, refresh } = props;
  const { queueId, gameLengthSeconds } = data;

  return (
    <div
      className={classNames(
        "flex items-center px-[18px] py-[10px] max-xs:px-[12px] bg-purple-200 rounded-t-[3px] flex-wrap",
        className,
      )}
    >
      <HeaderBullet>{title || <span className="text-[14px] font-bold text-white">Live Game</span>}</HeaderBullet>
      <div className="flex items-center justify-center ml-auto">
        <GameInfo className="max-xs:hidden" queueType={queueId} time={gameLengthSeconds} />
        {refresh && (
          <button
            className="flex items-center justify-center ml-[12px] w-[100px] h-[36px] rounded-[3px] bg-accent-blue-400 text-white font-bold text-[12px] max-xxs:w-[70px] max-xxs:h-[30px]"
            onClick={refresh}
          >
            Refresh
          </button>
        )}
      </div>
      <GameInfo className="xs:hidden mt-[4px] basis-full !text-[12px]" queueType={queueId} time={gameLengthSeconds} />
    </div>
  );
}
