import { useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { useSummonerProfileInitSimple } from "@ugg/shared/api/requests/summoner-profiles/profile-init";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";
import { MatchSummary } from "@ugg/shared/api/requests/summoner-profiles/match-summaries";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";

const gridColClassNames = "grid grid-cols-[48%_14%_21%_19%]";

interface PlayedWithUserProps {
  username: string;
  tagLine: string;
  region: string;
  wins: number;
  losses: number;
  count: number;
}

const PlayedWithUser = (props: PlayedWithUserProps) => {
  const { wins, losses, count, region, username, tagLine } = props;
  const { getProfileIconImg } = getRiotAssetsContext();
  const { data } = useSummonerProfileInitSimple(region, username, tagLine);
  const { profileInitSimple } = data || {};
  const { playerInfo } = profileInitSimple || {};
  const { iconId } = playerInfo || {};

  const profileIconImg = getProfileIconImg(iconId);

  return (
    <Link to={getProfileOverviewUrl(region, username, tagLine)} className="group/player hover:bg-purple-100">
      <div
        className={classNames(
          gridColClassNames,
          "px-[18px] max-sm:px-[12px] py-[6px] items-center border-t-[1px] border-purple-500 text-lavender-50 text-[11px] font-medium leading-[13px]",
        )}
      >
        <div className="flex items-center">
          <div className="mr-[6px] w-[24px] h-[24px] rounded-[2px] bg-purple-100">
            {profileIconImg && <img src={profileIconImg} className="w-full h-full rounded-[2px]" />}
          </div>
          <div className="max-w-[86px] truncate group-hover/player:text-white">{username}</div>
        </div>
        <div className="text-center">{count}</div>
        <div className="text-center">
          {wins} - {losses}
        </div>
        <div className="text-center">{Math.round((wins / (wins + losses)) * 100)}%</div>
      </div>
    </Link>
  );
};

interface RecentlyPlayedWithProps {
  matchSummariesRPW: MatchSummary[];
}

export function RecentlyPlayedWith(props: RecentlyPlayedWithProps) {
  const { matchSummariesRPW } = props;
  const context = useSummonerProfileContext();
  const { region, realRiotUserName, realRiotTagLine } = context;
  const totalMatches = matchSummariesRPW.length;

  const getRecentPlayers = () => {
    type Duo = { wins: number; losses: number; count: number; riotUserName: string; riotTagLine: string };
    let recentPlayersData: Record<string, Duo> = {};

    if (totalMatches === 0) {
      return [];
    }

    for (const match of matchSummariesRPW) {
      let targetTeam = match["teamA"];
      for (const user of match["teamB"]) {
        const { riotUserName, riotTagLine } = user;
        if (riotUserName === realRiotUserName && riotTagLine === realRiotTagLine) {
          targetTeam = match["teamB"];
        }
      }
      for (const duo of targetTeam) {
        const { riotUserName, riotTagLine } = duo;
        if ((riotUserName && riotTagLine && riotUserName !== realRiotUserName) || riotTagLine !== realRiotTagLine) {
          const key = `${riotUserName}_${riotTagLine}`;
          if (riotUserName && riotTagLine) {
            if (!(key in recentPlayersData)) {
              recentPlayersData[key] = {
                riotUserName,
                riotTagLine,
                wins: match["win"] ? 1 : 0,
                losses: match["win"] ? 0 : 1,
                count: 1,
              };
            } else {
              match["win"] ? (recentPlayersData[key]["wins"] += 1) : (recentPlayersData[key]["losses"] += 1);
              recentPlayersData[key]["count"] += 1;
            }
          }
        }
      }
    }
    const firstSort = Object.keys(recentPlayersData).map((key) => [key, recentPlayersData[key]] as const);
    firstSort.sort((a, b) => b[1]["count"] - a[1]["count"]);
    return firstSort.splice(0, 8);
  };

  const recentPlayers = useMemo(() => {
    return getRecentPlayers();
  }, [matchSummariesRPW]);

  return (
    <div className="flex flex-col bg-purple-400 xs:max-sm:col-[1/3] rounded-[3px]">
      <div className="flex justify-between px-[18px] h-[56px] text-lavender-50 text-[14px] font-normal max-sm:h-[42px] max-sm:px-[12px]">
        <HeaderBullet>
          <span className="leading-none">Recently Played With</span>
        </HeaderBullet>
        {totalMatches > 0 && <p className="flex items-center leading-none">Last {totalMatches} games</p>}
      </div>
      <div
        className={classNames(
          gridColClassNames,
          "px-[18px] max-sm:px-[12px] py-[12px] border-t-[1px] border-purple-500 bg-purple-100 text-[11px] text-lavender-50 font-medium whitespace-nowrap",
        )}
      >
        <div> Summoner </div>
        <div className="text-center"> Played </div>
        <div className="text-center"> W - L </div>
        <div className="text-center"> Win Ratio </div>
      </div>
      <div className="flex flex-col">
        {recentPlayers.length > 0 ? (
          recentPlayers.map(([key, player]) => (
            <PlayedWithUser
              key={key}
              wins={player.wins}
              count={player.count}
              losses={player.losses}
              region={region}
              username={player.riotUserName}
              tagLine={player.riotTagLine}
            />
          ))
        ) : (
          <h1 className="mx-auto my-[10px] text-lavender-50 text-[14px] font-medium leading-[24px]">No Recently Played With</h1>
        )}
      </div>
    </div>
  );
}
