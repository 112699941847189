import { useQuery, QueryHookOptions } from "@apollo/client";
import gql from "graphql-tag";
import { RoleN } from "@ugg/shared/utils/role-helpers";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";

export const GET_PLAYER_STATISTICS = gql`
  query getPlayerStats(
    $queueType: [Int!]
    $regionId: String!
    $role: Int!
    $seasonId: Int!
    $riotUserName: String!
    $riotTagLine: String!
  ) {
    fetchPlayerStatistics(
      queueType: $queueType
      riotUserName: $riotUserName
      riotTagLine: $riotTagLine
      regionId: $regionId
      role: $role
      seasonId: $seasonId
    ) {
      basicChampionPerformances {
        assists
        championId
        cs
        damage
        damageTaken
        deaths
        doubleKills
        gold
        kills
        maxDeaths
        maxKills
        pentaKills
        quadraKills
        totalMatches
        tripleKills
        wins
        lpAvg
        firstPlace
        totalPlacement
      }
      exodiaUuid
      puuid
      queueType
      regionId
      role
      seasonId
    }
  }
`;

export interface BasicChampionPerformance {
  assists: number;
  championId: number;
  cs: number;
  damage: number;
  damageTaken: number;
  deaths: number;
  doubleKills: number;
  gold: number;
  kills: number;
  maxDeaths: number;
  maxKills: number;
  pentaKills: number;
  quadraKills: number;
  totalMatches: number;
  tripleKills: number;
  wins: number;
  lpAvg: number;
  // Arena
  firstPlace: number;
  totalPlacement: number;
}

interface PlayerStatisticsData {
  fetchPlayerStatistics: Array<{
    basicChampionPerformances: BasicChampionPerformance[];
    exodiaUuid: string;
    puuid: string;
    queueType: number;
    regionId: string;
    role: number;
    seasonId: number;
  }>;
}

export function usePlayerStatistics(
  riotUserName: string,
  riotTagLine: string,
  regionId: string,
  additionalVaraibles?: { queueType?: number[]; seasonId?: number; role?: number },
  queryOptions: QueryHookOptions = {},
) {
  const { queueType, seasonId, role } = additionalVaraibles || {};

  return useQuery<PlayerStatisticsData>(GET_PLAYER_STATISTICS, {
    ...queryOptions,
    variables: {
      riotUserName,
      riotTagLine,
      regionId,
      role: role || RoleN.ALL,
      seasonId: seasonId || INTERNAL_CURRENT_SEASON,
      queueType: queueType || [],
    },
  });
}
