import { useState, useEffect, useContext } from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { UpdateDispatchContext, ActionTypes } from "@ugg/shared/components/SummonerProfiles/SummonerProfileUpdateReducer";
import { useSummonerProfileContext } from "@ugg/shared/components/SummonerProfiles/SummonerProfileContext";
import { RankScore, useProfileRanks } from "@ugg/shared/api/requests/summoner-profiles/profile-ranks";
import { getS13RankUrl } from "@outplayed/riot-assets";
import { RankS, selectRank, getProBuildRankName } from "@ugg/shared/utils/rank-helpers";
import { getQueueTypeName, QueueTypeS } from "@ugg/shared/utils/queue-type-helpers";
import { numberWithCommas } from "@ugg/shared/utils/math";
import { HeaderBullet } from "@ugg/shared/components/common/HeaderBullet";
import { ArrowToggle } from "@ugg/shared/components/common/ArrowToggle";
import LPBlockContainer from "@ugg/shared/components/SummonerProfiles/overview/lp/LPBlockContainer";
import { INTERNAL_CURRENT_SEASON } from "@ugg/shared/utils/season-helpers";

const Promos = ({ promos }: { promos: string }) => {
  return (
    <div className="promos">
      {(promos || "").split("").map((outcome, index) => {
        const promoGameClassNames = classnames("promo_game", {
          promo_game__win: outcome === "W",
          promo_game__loss: outcome === "L",
          promo_game__unfinished: !outcome,
        });
        return <div key={index} className={promoGameClassNames} />;
      })}
    </div>
  );
};

interface SummonerRankProps {
  rankData: RankScore;
  fallbackQueueType: QueueTypeS;
}

const SummonerRank = (props: SummonerRankProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowBreakpoint] = useGlobal("responsive");
  const collapseWindow = ["MOBILE_LARGE", "TABLET", "DESKTOP_SMALL"].includes(windowBreakpoint);
  const { fallbackQueueType, rankData } = props;

  const { lastUpdatedAt, losses, lp, promoProgress, queueType, rank, role, seasonId, tier, wins } = rankData || {};

  useEffect(() => {
    if (collapseWindow && isOpen) {
      setIsOpen(false);
    }
  }, [windowBreakpoint]);

  const queueTypeLabel = getQueueTypeName(fallbackQueueType || queueType);
  const winRate = Math.round(((wins || 0) / ((wins || 0) + (losses || 0))) * 100);
  const totalGames = (wins || 0) + (losses || 0);

  return (
    <div className={classnames("content-section content-section_no-padding rank-lp-container", { "unranked-container": !tier })}>
      <div className={classnames("rank-content", { is_open: isOpen })}>
        <div className="queue-container">
          <div className="rank-content-header">
            <HeaderBullet>
              <div className="leading-none">{queueTypeLabel}</div>
            </HeaderBullet>
            {tier && (
              <ArrowToggle
                className={classnames("hover:bg-purple-500", {
                  "bg-purple-500": isOpen,
                  "bg-purple-100": !isOpen,
                })}
                isOpen={isOpen}
                setToggle={setIsOpen}
              />
            )}
          </div>
          <span className="unranked">{!tier && getProBuildRankName(RankS.UNRANKED, "")}</span>
        </div>
        {tier && (
          <div className="rank-sub-content">
            <div className="rank-image-container">
              <img className="rank-img" src={getS13RankUrl(tier.toLowerCase())} />
            </div>
            <div className="text-container">
              <div className="rank-text">
                <span className="rank-title">{getProBuildRankName(tier.toLowerCase(), rank)}</span>
                {promoProgress ? <Promos promos={promoProgress} /> : <span>{`${numberWithCommas(lp || 0)} LP`}</span>}
              </div>
              {totalGames > 0 && (
                <div className="rank-wins">
                  <span className="total-games">
                    {wins}W {losses}L
                  </span>
                  <span>{`${winRate}% Win Rate`}</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {tier && isOpen && (
        <div className="content-section rank-block-lp-graph">
          <div className="graph-container">
            <LPBlockContainer queueType={fallbackQueueType || queueType} tier={tier} />
          </div>
        </div>
      )}
    </div>
  );
};

export function RankBlock() {
  const dispatch = useContext(UpdateDispatchContext);
  const context = useSummonerProfileContext();
  const { riotUserName, riotTagLine, region } = context;

  const { data, loading, error, refetch, variables } = useProfileRanks(
    riotUserName,
    riotTagLine,
    region,
    INTERNAL_CURRENT_SEASON,
  );
  const { fetchProfileRanks } = data || {};
  const { rankScores } = fetchProfileRanks || {};

  useEffect(() => {
    dispatch({
      type: ActionTypes.ADD_UPDATES,
      payload: { rankBlock: refetch },
    });
  }, [JSON.stringify(variables)]);

  let content = null;
  if (loading) {
    content = (
      <div className="rank-block_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="rank-list">
        <SummonerRank
          rankData={selectRank(QueueTypeS.RANKED_SOLO, INTERNAL_CURRENT_SEASON, rankScores || []) as RankScore}
          fallbackQueueType={QueueTypeS.RANKED_SOLO}
        />
        <SummonerRank
          rankData={selectRank(QueueTypeS.RANKED_FLEX, INTERNAL_CURRENT_SEASON, rankScores || []) as RankScore}
          fallbackQueueType={QueueTypeS.RANKED_FLEX}
        />
      </div>
    );
  }

  return <div className="rank-block">{content}</div>;
}
