import { useContext, createContext } from "react";

export interface SummonerProfileContextInterface {
  page: string;
  isLoading: boolean;
  isError: boolean;
  riotUserName: string;
  riotTagLine: string;
  realRiotUserName: string;
  realRiotTagLine: string;
  region: string;
}

export const SummonerProfileContext = createContext<SummonerProfileContextInterface>({
  page: "",
  isLoading: true,
  isError: false,
  riotUserName: "",
  riotTagLine: "",
  realRiotUserName: "",
  realRiotTagLine: "",
  region: "",
});

export function useSummonerProfileContext() {
  return useContext(SummonerProfileContext);
}
