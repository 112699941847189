import classNames from "classnames";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getTierColor } from "@ugg/shared/utils/rank-helpers";

interface ProfileIconContainerProps {
  className?: string;
  tier: string | undefined;
  summonerLevel: number;
  iconId: number;
}

export function ProfileIconContainer(props: ProfileIconContainerProps) {
  const { getProfileIconImg } = getRiotAssetsContext();
  const { className, tier, summonerLevel, iconId } = props;
  const tierColor = tier && getTierColor(tier);

  return (
    <div
      className={classNames(
        "relative flex-none w-[93px] h-[93px] max-xs:w-[75px] max-xs:h-[75px] rounded-[6px] border-[2px] border-lavender-400 bg-[#17172e]",
        className,
      )}
      style={{ borderColor: tierColor }}
    >
      {summonerLevel && (
        <div
          className="absolute z-[2] top-[-16px] left-[50%] translate-x-[-50%] flex items-center justify-center w-[36px] h-[20px] rounded-[4px] border-[1px] border-lavender-400 bg-[#06061f] text-white text-[11px] font-bold"
          style={{ borderColor: tierColor }}
        >
          {summonerLevel}
        </div>
      )}
      <div className="relative w-full h-full rounded-[4px] border-[2px] border-[#17172e] overflow-hidden">
        {summonerLevel && (
          <div className="absolute top-[-6px] left-[50%] z-[1] translate-x-[-50%] w-[40px] h-[10px] rounded-b-[2px] border-[2px] border-purple-500 bg-[#06061f]" />
        )}
        <img className="w-full h-full rounded-[2px]" src={getProfileIconImg(iconId)} />
      </div>
    </div>
  );
}
