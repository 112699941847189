import { QueueTypeS } from "./queue-type-helpers";

export function hasPerfScore(queueType: string) {
  const acceptableTypes: string[] = [
    QueueTypeS.RANKED_SOLO,
    QueueTypeS.RANKED_FLEX,
    QueueTypeS.NORMAL_BLIND,
    QueueTypeS.QUICKPLAY,
    QueueTypeS.NORMAL_DRAFT,
    QueueTypeS.CLASH,
  ];

  return acceptableTypes.includes(queueType);
}

export function getPerfScore(hardCarryScore: number, teamplayScore: number) {
  return Math.round((hardCarryScore + teamplayScore) / 2);
}

export function isBadPerfScore(team: Array<{ hardCarry: number; teamplay: number }>) {
  let isBadData = false;
  let count = 0;
  team.forEach((player) => {
    const avgScore = Math.round(((player.hardCarry || 0) + (player.teamplay || 0)) / 2);
    if (avgScore > 120) {
      count++;
    }
  });

  if (count >= 2) {
    isBadData = true;
  }
  return isBadData;
}
